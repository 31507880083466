import React, { useCallback, useEffect } from 'react';

import { Button, makeStyles, Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import SelectDestinationTemplateCard from './../../SelectDestinationTemplateCard';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../reducers';
import { DateTime } from 'luxon';
import { setWeekRangeData } from '../../../actions/time-actions';
import {
	getWeekRange,
	getSmallestTimeFromISOString,
} from '../../../utils/date';
import Loading from '../../Loading';
import { saveSettings } from '../../../actions/api-actions';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	links: {
		textDecoration: 'none',
	},
	title: {
		textAlign: 'center',
		fontWeight: 900,
	},
} ) );

type TemplateChipColor = 'primary' | 'secondary';

export interface SelectDestinationDesktopProps {
	onSetDestinationName: ( destinationName: string ) => void;
	setIsManagingDestination: ( isSelectingDestination: boolean ) => void;
}

const SelectDestinationDesktop: React.FC<SelectDestinationDesktopProps> = ( {
	onSetDestinationName,
	setIsManagingDestination,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);
	const timesheetDestinationTemplates = settings.destinations;

	const onAddClick = useCallback( () => {
		setIsManagingDestination( true );
	}, [ setIsManagingDestination ] );

	const cardClickHandler = useCallback(
		( title: string ) => {
			onSetDestinationName( title );
		},
		[ onSetDestinationName ]
	);

	useEffect( () => {
		if ( Object.keys( timesheetDestinationTemplates ).length === 0 ) {
			setIsManagingDestination( true );
		}
	} )

	const editClickHandler = useCallback(
		( title: string ) => {
			setIsManagingDestination( true );
			onSetDestinationName( title );
		},
		[ onSetDestinationName, setIsManagingDestination ]
	);

	const deleteClickHandler = useCallback(
		( title: string ) => {
			const {
				[ title ]: removedDestinationTemplate,
				...otherDestinationTemplates
			} = timesheetDestinationTemplates;

			dispatch(
				saveSettings( {
					...settings,
					destinations: otherDestinationTemplates,
				} )
			);
		},
		[ dispatch, settings, timesheetDestinationTemplates ]
	);

	const { accounts: userAccounts, currentTimesheets, selectedUser } = useSelector(
		( state: AppState ) => state.userState
	);
	const selectedWeekRange = useSelector(
		( state: AppState ) => state.timeState.weekRange
	);

	const onWeekErrorSuggestionClick = useCallback( () => {
		if ( currentTimesheets ) {
			const startDatesOfWeekPerAccount = currentTimesheets.map(
				( currentTimesheet ) => currentTimesheet.timeSheetInfos[ 0 ].startDateOfWeek
			);
			const lowestStartDate = getSmallestTimeFromISOString(
				startDatesOfWeekPerAccount
			);
			const weekRage = getWeekRange(
				DateTime.fromMillis( lowestStartDate ).toJSDate()
			);
			dispatch( setWeekRangeData( weekRage ) );
		}
	}, [ currentTimesheets, dispatch ] );

	const selectedAccountEmployeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const t = useTranslation();

	// In order to log time for a specific week, the not internal hired user must have logged the time for each of the previous ones.
	// The last week without completed timesheet is in the currentTimesheetData for the selectedUser
	if ( currentTimesheets && selectedWeekRange && selectedUser ) {
		const selectedAccountCurrentTimesheet = currentTimesheets.filter(
			( currentTimesheet ) =>
				currentTimesheet.employeeCode === selectedUser.employeeCode
		)[ 0 ];

		const lowestStartDate = Date.parse(
			selectedAccountCurrentTimesheet.timeSheetInfos[ 0 ].startDateOfWeek
		).valueOf();

		const currentTimesheetWeekStart = DateTime.fromMillis( lowestStartDate );
		const selectedRangeWeekStart = DateTime.fromJSDate( selectedWeekRange.from );

		// So we check if the selected week is greater than the one returned from the API.
		if (
			currentTimesheetWeekStart < selectedRangeWeekStart &&
			!selectedUser.employeeDetail.internalHired
		) {
			return (
				<>
					<Box
						flex="1"
						display="flex"
						flexDirection="column"
						height="calc( 100vh - 11rem )"
						overflow="hidden"
					>
						<Box p={ 2 }>
							<Typography variant="h3">{ 'Oops..' }</Typography>
							<Box paddingTop={ 1 }>
								<Typography>
									{ 'First you must log the time for the following week:' }
								</Typography>
								<Typography>
									{ currentTimesheetWeekStart.toFormat( `dd MMM yyyy` ) } -{ ' ' }
									{ currentTimesheetWeekStart
										.plus( { week: 1 } )
										.minus( { millisecond: 1 } )
										.toFormat( `dd MMM yyyy` ) }
								</Typography>
							</Box>
						</Box>
					</Box>
					<Button
						fullWidth
						className={ classes.button }
						onClick={ onWeekErrorSuggestionClick }
					>
						<CheckIcon />
						{ 'Okay' }
					</Button>
				</>
			);
		}
	} else {
		return <Loading />;
	}

	return (
		<>
			<Box
				flex="1"
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 11rem )"
				overflow="hidden"
			>
				<Box
					overflow="auto"
					boxShadow="rgba(0, 0, 0, 0.05) 0px -3px 3px 0px"
					flex="1"
					p={ 1 }
					paddingTop={ 2 }
				>
					{ userAccounts.length > 1 && selectedUser && (
						<Typography className={ classes.title }>
							{ selectedUser.companyName }
						</Typography>
					) }
					{ timesheetDestinationTemplates
						? Object.values( timesheetDestinationTemplates )
							.reverse()
							.map( ( timesheetDestinationTemplate: any ) => {
								if ( !timesheetDestinationTemplate ) {
									return null;
								}

								let chipColor: TemplateChipColor;
								let isActive = true;

								if ( timesheetDestinationTemplate.type === 'Project' ) {
									chipColor = 'primary';

									let projectStart: string =
										timesheetDestinationTemplate.activity
											.startDateProjectTeam;
									let projectEnd: string =
										timesheetDestinationTemplate.activity.endDateProjectTeam;

									// If the start of the project is after the end of the selected week
									// or if the end of the project is before the start of the selected week
									// it is marked as inactive
									if (
										Date.parse( projectStart ) >
										selectedWeekRange.to.getTime() ||
										Date.parse( projectEnd ) < selectedWeekRange.from.getTime()
									) {
										isActive = false;
									}
								} else {
									chipColor = 'secondary';
								}

								if (
									selectedAccountEmployeeCode !==
									timesheetDestinationTemplate.employeeCode
								) {
									isActive = false;
								}

								const destinationTemplateName =
									timesheetDestinationTemplate.details.Name;

								return (
									<SelectDestinationTemplateCard
										isActive={ isActive }
										onCardClick={ cardClickHandler }
										onEditClick={ editClickHandler }
										onDeleteClick={ deleteClickHandler }
										key={ destinationTemplateName }
										color={ chipColor }
										customChipColor={
											timesheetDestinationTemplate.templateColor
										}
										title={ destinationTemplateName }
									/>
								);
							} )
						: null }
				</Box>
			</Box>
			<Button fullWidth className={ classes.button } onClick={ onAddClick }>
				<AddIcon />
				{ t( 'Add new' ) }
			</Button>
		</>
	);
};

export default SelectDestinationDesktop;
