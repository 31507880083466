import React from 'react';

import { Button, Box, Typography } from '@material-ui/core';

import Header from '../Headers/LogoHeader';
import ContactLine from '../controls/ContactLine';

const LogIn = ( {
	onLogInClick,
	isLoading,
}: {
	onLogInClick: () => void;
	isLoading: boolean;
} ) => (
	<Box display="flex" flexDirection="column">
		<Header />
		<Box textAlign="center" m={ 4 }>
			{ isLoading ? (
				<Typography>Loading...</Typography>
			) : (
				<>
					<Button
						size="large"
						onClick={ onLogInClick }
						variant="contained"
						color="primary"
					>
						Log In
          </Button>
				</>
			) }
		</Box>
		{!isLoading && (
			<ContactLine contactText={ 'This will take you to our corporate login.' } />
		) }
	</Box>
);

export default LogIn;
