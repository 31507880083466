import { makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import QuickAction from '../../controls/QuickAction';
import DesktopTemplateCard from '../../DesktopTemplateCard';
import { ReactComponent as ExpenseIcon } from './../../../images/expense-icon.svg';

export interface ManageExpenseClaimsDesktopProps { }

const useStyles = makeStyles( theme => ( {
	expenseClaimsAction: {
		margin: theme.spacing( 1.5, 8 )
	}
} ) )

const ManageExpenseClaimsDesktop: React.FC<ManageExpenseClaimsDesktopProps> = () => {
	const history = useHistory();
	const classes = useStyles();

	const onCreateExpenseClaim = useCallback( () => {
		history.push( '/create-expense-claim', { previous: history.location.pathname } );
	}, [ history ] );

	const onSelectOverview = useCallback( () => {
		history.push( '/expense-claims', { previous: history.location.pathname } );
	}, [ history ] );

	return (
		<>
			<QuickAction
				title={ 'Expense claims overview' }
				tooltipTitle={ 'Alt+E' }
				onClick={ onSelectOverview }
				Icon={ ExpenseIcon }
				className={ classes.expenseClaimsAction }
			/>
			<DesktopTemplateCard
				title={ 'Add expense claim' }
				action={ onCreateExpenseClaim }
			/>
		</>
	);
};

export default ManageExpenseClaimsDesktop;
