import {
	GET_TIME_SHEET_SUCCEEDED,
	GET_TIME_SHEET_STARTED,
	GET_TIME_SHEET_FAILED,
	ADD_TIMESHEET_STARTED,
	DELETE_TIME_SHEET_LINE_ITEM_STARTED,
	ADD_TIMESHEET_FAILED,
	DELETE_TIME_SHEET_LINE_ITEM_FAILED,
	ADD_TIMESHEET_SUCCEEDED,
	COPY_TIMESHEET_STARTED,
	COPY_TIMESHEET_FAILED,
	GET_CURRENT_TIME_SHEET_SUCCEEDED,
	GET_CURRENT_TIME_SHEET_FAILED,
	GET_CURRENT_TIME_SHEET_STARTED,
} from './../actions/api-actions';
import { TimesheetStatus, Timesheet } from '../types/api/timesheets';

export interface TimesheetInfo {
	id: string;
	year: number;
	week: number;
	startDateOfWeek: string;
	endDateOfWeek: string;
	contractHours: number;
	timeSpent: number;
	timeSpentDirect: number;
	timeSpentIndirect: number;
	timeSpentAbsence: number;
	percentageDirect: number;
	employeeSearchKey: string;
	homeDepartment: string;
	percentageDirectRelativeTo40Hrs: number;

	lowestStatusTimesheet: TimesheetStatus;
	highestStatusTimesheet: TimesheetStatus;
}

export interface TimesheetState {
	timesheets: Array<Timesheet>;
	isLoading: boolean;
	startedAt: number;
	error: string;
}

export const timesheetsReducer = (
	state: TimesheetState = {
		timesheets: [],
		isLoading: false,
		startedAt: 0,
		error: '',
	},
	action: any
) => {
	switch ( action.type ) {
		case DELETE_TIME_SHEET_LINE_ITEM_STARTED:
		case ADD_TIMESHEET_STARTED:
		case COPY_TIMESHEET_STARTED:
		case GET_CURRENT_TIME_SHEET_STARTED:
		case GET_TIME_SHEET_STARTED: {
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					isLoading: true,
					startedAt: action.startedAt,
					error: '',
				};
			}
			return {
				...state,
				isLoading: true,
				startedAt: action.startedAt,
			}
		}
		case ADD_TIMESHEET_SUCCEEDED:
		case GET_TIME_SHEET_SUCCEEDED: {
			const timesheets = action.timesheets as Array<Timesheet>;
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					timesheets,
					isLoading: false,
					error: '',
				};
			}
			return state;

		}
		case GET_CURRENT_TIME_SHEET_SUCCEEDED: {
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					isLoading: false,
					error: '',
				};
			}
			return state;

		}
		case DELETE_TIME_SHEET_LINE_ITEM_FAILED:
		case ADD_TIMESHEET_FAILED:
		case COPY_TIMESHEET_FAILED:
		case GET_CURRENT_TIME_SHEET_FAILED:
		case GET_TIME_SHEET_FAILED: {
			if ( action.startedAt >= state.startedAt ) {
				return {
					...state,
					isLoading: false,
					error: action.error,
					timesheets: [],
				}
			}
			return state
		}
		default:
			return state;
	}
};
