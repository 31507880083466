import React from 'react';

import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import { InfoOutlined, Close } from '@material-ui/icons';
import useTranslation from '../../hooks/useTranslation';
import { CSSProperties } from '@material-ui/styles';

const useStyles = makeStyles( ( theme ) => {
	const hintContainer: CSSProperties = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textTransform: 'uppercase',
		color: theme.palette.custom.gray,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 'normal',
	};

	return {
		hintContainer,
		errorContainer: {
			...hintContainer,
			backgroundColor: theme.palette.error.dark,
		},
		actionText: {
			fontSize: theme.spacing( 1.6 ),
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 1.2 ),
			},
		},
		errorText: {
			color: theme.palette.primary.contrastText,
			fontSize: theme.spacing( 1.6 ),
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 1.2 ),
			},
		},
		hintIcon: {
			marginRight: theme.spacing( 1 ),
		},
		errorIcon: {
			marginRight: theme.spacing( 1 ),
			color: theme.palette.primary.contrastText,
			'&:hover': {
				cursor: 'pointer',
			},
		},
		alternativeActionButton: {
			fontSize: theme.spacing( 1.6 ),
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 1.2 ),
			},
			marginLeft: theme.spacing( 1 ),
		},
	};
} );

export interface FooterHintProps {
	hint: string;
	alternativeActionText?: string;
	errorText?: string;
	onCloseError?: () => void;
	onAlternativeActionClick?: () => void;
}

const FooterHint = ( {
	hint,
	alternativeActionText,
	onAlternativeActionClick,
	errorText,
	onCloseError,
}: FooterHintProps ) => {
	const classes = useStyles();
	const t = useTranslation();

	let hintContent;
	if ( alternativeActionText ) {
		hintContent = (
			<Typography variant="body2" className={ classes.actionText }>
				{`${ t( hint ) } ${ t( 'or' ) } ` }
				<Button
					className={ classes.alternativeActionButton }
					variant="contained"
					color="primary"
					onClick={ onAlternativeActionClick }
				>
					{ t( alternativeActionText ) }
				</Button>
			</Typography>
		);
	} else if ( errorText ) {
		hintContent = (
			<>
				<Close className={ classes.errorIcon } onClick={ onCloseError } />
				<Typography variant="body2" className={ classes.errorText }>
					{ `Oops...${ t( errorText ) }` }
				</Typography>
			</>
		);
	} else {
		hintContent = <Typography variant="body2">{ t( hint ) }</Typography>;
	}

	return (
		<Box className={ errorText ? classes.errorContainer : classes.hintContainer }>
			{!errorText && <InfoOutlined className={ classes.hintIcon } /> }
			{hintContent }
		</Box>
	);
};

export default FooterHint;
