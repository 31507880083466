import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box } from '@material-ui/core';

import { DateTime } from 'luxon';

import { getLeaveItemTitle } from './leave-utils';
import TemplateCard from '../../TemplateCard';
import { days, convertDecimalToTimeString } from '../../../utils/date';
import { LeaveRequest } from '../../../types/api/leaveRequests';
import useLanguage from '../../../hooks/useLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { getLeaveRequestActivities } from '../../../actions/api-actions';

export interface LeaveItemsProps {
	leaves: Array<LeaveRequest>;
}

const LeaveItems: React.FC<LeaveItemsProps> = ( { leaves } ) => {
	const history = useHistory();
	const language = useLanguage();
	const dispatch = useDispatch()

	const { activities } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	useEffect( () => {
		if ( !activities.length ) {
			dispatch( getLeaveRequestActivities() );
		}
	}, [ dispatch, activities.length ] );

	return (
		<Box flex="1">
			{ leaves.map( ( leave, index ) => {
				if ( !leave ) {
					return null;
				}

				const activityName =
					activities.find(
						( activity ) => activity.activityCode === leave.activityCode
					)?.activityDescription || 'Leave';
				const activityPrefix =
					activityName.length > 10
						? activityName.substr( 0, 10 ) + '...'
						: activityName;

				const startOfWeekDay = DateTime.fromISO( leave.startDateOfWeek );
				const endOfWeekDay = DateTime.fromISO( leave.endDateOfWeek );
				const leaveDayName = days.find( ( day ) => {
					return leave[ day ] !== 0;
				} );

				if ( days.filter( ( day ) => leave[ day ] !== 0 ).length === 1 ) {
					if ( !leaveDayName ) {
						return null;
					}

					return (
						<TemplateCard
							title={ getLeaveItemTitle(
								startOfWeekDay,
								endOfWeekDay,
								activityPrefix,
								language,
								leaveDayName
							) }
							color={ leave.status?.id === 'G' ? 'primary' : 'secondary' }
							chipLabel={
								leave.numberOfHours
									? convertDecimalToTimeString( leave.numberOfHours )
									: 'N/A'
							}
							key={ index }
							action={ () => {
								history.push( `/edit-leave-request/${ leave.id }`, {
									previous: history.location.pathname,
								} );
							} }
						/>
					);
				} else {
					return (
						<TemplateCard
							title={ getLeaveItemTitle(
								startOfWeekDay,
								endOfWeekDay,
								activityPrefix,
								language
							) }
							chipLabel={
								leave.numberOfHours
									? convertDecimalToTimeString( leave.numberOfHours )
									: 'N/A'
							}
							color={ leave.status?.id === 'G' ? 'primary' : 'secondary' }
							key={ index }
							action={ () => {
								history.push( `/edit-leave-request/${ leave.id }`, {
									previous: history.location.pathname,
								} );
							} }
						/>
					);
				}
			} ) }
		</Box>
	);
};

export default LeaveItems;
