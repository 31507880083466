import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import { Save } from '@material-ui/icons';
import useTranslation from '../../../hooks/useTranslation';
import { CSSProperties } from '@material-ui/styles';

const useStyles = makeStyles( ( theme ) => {
	const buttonProps: CSSProperties = {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
	};

	return {
		button: {
			...buttonProps,
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
			width: '100%',
		},
		editButton: {
			...buttonProps,
			width: '50%',
			'&:hover': {
				backgroundColor: theme.palette.background.paper,
			},
		}
	};
} );

interface ManageButtonsProps {
	title: string;
	isEditButton?: boolean;
	disabled?: boolean;
}

const ManageButtons: React.FC<ManageButtonsProps> = ( {
	title,
	isEditButton = false,
	disabled = false,
} ) => {
	const classes = useStyles();
	const t = useTranslation();

	return (
		<Button
			form="manage-expense-claim"
			className={ isEditButton ? classes.editButton : classes.button }
			type="submit"
			disabled={ disabled }
		>
			<Save />
			{ t( title ) }
		</Button>
	);
};

export default ManageButtons;
