import React from 'react';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import MostRecentDestinations from '../controls/MostRecentDestinations';
import LogoHeader from '../Headers/LogoHeader';
import FooterMenu from '../FooterMenu/FooterMenu';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles( theme => ( {
	links: {
		textDecoration: 'none',
	}
} ) );

const NoMatch: React.FC = () => {
	const classes = useStyles();
	return (
		<>
			<Box
				flex="1"
				paddingLeft="1em"
				paddingRight="1em"
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 10rem )"
				overflow="hidden"
			>
				<LogoHeader />
				<Box p={ 2 } paddingBottom={ 1 }>
					<Typography variant="h2">Oops</Typography>
					<br />
					<Typography>Seems like you're looking for something that can't be found.</Typography>
					<br />
				</Box>
				<Box flex="1" overflow="auto">
					<Typography>You might want to:</Typography>
					<Box paddingLeft={ 2 } padding={ 1 }>
						<Typography variant="h3">Log your time</Typography>
					</Box>
					<MostRecentDestinations />
					<Typography>or</Typography>
					<Box p={ 2 }>
						<NavLink className={ classes.links } to="/create-destination">
							<Button variant="contained" color="primary">Create a destination</Button>
						</NavLink>
					</Box>
				</Box>
			</Box>
			<FooterMenu />
		</>
	)
}

export default NoMatch;
