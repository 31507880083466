import React, { useEffect } from 'react';
import { makeStyles, Input } from '@material-ui/core';
import {
	convertDecimalToTimeString,
	getDecimalTimeString,
} from '../../../utils/date';
import { getTotalTime } from '../../screens/ManageTimesheet/helpers';
import { weekendsHoursProps } from '../../../config/constants';

const useStyles = makeStyles( ( theme ) => ( {
	timeSpentField: {
		textAlign: 'right',
		height: '100%',
		width: theme.spacing( 6.2 ),
		'& .MuiInputBase-input': {
			fontSize: theme.spacing( 1.5 ),
			fontWeight: 0,
			textAlign: 'right',
			cursor: 'pointer',
		},
	},
	selectedDayField: {
		textAlign: 'right',
		height: '100%',
		width: theme.spacing( 6.2 ),
		'& .MuiInputBase-input': {
			fontSize: theme.spacing( 1.75 ),
			color: theme.palette.primary.dark,
			fontWeight: 900,
			textAlign: 'right',
			cursor: 'pointer',
		},
	},
} ) );

export interface AddHoursFieldProps {
	inputRef: any;
	name: string;
	isAllWorkingDays: boolean;
	onSetFieldValue: ( time: string ) => void;
	timesheets: any;
	clockTime?: string;
	onOpenTimePicker: () => void;
	selectedDayProp?: string;
}

const AddHoursField: React.FC<AddHoursFieldProps> = ( props ) => {
	const classes = useStyles();
	const {
		inputRef,
		name,
		onSetFieldValue,
		timesheets,
		isAllWorkingDays,
		onOpenTimePicker,
		clockTime,
		selectedDayProp,
	} = props;

	useEffect( () => {
		if ( isAllWorkingDays && clockTime ) {
			const timesheetTime = getDecimalTimeString( clockTime, ':' );
			Object.keys( timesheets )
				.filter( ( hoursPropName ) => !weekendsHoursProps.includes( hoursPropName ) )
				.forEach(
					( timesheetProp ) => ( timesheets[ timesheetProp ] = Number( timesheetTime ) )
				);
			onSetFieldValue( getTotalTime( timesheets ) );
		} else {
			onSetFieldValue( getTotalTime( timesheets ) );
		}
	}, [ name, timesheets, onSetFieldValue, isAllWorkingDays, clockTime ] );

	return (
		<Input
			value={ convertDecimalToTimeString( timesheets[ name ] ) }
			inputRef={ inputRef }
			className={
				selectedDayProp === name
					? classes.selectedDayField
					: classes.timeSpentField
			}
			readOnly
			onClick={ onOpenTimePicker }
			name={ name }
		/>
	);
};

export default AddHoursField;
