import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { getWeekDays } from '../../../utils/date';

import AddTimesheetHeader from '../../Headers/AddTimesheetHeader';

import {
	addTimesheet,
	deleteTimesheetLineItem,
} from '../../../actions/api-actions';
import { TimesheetLineItem, Timesheet } from '../../../types/api/timesheets';

import MissingDataCreateTimesheet from './MissingDataCreateTimesheet';
import useCreateTimesheetData from '../../../hooks/useCreateTimesheetData';
import CreateTimesheetForm from '../../controls/CreateTimesheet/CreateTimesheetForm';
import { AppState } from '../../../reducers';
import { TimesheetState } from '../../../reducers/timesheets';
import DeleteConfirm from './DeleteConfirm';
import ManageTimesheetWrapper from './ManageTimeSheetWrapper';
import ManageTimesheetButton from './ManageTimesheetButton';
import TimesheetItemDescription from './TimesheetItemDescription';
import FooterHint from '../../controls/FooterHint';
import { getRequestHours } from './helpers';
import useManageTimesheetAccount from '../../../hooks/useManageTimesheetAccount';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( () => ( {
	createTimesheetFormContainer: {
		height: 'calc(100vh - 175px)',
	},
	buttons: {
		textTransform: 'none',
		textAlign: 'center',
	},
} ) );

const EditTimesheet: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const [ error, setError ] = useState<string>();
	const { selectedWeekRange } = useCreateTimesheetData();

	const { id: timesheetId, destination } = useParams<{ id: string, destination: string }>();

	const timesheetState: TimesheetState = useSelector<AppState, TimesheetState>(
		( state ) => state.timesheetsState
	);

	const destinations = useSelector(
		( state: AppState ) => state.settingsState.settings.destinations
	);

	const templateColor =
		destination && destinations
			? destinations[ destination ].templateColor
			: undefined;

	const timesheets: Timesheet[] = timesheetState.timesheets;
	const isLoading = timesheetState.isLoading;

	const timesheet = timesheets.find(
		( timesheetData ) =>
			!!timesheetData?.timeSheetInfos[ 0 ]?.timesheetDetails.find(
				( timesheetItem ) => timesheetItem.id === timesheetId
			)
	);

	useManageTimesheetAccount( timesheet?.employeeCode );

	const currentTimesheet = timesheet?.timeSheetInfos[ 0 ].timesheetDetails.find(
		( timesheetItem ) => timesheetItem.id === timesheetId
	);

	const timesheetInfos = timesheet?.timeSheetInfos[ 0 ];

	const timesheetItemName =
		currentTimesheet?.destination.desc === 'Project'
			? currentTimesheet?.projectDescription
			: currentTimesheet?.activityDescription;

	const t = useTranslation();

	const onSubmitForm = useCallback(
		( data: TimesheetLineItem ) => {
			if (
				currentTimesheet &&
				timesheet &&
				timesheetInfos &&
				timesheetItemName
			) {
				const { error: hoursError, requestData } = getRequestHours(
					data,
					timesheet,
					t,
					timesheetId,
				);

				if ( hoursError.message ) {
					setError( hoursError.message );
					return;
				} else if ( data.comment.length > 60 ) {
					setError( t( 'Maximum comment length is 60 symbols' ) );
					return;
				}


				const timesheetLineItem: TimesheetLineItem =
					currentTimesheet.destination.desc !== 'Project'
						? {
							id: timesheetId,
							...requestData,
							projectNumber: '',
							activityCode: currentTimesheet?.activityCode,
						}
						: {
							id: timesheetId,
							...requestData,
							activityCode: currentTimesheet?.activityCode,
							projectNumber: currentTimesheet?.projectNumber?.toString(),
							subProjectNumber: currentTimesheet?.subProjectNumber?.toString(),
							projectPhaseNumber: currentTimesheet?.projectPhaseNumber?.toString(),
							subTaskNumber: currentTimesheet?.subTaskNumber?.toString(),
						};

				dispatch(
					addTimesheet( {
						year: timesheetInfos.year,
						week: timesheetInfos.week,
						employeeCode: timesheet.employeeCode,
						addLineItemRequestRecords: [ timesheetLineItem ],
					} )
				);

				history.push( '/', { previous: history.location.pathname } );
			} else {
				console.error( 'No week selected' );
			}
		},
		[
			timesheetItemName,
			dispatch,
			history,
			currentTimesheet,
			timesheetId,
			timesheet,
			timesheetInfos,
			t,
		]
	);

	if (
		!timesheet ||
		!selectedWeekRange ||
		!timesheetItemName ||
		!currentTimesheet ||
		!currentTimesheet.activitySearchKey ||
		!currentTimesheet?.activityCode
	) {
		return (
			<MissingDataCreateTimesheet
				selectedWeekRange={ selectedWeekRange }
				isLoadingDestinations={ false }
				destinationName={ destination }
				isTimesheetExists={ !!currentTimesheet }
				isLoading={ isLoading }
			/>
		);
	}

	const onDelete = () => {
		const selectedWeekStart = DateTime.fromJSDate( selectedWeekRange.from );
		dispatch(
			deleteTimesheetLineItem(
				selectedWeekRange,
				selectedWeekStart.year,
				selectedWeekStart.weekNumber,
				[ currentTimesheet.id ]
			)
		);

		history.push( '/', { previous: history.location.pathname } );
	};

	const weekDays = getWeekDays( selectedWeekRange.from );
	const destinationName = `${ currentTimesheet.activityCode } - ${ currentTimesheet.activitySearchKey }`;
	const employeeCode = timesheet.employeeCode;

	return timesheet && currentTimesheet ? (
		<>
			<ManageTimesheetWrapper>
				<AddTimesheetHeader />
				{ isLoading && <Typography>{ 'Loading...' }</Typography> }
				<TimesheetItemDescription
					timesheetItemName={ timesheetItemName }
					destinationName={ destination || destinationName }
					destinationType={ currentTimesheet.destination.desc }
					chipCustomColor={ templateColor }
				/>
				<Box
					className={ classes.createTimesheetFormContainer }
					overflow="auto"
					paddingTop={ 0 }
					p={ 2 }
				>
					<CreateTimesheetForm
						onSubmit={ onSubmitForm }
						weekDays={ weekDays }
						currentTimesheet={ { ...currentTimesheet } }
						employeeCode={ employeeCode }
						comment={ currentTimesheet.comment }
					/>
				</Box>
			</ManageTimesheetWrapper>
			<div className={ classes.buttons }>
				<ManageTimesheetButton title="Save changes" isEditButton />
				<DeleteConfirm action={ onDelete } />
			</div>
			{ error && (
				<FooterHint
					errorText={ error }
					onCloseError={ () => {
						setError( '' );
					} }
					hint=""
				/>
			) }
		</>
	) : null;
};

export default EditTimesheet;
