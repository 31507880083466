import React from 'react';
import { Box, Typography } from '@material-ui/core';
import AddTimesheetHeader from '../../Headers/AddTimesheetHeader';
import { Destinations } from '../../../types/api/settings';
import { WeekRangeData } from '../../../actions/time-actions';
import useTranslation from '../../../hooks/useTranslation';

interface MissingDataCreateTimesheetProps {
	selectedWeekRange?: WeekRangeData;
	destinationName?: string;
	isLoadingDestinations: boolean;
	destinations?: Destinations;
	isTimesheetExists?: boolean;
	isLoading?: boolean;
}

const MissingDataCreateTimesheet: React.FC<MissingDataCreateTimesheetProps> = ( {
	destinationName,
	selectedWeekRange,
	isLoadingDestinations,
	destinations,
	isTimesheetExists = true,
	isLoading,
} ) => {
	const t = useTranslation();

	if ( !selectedWeekRange || isLoading ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 } textAlign="center">
					<Typography>{ t( 'Loading' ) }</Typography>
				</Box>
			</Box>
		);
	}

	if ( isLoadingDestinations ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 }>
					<Typography>{ t( 'Loading' ) }</Typography>
				</Box>
			</Box>
		);
	}

	if ( !destinations ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 }>
					<Typography>{ t( 'No destinations loaded' ) }</Typography>
				</Box>
			</Box>
		);
	}

	if ( !destinationName ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 }>
					<Typography>({ t( 'No destination provided' ) })</Typography>
				</Box>
			</Box>
		);
	}

	if ( !isTimesheetExists ) {
		return (
			<Box>
				<AddTimesheetHeader />
				<Box p={ 2 } textAlign="center">
					<Typography>{ t( 'No timesheet to edit' ) }</Typography>
				</Box>
			</Box>
		);
	}

	return null;
};

export default MissingDataCreateTimesheet;
