import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import useTranslation from '../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	loadingOverlay: {
		position: 'absolute',
		zIndex: 99,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		padding: theme.spacing( 2 ),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default,
		opacity: 0.9,
	},
} ) );

interface OverlayLoaderProps {
	loadingCondition: boolean;
	message: string;
}

const OverlayLoader: React.FC<OverlayLoaderProps> = ( {
	loadingCondition,
	message,
} ) => {
	const classes = useStyles();
	const t = useTranslation();
	return loadingCondition ? (
		<Box className={ classes.loadingOverlay }>
			<Typography>{ t( message ) }</Typography>
		</Box>
	) : null;
};

export default OverlayLoader;
