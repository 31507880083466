import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
	makeStyles,
	Box,
	Typography,
	Button,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from '@material-ui/core';
import clsx from 'clsx';

import {
	dismissExpenseClaimError,
	getExpenseClaimsSourceData,
	upSertExpenseClaim,
} from '../../../actions/api-actions';

import ManageExpenseClaimForm from './ManageExpenseClaimFormDesktop';
import ExpenseClaimsCodes from './ExpenseClaimsCodes';
import ProjectActivitiesMenu from './ProjectActivities';
import CodesCategories from './CodesCategories';
import OverlayLoader from '../../OverlayLoader';
import FileView from '../../FileView';
import useTranslation from '../../../hooks/useTranslation';
import { ExpenseClaim } from '../../../types/api/expenseClaims';
import { AttachFile, Save, Close } from '@material-ui/icons';
import { fileToBase64String } from '../../../utils/files';
import * as promises from '../../../utils/promises';
import useManageExpenseClaimsData from '../../../hooks/useManageExpenseClaimData';
import { AppState } from '../../../reducers';

const useStyles = makeStyles( ( theme ) => ( {
	manageContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	actionSection: {
		borderBottomStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		borderWidth: theme.spacing( 0.125 ),
		borderTopWidth: 0,
		display: 'flex',
		height: '4rem',
	},
	titleContainer: {
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'flex-start',
		flex: '1',
		padding: theme.spacing( 0, 2 ),
	},
	title: {
		fontWeight: 900,
		flex: 1,
	},
	sectionTitle: {
		fontWeight: 900,
		flex: 1,
		color: theme.palette.text.disabled,
	},
	button: {
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		fontWeight: 700,
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.11 ),
		fontSize: theme.spacing( 1.5 ),
		margin: theme.spacing( 0.5, 1, 0.5, 0 ),
		borderRadius: theme.spacing( 1 ),
		'&:first-child': {
			margin: theme.spacing( 0.5, 1 ),
		},
		'&:hover': {
			color: theme.palette.primary.main,
		},
		textTransform: 'none',
	},
	icon: {
		fontSize: theme.spacing( 2 ),
		margin: theme.spacing( 0, 0.25, 0, 0 ),
		cursor: 'pointer',
	},
	responseError: {
		color: theme.palette.secondary.main,
		fontSize: theme.spacing( 1.25 ),
		display: 'flex',
		alignItems: 'center',
	},
	selectClass: {
		flex: 1,
		margin: theme.spacing( 0 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 0.5 ),
		color: theme.palette.text.primary,
		overflow: 'hidden',
	},
	selectClassWithError: {
		flex: 1,
		margin: theme.spacing( 0 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 0.5 ),
		color: theme.palette.text.primary,
		overflow: 'hidden',
		'&::before': {
			content: '"This field is required"',
			color: theme.palette.error.dark,
		},
	},
	selectBackground: {
		backgroundColor: theme.palette.custom.lightBlueGray,
	},
	missingData: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing( 3, 0 ),
	},
	uploadBox: {
		backgroundColor: theme.palette.custom.paper,
		borderRadius: theme.spacing( 0.5 ),
		cursor: 'pointer',
		alignSelf: 'center',
		width: '100%',
		height: '95%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	inActiveUpload: {
		opacity: '0.5',
		cursor: 'default',
	},
	hint: {
		textAlign: 'center',
		width: '50%',
		fontWeight: 700,
		padding: theme.spacing( 3 ),
		color: theme.palette.secondary.main,
		position: 'absolute',
	},
	uploadSection: {
		height: 'calc(50vh - 14rem)',
		overflow: 'hidden',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	description: {
		padding: theme.spacing( 0, 1, 4 ),
		margin: theme.spacing( 1, 0 ),
		borderRadius: theme.spacing( 0.5 ),
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.25 ),
	},
	picker: {
		padding: theme.spacing( 0 ),
		cursor: 'pointer',
		'& .MuiInputBase-input ': {
			textAlign: 'left',
		},
	},
	attachBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	attachFile: {
		fontSize: theme.spacing( 7 ),
		marginBottom: theme.spacing( 1 ),
	},
	attachText: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
	},
	timeLabel: {
		textTransform: 'capitalize',
	},
	detailsSection: {
		width: '98%',
		alignSelf: 'center',
	},
	detailsContainer: {
		width: '50%',
	},
	uploadContainer: {
		width: '50%',
		display: 'flex',
		height: '100%',
	},
	fileList: {
		height: 'calc(50vh - 14rem)',
		overflow: 'auto',
		width: '50%',
	},
	deleteFile: {
		cursor: 'pointer',
	},
} ) );

export interface ManageExpenseClaimDesktopProps {
	action?: ( isActive: boolean ) => void;
	expenseClaimToEdit?: ExpenseClaim;
}

export interface ExpenseClaimProjectActivity {
	projectNumber?: string;
	projectDescription?: string;
	subProjectNumber?: string;
	subProjectDescription?: string;
	projectPhaseDescription?: string;
	projectPhaseNumber?: string;
}

const ManageExpenseClaimDesktop: React.FC<ManageExpenseClaimDesktopProps> = ( {
	action,
	expenseClaimToEdit,
} ) => {
	const classes = useStyles();
	const dispatch: any = useDispatch();
	const history = useHistory();

	const [ releaseExpenseClaim, setReleaseExpenseClaim ] =
		useState<boolean>( false );

	const [ files, setFiles ] = useState<
		Array<{ fileName: string; fileData: string }>
	>( [] );

	const [ error, setError ] = useState<string | undefined>();

	const id = expenseClaimToEdit?.id;

	const {
		projectActivities,
		expenseClaimsCodesDescriptions,
		defaultExpenseClaimDescription,
		defaultExpenseClaimType,
		defaultExpenseClaimCode,
		isLoading,
		expenseClaimsCodes,
		codesCategory,
		setCodesCategory,
		isActiveDropDown,
		setIsActiveDropDown,
		projectData,
		setSelectedProjectData,
		descriptionExpenseClaimCode,
		setDescriptionExpenseClaimCode,
		date,
		setDate,
		employeeCode,
	} = useManageExpenseClaimsData( id );

	const { responseError } = useSelector( ( state: AppState ) => ( {
		responseError: state.expenseClaimsState.error,
	} ) );

	const onCancelHandler = useCallback( () => {
		action
			? action( false )
			: history.push( '/expense-claims', {
				previous: history.location.pathname,
			} );
	}, [ action, history ] );

	useEffect( () => {
		if ( employeeCode && !projectActivities ) {
			dispatch( getExpenseClaimsSourceData() );
		}
	}, [ employeeCode, dispatch, projectActivities ] );

	const onDismissError = useCallback( () => {
		dispatch( dismissExpenseClaimError() );
	}, [ dispatch ] );

	const expenseClaimCodeData = expenseClaimsCodes?.find(
		( item ) => item.DescriptionExpenseClaimCode === descriptionExpenseClaimCode
	);

	const t = useTranslation();

	const onSubmit = useCallback(
		async ( data ) => {
			const requestData = {
				...data,
				amount: data.amount || 0,
				KM: data.KM || 0,
				id,
				employeeCode,
				releaseExpenseClaim,
			};

			const upDatedItemId = await dispatch( upSertExpenseClaim( requestData ) );

			if ( !!upDatedItemId ) {
				onCancelHandler();
			}
		},
		[ employeeCode, id, dispatch, releaseExpenseClaim, onCancelHandler ]
	);

	const onSetCategory = useCallback(
		( category: string ) => {
			setCodesCategory( category );
			setIsActiveDropDown( false );
			setTimeout( () => {
				setIsActiveDropDown( true );
			}, 0 );
			setError( undefined );
		},
		[ setCodesCategory, setIsActiveDropDown ]
	);

	const getFileData = useCallback( async ( file ) => {
		const fileData = await fileToBase64String( file );
		const fileName = file.name;
		return { fileName, fileData };
	}, [] );

	const onDrop = useCallback(
		async ( acceptedFiles ) => {
			try {
				const filesData = await promises.map( acceptedFiles, getFileData );
				setFiles( [ ...files, ...filesData ] );
			} catch ( e ) { }
		},
		[ files, getFileData ]
	);

	const onRemoveSelectedFile = ( fileName: string ) => {
		const newFiles = files.filter( ( file ) => file.fileName !== fileName );
		setFiles( newFiles );
	};

	const { getRootProps, getInputProps } = useDropzone( { onDrop } );

	let missingData = null;

	if ( !codesCategory && !expenseClaimToEdit ) {
		missingData = 'Add expense claim code category';
	} else if ( !expenseClaimCodeData && !expenseClaimToEdit ) {
		missingData = 'Select an expense claim code';
	}

	const uploadBoxClass =
		expenseClaimCodeData || expenseClaimToEdit
			? classes.uploadBox
			: clsx( classes.uploadBox, classes.inActiveUpload );

	if ( isLoading || !employeeCode ) {
		return <OverlayLoader message={ 'Loading' } loadingCondition={ true } />;
	}

	if ( !expenseClaimToEdit && id && employeeCode && !isLoading ) {
		return (
			<Typography className={ classes.missingData }>
				{ t( "Expense claim doesn't exist" ) }
			</Typography>
		);
	}

	const projectDataError: boolean =
		!!projectActivities &&
		!!expenseClaimCodeData?.type.includes( 'project' ) &&
		!!( descriptionExpenseClaimCode || defaultExpenseClaimCode ) &&
		!projectData?.projectDescription;

	return (
		<Box className={ classes.manageContainer }>
			<Box className={ classes.actionSection }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.title }>
						{ !id ? t( 'New expense claim' ) : t( `Edit expense claim` ) }
					</Typography>
				</Box>
				<Button
					form="manage-expense-claim"
					className={ classes.button }
					type="submit"
					disabled={
						!!error ||
						!( expenseClaimCodeData || expenseClaimToEdit ) ||
						projectDataError
					}
				>
					<Save className={ classes.icon } />
					{ t( 'Save' ) }
				</Button>
				<Button
					form="manage-expense-claim"
					className={ classes.button }
					type="submit"
					disabled={
						!!error ||
						!( expenseClaimCodeData || expenseClaimToEdit ) ||
						projectDataError
					}
					onClick={ () => {
						setReleaseExpenseClaim( true );
					} }
				>
					<Save className={ classes.icon } />
					{ t( 'Save and release' ) }
				</Button>
				<Button className={ classes.button } onClick={ onCancelHandler }>
					<Close className={ classes.icon } />
					{ t( 'Discard' ) }
				</Button>
			</Box>
			<Box className={ classes.detailsSection }>
				<Box className={ classes.actionSection }>
					<Box className={ classes.titleContainer }>
						<Typography className={ classes.sectionTitle }>
							{ t( 'Details' ) }
						</Typography>
						{ responseError && (
							<Typography className={ classes.responseError }>
								{ responseError }
								<Close className={ classes.icon } onClick={ onDismissError } />
							</Typography>
						) }
					</Box>
				</Box>
				<Box height="calc(50vh - 8rem)" overflow="auto" position="relative">
					<Box className={ classes.detailsContainer }>
						{ !id && (
							<CodesCategories
								onSelectCategory={ onSetCategory }
								selectedCategory={ codesCategory }
							/>
						) }
						{ ( isActiveDropDown || id ) &&
							expenseClaimsCodesDescriptions?.length &&
							( codesCategory || id ) ? (
							<ExpenseClaimsCodes
								selectClass={ classes.selectClass }
								expenseClaimsDescriptions={ expenseClaimsCodesDescriptions }
								onChangeValue={ setDescriptionExpenseClaimCode }
								selectedValue={
									( descriptionExpenseClaimCode &&
										expenseClaimsCodesDescriptions.includes(
											descriptionExpenseClaimCode
										)
										? descriptionExpenseClaimCode
										: undefined ) || defaultExpenseClaimDescription
								}
							/>
						) : null }
						{ projectActivities &&
							expenseClaimCodeData?.type.includes( 'project' ) &&
							( descriptionExpenseClaimCode || defaultExpenseClaimCode ) && (
								<ProjectActivitiesMenu
									projectActivities={ projectActivities }
									selectBackground={ classes.selectBackground }
									setSelectedProjectData={ setSelectedProjectData }
									selectedProjectData={ projectData }
									selectClass={ classes.selectClass }
									expenseClaimToEdit={ expenseClaimToEdit }
									autoExpand
								/>
							) }
						{ ( expenseClaimCodeData?.ExpenseClaimCode ||
							defaultExpenseClaimCode ) && (
								<ManageExpenseClaimForm
									date={ date }
									files={ files }
									setDate={ setDate }
									onSubmit={ onSubmit }
									expenseClaimToEdit={ expenseClaimToEdit }
									expenseClaimCode={
										expenseClaimCodeData?.ExpenseClaimCode ||
										defaultExpenseClaimCode
									}
									expenseClaimCodeType={
										expenseClaimCodeData?.type || defaultExpenseClaimType
									}
									error={ error }
									setError={ setError }
									{ ...projectData }
								/>
							) }
					</Box>
				</Box>
				<Box className={ classes.actionSection }>
					<Box className={ classes.titleContainer }>
						<Typography className={ classes.sectionTitle }>
							{ t( 'Documents' ) }
						</Typography>
					</Box>
				</Box>
				<Box className={ classes.uploadSection }>
					<Box className={ classes.uploadContainer }>
						{ missingData && (
							<Typography className={ classes.hint }>{ t( missingData ) }</Typography>
						) }
						<Box { ...getRootProps() } className={ uploadBoxClass }>
							{ ( expenseClaimCodeData || expenseClaimToEdit ) && (
								<input
									{ ...getInputProps() }
									name="files"
									accept="image/*,.pdf"
									type="file"
								/>
							) }
							<Box className={ classes.attachBox }>
								<AttachFile className={ classes.attachFile } />
								<Typography className={ classes.attachText }>
									{ files.length > 0
										? `${ files.length } ${ t( 'attached file(s)' ) }`
										: t( 'attach file(s)' ) }
								</Typography>
							</Box>
						</Box>
					</Box>
					<List className={ classes.fileList }>
						{ files.map( ( file ) => (
							<ListItem key={ file.fileName }>
								<ListItemIcon>
									<FileView
										base64String={ file.fileData }
										fileName={ file.fileName }
									/>
								</ListItemIcon>
								<ListItemText>
									{ file.fileName.length > 10
										? `${ file.fileName.slice( 0, 15 ) }...`
										: file.fileName }
								</ListItemText>
								<ListItemIcon>
									<Close
										className={ classes.deleteFile }
										onClick={ () => onRemoveSelectedFile( file.fileName ) }
									/>
								</ListItemIcon>
							</ListItem>
						) ) }
					</List>
				</Box>
			</Box>
		</Box>
	);
};
export default ManageExpenseClaimDesktop;
