import React, { useCallback } from 'react';

import {
	Box,
	Typography,
	makeStyles,
	Button,
	CircularProgress,
} from '@material-ui/core';

import ExpenseClaimHistoryDesktop from './ExpenseClaimHistoryDesktop';
import { ExpenseClaim } from '../../../types/api/expenseClaims';
import ExpenseClaimDetailsDesktop from './ExpenseClaimDetailsDesktop';
import { Edit, Save, Undo, Delete } from '@material-ui/icons';
import useTranslation from '../../../hooks/useTranslation';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useExpenseClaimActions from '../../../hooks/useExpenseClaimActions';
import ScreenMessage from '../../ScreenMessage';

export interface ExpenseClaimOptionsDesktopProps {
	expenseClaim?: ExpenseClaim;
	onManage: ( isActive: boolean ) => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 2 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		textAlign: 'center',
	},
	button: {
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.11 ),
		fontSize: theme.spacing( 1.5 ),
		margin: theme.spacing( 0.5, 1, 0.5, 0 ),
		'&:first-child': {
			margin: theme.spacing( 0.5, 1 ),
			color: theme.palette.primary.main,
		},
		'&:hover': {
			color: theme.palette.primary.main,
		},
		textTransform: 'none',
		borderRadius: theme.spacing( 1 ),
	},
	actionContainer: {
		borderStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		borderWidth: theme.spacing( 0.125 ),
		borderTopWidth: 0,
		display: 'flex',
		height: '4rem',
	},
	hiddenContainer: {
		borderBottomStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		borderWidth: theme.spacing( 0.125 ),
		display: 'flex',
		height: '4rem',
	},
	titleContainer: {
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'flex-start',
		flex: '1',
		padding: theme.spacing( 0, 2 ),
	},
	loader: {
		margin: theme.spacing( 0, 1 ),
	},
	sectionTitle: {
		borderTopStyle: 'solid',
		borderWidth: theme.spacing( 0.125 ),
		borderColor: theme.palette.custom.paper,
		fontWeight: 900,
		color: theme.palette.text.disabled,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: theme.spacing( 0, 2 ),
		width: '100%',
	},
	sectionContainer: {
		height: `calc( 50vh - 11rem )`,
		overflow: 'auto',
	},
	icon: {
		fontSize: theme.spacing( 2 ),
		margin: theme.spacing( 0, 0.25, 0, 0 ),
	},
} ) );

const ExpenseClaimOptionsDesktop: React.FC<ExpenseClaimOptionsDesktopProps> = ( {
	expenseClaim,
	onManage,
} ) => {
	const classes = useStyles();

	const { onReleaseExpenseClaim, onDeleteExpenseClaim } =
		useExpenseClaimActions();

	const id = expenseClaim?.id;
	const { isLoadingExpenseClaim } = useExpenseClaimsData( id );

	const onReleaseHandler = useCallback(
		( e ) => {
			if ( id ) {
				onReleaseExpenseClaim( id );
			}
		},
		[ id, onReleaseExpenseClaim ]
	);

	const onDeleteHandler = useCallback( () => {
		if ( id ) {
			onDeleteExpenseClaim( id );
		}
	}, [ id, onDeleteExpenseClaim ] );

	const { isLoadingProgress, expenseClaims, isLoadingDocuments } =
		useExpenseClaimsData( id );

	const disabled = !id || isLoadingExpenseClaim;

	const t = useTranslation();

	return (
		<>
			<Box className={ classes.hiddenContainer } />
			<Box className={ classes.actionContainer }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.pageTitle }>
						{ t( 'Expense claim details' ) }
					</Typography>
					{ isLoadingExpenseClaim && (
						<CircularProgress size={ 20 } className={ classes.loader } />
					) }
				</Box>
				<Button
					className={ classes.button }
					onClick={ () => {
						onManage( true );
					} }
					disabled={
						expenseClaim?.status?.id !== '0' || disabled || isLoadingDocuments
					}
				>
					<Edit className={ classes.icon } />
					{ t( 'Edit' ) }
				</Button>
				<Button
					className={ classes.button }
					onClick={ onReleaseHandler }
					disabled={
						Number( expenseClaim?.status?.id ) > 1 ||
						disabled ||
						isLoadingProgress
					}
				>
					{ expenseClaim?.status?.id === '0' ? (
						<Save className={ classes.icon } />
					) : (
						<Undo className={ classes.icon } />
					) }
					{ expenseClaim?.status?.id === '0' ? t( 'Release' ) : t( 'Back to draft' ) }
				</Button>
				<Button
					className={ classes.button }
					onClick={ onDeleteHandler }
					disabled={ expenseClaim?.status?.id !== '0' || disabled }
				>
					<Delete className={ classes.icon } />
					{ t( 'Delete' ) }
				</Button>
			</Box>
			{ expenseClaims?.length ? (
				<Box position="relative">
					<Box className={ classes.sectionContainer }>
						<ExpenseClaimDetailsDesktop id={ expenseClaim?.id } />
					</Box>
					<Box className={ classes.actionContainer }>
						<Typography className={ classes.sectionTitle }>
							{ t( 'Progress history' ) }
						</Typography>
					</Box>{ ' ' }
					<Box className={ classes.sectionContainer }>
						<ExpenseClaimHistoryDesktop id={ expenseClaim?.id } />
					</Box>
				</Box>
			) : (
				<ScreenMessage
					message={ 'No expense claims added for the selected year' }
				/>
			) }
		</>
	);
};

export default ExpenseClaimOptionsDesktop;
