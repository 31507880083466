import React from 'react';

import {
	Box,
	makeStyles,
	Divider,
	Typography,
	CircularProgress,
} from '@material-ui/core';

import { DateTime } from 'luxon';

import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useTranslation from '../../../hooks/useTranslation';

interface ExpenseClaimHistoryProps {
	id?: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		justifyItems: 'center',
	},
	number: {
		width: '20px',
		height: '20px',
		borderRadius: '50%',
		overflow: 'hidden',
		display: 'flex',
		flexShrink: 0,
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
		backgroundColor: theme.palette.custom.gray,
		color: theme.palette.primary.contrastText,
	},
	status: {
		flex: 2,
		padding: theme.spacing( 1 ),
	},
	step: {
		width: theme.spacing( 0.25 ),
		backgroundColor: theme.palette.custom.gray,
		margin: theme.spacing( 0.125, 0.25, 0.125, 0 ),
		height: theme.spacing( 7.5 ),
		display: 'inline-block',
	},
} ) );

const ExpenseClaimHistory: React.FC<ExpenseClaimHistoryProps> = ( { id } ) => {
	const classes = useStyles();
	const { approvalProgress, isLoadingProgress } = useExpenseClaimsData( id );
	const t = useTranslation();

	return !isLoadingProgress ? (
		<Box marginTop={ 2 } marginBottom={ 2 }>
			{ approvalProgress.map( ( element, index, array ) => (
				<Box key={ index }>
					<Divider />
					<Box className={ classes.container }>
						<Box flex="1">
							<Typography className={ classes.number } />
						</Box>
						<Box flex="3">
							<Typography>{ element.nameOfUser }</Typography>
							<Typography>
								{ `${ DateTime.fromJSDate(
									new Date( element.date )
								).toISODate() } - ${ element.time }` }
							</Typography>
						</Box>
						<Box flex="2">
							<Typography className={ classes.status }>
								{ element.status?.desc }
							</Typography>
						</Box>
					</Box>
					{ index < array.length - 1 && (
						<Box className={ classes.container }>
							<Box flex="1">
								<Typography className={ classes.step } />
							</Box>
							<Box flex="3" />
							<Box flex="2" />
						</Box>
					) }
				</Box>
			) ) }
		</Box>
	) : (
		<Box alignSelf="center" display="flex" alignItems="center">
			<Typography>{ t( 'Loading progress' ) }</Typography>
			<Box
				width={ 25 }
				height={ 25 }
				display="flex"
				justifyContent="center"
				alignItems="center"
				paddingLeft={ 1 }
			>
				<CircularProgress size={ 20 } />
			</Box>
		</Box>
	);
};

export default ExpenseClaimHistory;
