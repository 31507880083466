import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { DateTime } from 'luxon';

import ExpenseClaimTemplateCardDesktop from './ExpenseClaimTemplateCardDesktop';
import YearSelector from '../Leave/YearSelector';
import ScreenWrapper from '../ScreenWrapper';
import { AppState } from '../../../reducers';
import useTranslation from '../../../hooks/useTranslation';
import OverlayLoader from '../../OverlayLoader';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import { setSelectedExpenseClaimsYear } from '../../../actions/time-actions';
import { currentYear } from '../../controls/Minimal/helpers';

export interface ExpenseClaimsListDesktopProps {
	setExpenseClaimId: ( id: string ) => void;
	selectedId?: string;
}

const setDateFormat = ( date: Date ) =>
	DateTime.fromJSDate( new Date( date ) ).toISODate();

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 2 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
	},
	card: {
		'&:last-child': {
			marginBottom: theme.spacing( 5 ),
		},
	},
	add: {
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		fontWeight: 700,
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.1 ),
		fontSize: theme.spacing( 1.5 ),
		textAlign: 'center',
		margin: theme.spacing( 0.5, 1 ),
	},
	actionContainer: {
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.custom.paper,
		borderWidth: theme.spacing( 0.125 ),
		display: 'flex',
		height: '4rem',
	},
	titleContainer: {
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'flex-start',
		flex: '1',
		margin: theme.spacing( 0, 0, 0, 2 ),
	},
	frame: {
		borderRightStyle: 'solid',
		height: '100%',
		borderWidth: theme.spacing( 0.125 ),
		borderColor: theme.palette.custom.paper,
	},
} ) );

const ExpenseClaimsListDesktop: React.FC<ExpenseClaimsListDesktopProps> = ( {
	setExpenseClaimId,
	selectedId,
} ) => {
	const classes = useStyles();
	const history = useHistory();

	const { accounts: userAccounts } = useSelector(
		( state: AppState ) => state.userState
	);

	const { expenseClaimsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	const employeeCode = userAccounts[ 0 ]?.employeeCode;

	const dispatch = useDispatch();

	const { isLoading } = useSelector(
		( state: AppState ) => state.expenseClaimsState
	);

	useEffect( () => {
		if ( !expenseClaimsYear && employeeCode ) {
			dispatch( setSelectedExpenseClaimsYear( currentYear, employeeCode ) );
		}
	}, [ employeeCode, dispatch, expenseClaimsYear ] );

	const onChangeYear = useCallback(
		( year: number ) => {
			dispatch( setSelectedExpenseClaimsYear( year, employeeCode ) );
		},
		[ employeeCode, dispatch ]
	);

	const onSelectExpenseClaim = useCallback(
		( id: string ) => {
			setExpenseClaimId
				? setExpenseClaimId( id )
				: history.push( `/expense-claim/${ id }`, {
					previous: history.location.pathname,
				} );
		},
		[ setExpenseClaimId, history ]
	);

	const { expenseClaims } = useExpenseClaimsData();

	const t = useTranslation();

	return (
		<Box>
			<OverlayLoader
				message={ 'Loading expense claims data' }
				loadingCondition={ isLoading || !expenseClaims }
			/>
			<Box className={ classes.actionContainer }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.pageTitle }>
						{ t( 'Expense Claims' ) }
					</Typography>
					<Box>
						<YearSelector
							selectedYear={ expenseClaimsYear || currentYear }
							onChange={ onChangeYear }
						/>
					</Box>
				</Box>
			</Box>
			<Box className={ classes.actionContainer }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.pageTitle }>{ t( 'Overview' ) }</Typography>
				</Box>
			</Box>
			<ScreenWrapper>
				<Box className={ classes.frame }>
					{ expenseClaims &&
						expenseClaims.map( ( expenseClaim ) => (
							<ExpenseClaimTemplateCardDesktop
								key={ expenseClaim.id }
								employeeCode={ employeeCode }
								amount={ expenseClaim.salesAmount.toFixed( 2 ) }
								onSelect={ onSelectExpenseClaim }
								date={ setDateFormat( expenseClaim.date ) }
								statusId={ expenseClaim.status?.id }
								status={ expenseClaim.status?.desc }
								description={ expenseClaim.description }
								className={ classes.card }
								codeDescription={ expenseClaim.descriptionExpenseClaimCode }
								id={ expenseClaim.id }
								isSelected={ expenseClaim.id === selectedId }
							/>
						) ) }
				</Box>
			</ScreenWrapper>
		</Box>
	);
};

export default ExpenseClaimsListDesktop;
