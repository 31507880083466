import { createMuiTheme, /*, responsiveFontSizes */ } from '@material-ui/core';

import { lightCustomColors, darkCustomColors } from './colors';


const mainFontFamily = [
	'Montserrat',
	'sans-serif'
].join( ',' );

const secondaryFontFamily = [
	'Roboto', 'sans-serif'
].join( ',' );

/*
 * More info on theming: https://material-ui.com/customization/theming/
 */
export const lightTheme = createMuiTheme( {
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: lightCustomColors.turquoise,
			// dark: will be calculated from palette.primary.main,
			// contrastText: will be calculated to contrast with palette.primary.main
		},
		secondary: {
			main: lightCustomColors.orange,
		},
		custom: {
			...lightCustomColors
		},
		background: {
			default: "#fff",
		},
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	overrides: {
		MuiInput: {
			input: {
				'&::placeholder': {
					color: lightCustomColors.darkBlueGray,
					opacity: 0.9
				}
			}
		}
	},
	props: {
		MuiButtonBase: {
			disableRipple: true
		},
		MuiSelect: {
			disableUnderline: true
		},
		MuiInput: {
			disableUnderline: true,
		},
		MuiChip: {
			style: {
				fontFamily: secondaryFontFamily,
			}
		}
	},
	typography: {
		htmlFontSize: 10,
		fontFamily: mainFontFamily,
		body1: {
			lineHeight: '1.1'
		},
		body2: {
			fontFamily: secondaryFontFamily
		},
		h1: {
			fontSize: '2.9rem',
			lineHeight: '1.1',
			fontWeight: 'bold'
		},
		h2: {
			fontSize: '2.6rem',
			lineHeight: '1.1',
			fontWeight: 'bold'
		},
		h3: {
			fontSize: '2.6rem',
			lineHeight: '1.1',
			fontWeight: 'normal'
		},
		h4: {
			fontSize: '1.4rem',
			lineHeight: '1.5',
		},
	}
} )

export const darkTheme = createMuiTheme( {
	...lightTheme,
	palette: {
		type: "dark",
		primary: {
			main: lightCustomColors.turquoise,
		},
		secondary: {
			main: lightCustomColors.orange,
		},
		custom: {
			...darkCustomColors
		},
		background: {
			default: "#000",
		},
	},
	overrides: {
		...lightTheme.overrides,
		MuiInput: {
			input: {
				'&::placeholder': {
					color: darkCustomColors.darkBlueGrayContrastText,
					opacity: 0.9
				}
			}
		}
	},
} )

// theme = responsiveFontSizes( theme );

export default lightTheme;
