import {
	GET_SETTINGS_SUCCEEDED,
	GET_SETTINGS_STARTED,
	SAVE_SETTINGS_SUCCEEDED,
	SAVE_SETTINGS_STARTED,
	GET_SETTINGS_FAILED,
	SAVE_SETTINGS_FAILED,
	CLEAR_SETTINGS_SUCCEEDED,
} from './../actions/api-actions';
import { AppSettings } from '../types/api/settings';

export interface SettingsState {
	settings: AppSettings;
	error: string;
	isLoading: boolean;
}

export function settingsReducer (
	state: any = {
		settings: {},
		error: '',
		isLoading: false,
	},
	action: any
) {
	switch ( action.type ) {
		case GET_SETTINGS_SUCCEEDED: {
			let settings: any = action.settings?.settings;

			if ( settings && settings.printOptions ) {
				settings.printOptions = JSON.parse( settings.printOptions );
			}

			if ( settings && settings.destinations ) {
				settings.destinations = JSON.parse( settings.destinations );
			} else {
				settings = {
					destinations: {},
				};
			}

			return {
				...state,
				settings,
				isLoading: false,
				error: '',
			};
		}
		case SAVE_SETTINGS_SUCCEEDED: {
			return {
				...state,
				settings: action.settings,
				isLoading: false,
				error: '',
			};
		}
		case GET_SETTINGS_STARTED:
		case SAVE_SETTINGS_STARTED:
			return {
				...state,
				isLoading: true,
				error: '',
			};
		case GET_SETTINGS_FAILED:
		case SAVE_SETTINGS_FAILED:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		case CLEAR_SETTINGS_SUCCEEDED:
			return {
				...state,
				settings: {},
				isLoading: false,
				error: '',
			};
		default:
			return state;
	}
}
