import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';

import DestinationDescription from './DestinationDescription';

import { getWeekDays } from '../../../utils/date';

import { addTimesheet } from '../../../actions/api-actions';
import { TimesheetLineItem } from '../../../types/api/timesheets';

import MissingDataCreateTimesheet from './MissingDataCreateTimesheet';
import useCreateTimesheetData from '../../../hooks/useCreateTimesheetData';
import CreateTimesheetForm from '../../controls/CreateTimesheet/CreateTimesheetForm';
import ManageTimesheetWrapper from './ManageTimeSheetWrapper';
import ManageTimesheetButton from './ManageTimesheetButton';
import { getRequestHours } from './helpers';
import FooterHint from '../../controls/FooterHint';
import useUserAccountTimesheet from '../../../hooks/useUserAccountTimesheet';
import useManageTimesheetAccount from '../../../hooks/useManageTimesheetAccount';
import useTranslation from '../../../hooks/useTranslation';


const useStyles = makeStyles( ( theme ) => ( {
	createTimesheetFormContainer: {
		height: 'calc(100vh - 175px)',
	},
	button: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
} ) );

interface CreateTimesheetDesktopProps {
	destinationName: string;
	closeDialog: () => void;
	selectedDayProp?: string;
	selectedDayHours?: number;
}

const CreateTimesheetDesktop: React.FC<CreateTimesheetDesktopProps> = ( {
	destinationName,
	selectedDayProp,
	selectedDayHours,
	closeDialog,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [ error, setError ] = useState<string>( '' );
	const { selectedWeekRange, isLoadingDestinations, settings } =
		useCreateTimesheetData();

	const { destinations } = settings;

	const destination = destinations[ destinationName ];

	const employeeCode = destination ? destination.employeeCode : '';

	const { timesheet, isLoading } = useUserAccountTimesheet( employeeCode );

	useManageTimesheetAccount( employeeCode );

	const timesheetInfos = timesheet?.timeSheetInfos[ 0 ];

	const t = useTranslation();

	const onSubmitForm = useCallback(
		( data: TimesheetLineItem ) => {
			if ( timesheetInfos && destinationName && destination ) {
				const { error: hoursError, requestData } = getRequestHours(
					data,
					timesheet,
					t
				);

				if ( hoursError.message ) {
					setError( hoursError.message );
					return;
				} else if ( data.comment.length > 60 ) {
					setError( t( 'Maximum comment length is 60 symbols' ) );
					return;
				}

				const timesheetLineItem: TimesheetLineItem =
					destination.type !== 'Project'
						? {
							...requestData,
							projectNumber: '',
							activityCode: destination.activity.activityCode,
						}
						: {
							...requestData,
							activityCode: destination.activity.componentCode,
							projectNumber: destination.activity.projectNumber.toString(),
							subProjectNumber:
								destination.activity.SubprojectNumber.toString(),
							projectPhaseNumber:
								destination.activity.projectPhaseNumber.toString(),
							subTaskNumber: destination.activity.subTaskNumber.toString(),
						};

				dispatch(
					addTimesheet( {
						year: timesheetInfos.year,
						week: timesheetInfos.week,
						employeeCode: destination.employeeCode,
						addLineItemRequestRecords: [ timesheetLineItem ],
					} )
				);

				closeDialog();
			} else {
				console.error( 'No week selected' );
			}
		},
		[
			destination,
			destinationName,
			dispatch,
			timesheetInfos,
			timesheet,
			closeDialog,
			t,
		]
	);

	if (
		!selectedWeekRange ||
		isLoadingDestinations ||
		isLoading ||
		!destinations ||
		!destinationName ||
		!destination
	) {
		return (
			<MissingDataCreateTimesheet
				isLoading={ isLoading }
				selectedWeekRange={ selectedWeekRange }
				isLoadingDestinations={ isLoadingDestinations }
				destinations={ destinations }
				destinationName={ destinationName }
			/>
		);
	}

	const weekDays = getWeekDays( selectedWeekRange.from );

	return (
		<>
			<ManageTimesheetWrapper>
				<Box
					className={ classes.createTimesheetFormContainer }
					overflow="auto"
					p={ 2 }
					paddingTop={ 0 }
				>
					<DestinationDescription
						destination={ destination }
						destinationName={ destinationName }
						customChipColor={ destination.templateColor }
					/>
					<CreateTimesheetForm
						onSubmit={ onSubmitForm }
						weekDays={ weekDays }
						employeeCode={ employeeCode }
						selectedDayProp={ selectedDayProp }
						selectedDayHours={ selectedDayHours }
					/>
				</Box>
			</ManageTimesheetWrapper>
			<ManageTimesheetButton title="Save changes" />
			{error && (
				<FooterHint
					errorText={ error }
					onCloseError={ () => {
						setError( '' );
					} }
					hint=""
				/>
			) }
		</>
	);
};

export default CreateTimesheetDesktop;
