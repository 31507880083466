import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import {
	deleteExpenseClaimDocument,
	getExpenseClaimDocument,
} from '../../../actions/api-actions';
import { Close } from '@material-ui/icons';
import { ExpenseClaimDocument } from '../../../types/api/expenseClaims';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import FileView from '../../FileView';

export interface ExpenseClaimDetailsProps {
	document: ExpenseClaimDocument;
	expenseClaimId?: string;
	employeeCode?: string;
	documentId: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	file: {
		color: theme.palette.text.primary,
		padding: theme.spacing( 0, 1 ),
	},
	files: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing( 2, 1 ),
		justifyContent: 'center',
	},
	delete: {
		color: theme.palette.error.dark,
		cursor: 'pointer',
	},
	detailsProp: {
		padding: theme.spacing( 1, 2 ),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
} ) );

const ExpenseClaimFileView: React.FC<ExpenseClaimDetailsProps> = ( {
	document,
	expenseClaimId,
	documentId,
	employeeCode,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [ isLoadingDocument, setIsLoadingDocument ] = useState<boolean>( false );
	const onDeleteDocument = useCallback( () => {
		if ( employeeCode && expenseClaimId ) {
			setIsLoadingDocument( true );
			dispatch(
				deleteExpenseClaimDocument( {
					employeeCode,
					expenseClaimId,
					documentId,
				} )
			);
		}
	}, [ dispatch, employeeCode, expenseClaimId, documentId ] );

	const onLoadDocument = useCallback( async () => {
		if ( employeeCode && expenseClaimId && !document.isLoaded ) {
			setIsLoadingDocument( true );
			dispatch(
				getExpenseClaimDocument( {
					employeeCode,
					expenseClaimId,
					documentId,
				} )
			);
		}
	}, [ dispatch, employeeCode, expenseClaimId, documentId, document.isLoaded ] );

	useEffect( () => {
		if (
			isLoadingDocument &&
			( document.isLoaded || document.failed || !document.document )
		) {
			setIsLoadingDocument( false );
		}
	}, [
		document.isLoaded,
		document.document,
		document.failed,
		isLoadingDocument,
	] );

	return (
		<Box className={ classes.files } key={ document.id }>
			<Box flex="2" display="flex" alignItems="center">
				<FileView
					base64String={
						document.files?.length ? document.files[ 0 ].fileData : undefined
					}
					fileName={ document.document }
					isLoading={ isLoadingDocument }
					onClick={ onLoadDocument }
				/>
				<Typography className={ classes.file } key={ document.document }>
					{ document.document }
				</Typography>
			</Box>
			<Box flex="2">
				<Typography className={ classes.file } key={ document.document }>
					{ DateTime.fromJSDate( new Date( document.timeCreated ) ).toISODate() }
				</Typography>
			</Box>
			{!isLoadingDocument && (
				<Box flex="1" textAlign="right">
					<Close className={ classes.delete } onClick={ onDeleteDocument } />
				</Box>
			) }
		</Box>
	);
};

export default ExpenseClaimFileView;
