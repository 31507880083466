import {
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED,
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED,
	GET_EXPENSE_CLAIM_DETAILS_FAILED,
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED,
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED,
	GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED,
	GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED,
	RELEASE_EXPENSE_CLAIM_SUCCEEDED,
	GET_EXPENSE_CLAIMS_SUCCEEDED,
	DELETE_EXPENSE_CLAIM_SUCCEEDED,
	GET_EXPENSE_CLAIM_SUCCEEDED,
} from '../actions/api-actions';
import { ExpenseClaim, ExpenseClaimDetails } from '../types/api/expenseClaims';

export interface ExpenseClaimDetailsState {
	isLoading: boolean;
	details: Array<ExpenseClaimDetails>;
}

const defaultElementState = {
	approvalRoute: null,
	approvalProgress: [],
	isLoadingProgress: false,
	isLoadingApprovalRoute: false,
	id: undefined,
};

export const expenseClaimDetailsReducer = (
	state: ExpenseClaimDetailsState = {
		details: [],
		isLoading: false,
	},
	action: any
) => {
	switch ( action.type ) {
		case GET_EXPENSE_CLAIMS_SUCCEEDED: {
			const expenseClaims: Array<ExpenseClaim> =
				action.expenseClaimsData.expenseClaims;
			const details: Array<ExpenseClaimDetails> = expenseClaims.map(
				( expenseClaim ) => {
					const currentAttachmentIndex = state.details.findIndex(
						( item ) => item.id === expenseClaim.id
					);
					if ( currentAttachmentIndex !== -1 ) {
						return {
							...state.details[ currentAttachmentIndex ],
						};
					}
					return {
						...defaultElementState,
						id: expenseClaim.id,
					};
				}
			);
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_SUCCEEDED: {
			const expenseClaim: ExpenseClaim = action.expenseClaim;

			const currentElement = state.details.find(
				( element ) => element.id === expenseClaim.id
			);

			const details: Array<ExpenseClaimDetails> = [ ...state.details ]

			if ( !currentElement ) {
				details.push( {
					...defaultElementState,
					id: expenseClaim.id,
				} );
			}
			return { ...state, details };
		}
		case RELEASE_EXPENSE_CLAIM_SUCCEEDED: {
			const details = [ ...state.details ];

			const detailsIndex = details.findIndex(
				( element ) => element.id === action.expenseClaimId
			);
			details[ detailsIndex ] = { ...details[ detailsIndex ] };
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_STARTED: {
			const details = [ ...state.details ];
			const detailsIndex = details.findIndex(
				( element ) => element.id === action.expenseClaimId
			);
			details[ detailsIndex ] = {
				...details[ detailsIndex ],
				isLoadingProgress: true,
			};
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_APPROVAL_ROUTE_STARTED: {
			const details = [ ...state.details ];
			const detailsIndex = details.findIndex(
				( element ) => element.id === action.expenseClaimId
			);
			details[ detailsIndex ] = {
				...details[ detailsIndex ],
				isLoadingApprovalRoute: true,
			};
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_SUCCEEDED: {
			const details = [ ...state.details ];
			const detailsIndex = details.findIndex(
				( element ) => element.id === action.expenseClaimId
			);
			details[ detailsIndex ] = {
				...details[ detailsIndex ],
				approvalProgress: action.approvalProgress,
				isLoadingProgress: false,
			};
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_APPROVAL_ROUTE_SUCCEEDED: {
			const details = [ ...state.details ];
			const detailsIndex = details.findIndex(
				( element ) => element.id === action.expenseClaimId
			);
			details[ detailsIndex ] = {
				...details[ detailsIndex ],
				approvalRoute: action.approvalRoute,
				isLoadingApprovalRoute: false,
			};
			return { ...state, details };
		}
		case DELETE_EXPENSE_CLAIM_SUCCEEDED: {
			const { id } = action;
			const details = state.details.filter( ( element ) => element.id !== id );
			return { ...state, details };
		}
		case GET_EXPENSE_CLAIM_APPROVAL_ROUTE_FAILED:
		case GET_EXPENSE_CLAIM_APPROVAL_PROGRESS_FAILED:
		case GET_EXPENSE_CLAIM_DETAILS_FAILED: {
			return {
				...state,
				isLoading: false,
			};
		}

		default:
			return state;
	}
};
