import React from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import { makeStyles, Box } from '@material-ui/core';

import Calendar from './screens/Calendar/Calendar';
import Minimal from './screens/Minimal/Minimal';
import Leave from './screens/Leave/Leave';
import Settings from './screens/Settings/Settings';
import ManageDestination from './screens/CreateDestination/ManageDestination';
import SelectDestination from './screens/SelectDestination/SelectDestination';
import CreateTimesheet from './screens/ManageTimesheet/CreateTimesheet';
import CreateTimesheetTempDestination from './screens/ManageTimesheet/CreateTimesheetTempDestination';

import FooterMenu from './FooterMenu/FooterMenu';
import NoMatch from './screens/NoMatch';
import LoginCallback from './LoginCallback';
import EditTimesheet from './screens/ManageTimesheet/EditTimesheet';
import DesktopLayout from './Desktop/DesktopLayout';
import ManageLeaves from './screens/ManageLeaves/ManageLeaves';
import ExpenseClaims from './screens/ExpenseClaims/ExpenseClaims';
import ManageExpenseClaims from './screens/ManageExpenseClaims/ManageExpenseClaim';
import ExpenseClaimOptions from './screens/ExpenseClaimsOptions/ExpenseClaimOptions';
import TimesheetDesktop from './screens/DesktopTimesheet/TimesheetDesktop';
import ExpenseClaimsDesktop from './screens/ExpenseClaims/ExpenseClaimsDesktop';
import ManageExpenseClaimDesktop from './screens/ManageExpenseClaims/ManageExpenseClaimDesktop';
import useDesktopHomeMenuActions from '../hooks/useDesktopHomeMenuActions';
import LeavesDesktop from './screens/Leave/LeavesDesktop';
import useWindowSize from '../hooks/useWindowSize';

export interface RouterProps {
	onSignOut: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	mobile: {
		display: 'none',
		[ theme.breakpoints.down( 'md' ) ]: {
			display: 'flex',
			flexDirection: 'column',
			height: 'calc( 100vh - 10rem )',
			flex: '1',
		},
	},
	desktop: {
		[ theme.breakpoints.down( 'md' ) ]: {
			display: 'none',
		},
	},
} ) );

const AppRouter: React.FC<RouterProps> = ( { onSignOut } ) => {
	const classes = useStyles();
	const desktopHomeMenuActions = useDesktopHomeMenuActions();

	const windowSize = useWindowSize();

	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<Box className={ classes.mobile }>
						<Minimal />
						<FooterMenu />
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout
							onLogOutClick={ onSignOut }
							homeMenuActions={ desktopHomeMenuActions }
						>
							<TimesheetDesktop homeMenuActions={ desktopHomeMenuActions } />
						</DesktopLayout>
					</Box>
				</Route>
				<Route exact path="/expense-claims/">
					<Box className={ classes.mobile }>
						{ windowSize.width && windowSize.width < 1280 && <ExpenseClaims /> }
						<FooterMenu />
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout onLogOutClick={ onSignOut }>
							{ windowSize.width && windowSize.width >= 1280 && (
								<ExpenseClaimsDesktop />
							) }
						</DesktopLayout>
					</Box>
				</Route>
				<Route path="/expense-claim/:id">
					<Box className={ classes.mobile }>
						{ windowSize.width && windowSize.width < 1280 && (
							<ExpenseClaimOptions />
						) }
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout onLogOutClick={ onSignOut }>
							{ windowSize.width && windowSize.width >= 1280 && (
								<ExpenseClaimsDesktop />
							) }
						</DesktopLayout>
					</Box>
				</Route>
				<Route path="/create-expense-claim">
					<Box className={ classes.mobile }>
						<ManageExpenseClaims />
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout onLogOutClick={ onSignOut }>
							<ManageExpenseClaimDesktop />
						</DesktopLayout>
					</Box>
				</Route>
				<Route path="/leave">
					<Box className={ classes.mobile }>
						{ windowSize.width && windowSize.width < 1280 && <Leave /> }
						<FooterMenu />
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout onLogOutClick={ onSignOut }>
							{ windowSize.width && windowSize.width >= 1280 && (
								<LeavesDesktop editMode />
							) }
						</DesktopLayout>
					</Box>
				</Route>
				<Route path="/create-leave-request">
					<Box className={ classes.mobile }>
						{ windowSize.width && windowSize.width < 1280 && <ManageLeaves /> }
					</Box>
					<Box className={ classes.desktop }>
						<DesktopLayout onLogOutClick={ onSignOut }>
							{ windowSize.width && windowSize.width >= 1280 && (
								<LeavesDesktop editMode={ false } />
							) }
						</DesktopLayout>
					</Box>
				</Route>
				<Route path="/calendar">
					<Calendar />
					<FooterMenu />
				</Route>
				<Route path="/minimal">
					<Minimal />
					<FooterMenu />
				</Route>
				<Route path="/settings">
					<Settings onLogOutClick={ onSignOut } />
					<FooterMenu />
				</Route>
				<Route path="/select-destination">
					<SelectDestination />
				</Route>
				<Route path="/create-destination">
					<ManageDestination />
				</Route>
				<Route path="/edit-destination/:destination">
					<ManageDestination />
				</Route>
				<Route path="/create-timesheet/:destination">
					<CreateTimesheet />
				</Route>
				<Route path="/edit-timesheet/:destination/:id">
					<EditTimesheet />
				</Route>
				<Route path="/edit-timesheet/:id">
					<EditTimesheet />
				</Route>
				<Route path="/edit-leave-request/:id">
					<ManageLeaves />
				</Route>
				<Route path="/create-timesheet">
					<Redirect to="/select-destination" />
				</Route>
				<Route path="/create-timesheet-without-template">
					<CreateTimesheetTempDestination />
				</Route>
				<Route path="/login_callback">
					<LoginCallback />
				</Route>
				<Route>
					<NoMatch />
				</Route>
			</Switch>
		</Router>
	);
};

export default AppRouter;
