import React from 'react';

import { Icon } from '@material-ui/core';
import { IconProps } from './interfaces';

const OnTimeHomeIcon = ( { color, width, height, className }: IconProps ) => (
	<Icon className={ className }>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.22 26.53" stroke="currentColor" fill="currentColor" strokeWidth="0" width={ width } height={ height } >
			<g id="a4095c77-1284-43a5-bc0f-381ed158f69b" data-name="Time registration">
				<path d="M12,14.83,9,13.15V8.57a.52.52,0,0,0-.51-.5.49.49,0,0,0-.49.5v5.17l3.49,2a.64.64,0,0,0,.24,0,.49.49,0,0,0,.44-.24A.52.52,0,0,0,12,14.83Z" />
				<path d="M10,20.27a7.14,7.14,0,0,1-1.79.22,7.23,7.23,0,1,1,7.15-8.32.52.52,0,0,0,.57.42.5.5,0,0,0,.42-.57,8.23,8.23,0,1,0-8.14,9.47,8.14,8.14,0,0,0,2-.25.51.51,0,0,0,.36-.61A.5.5,0,0,0,10,20.27Z" />
				<path d="M22.93,11.49,21.24,9.8c-.33-.31-.7-.38-.93-.16L19,11h0L12.86,17.1a.75.75,0,0,0-.23.55v1.68a.78.78,0,0,0,.78.77H15.1a.79.79,0,0,0,.54-.22l6.13-6.13v0l1.31-1.31C23.32,12.19,23.25,11.82,22.93,11.49Zm-7.84,7.86-1.71,0V17.65l6.17-6.15,1.69,1.72Zm6.69-6.68v0L20.08,11l0,0,.63-.62h0L22.39,12l0,0Z" />
			</g>
		</svg>
	</Icon>
);

export default OnTimeHomeIcon;