import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from '@material-ui/core';

import { useStyles } from '../../Desktop/desktop-styles';

import { manageWorkingHoursProps } from '../../../config/constants';
import { AppState } from '../../../reducers';
import { setTempDestination } from '../../../actions/ui-actions';
import useAccountTimesheetManagement from '../../../hooks/useAccountTimesheetManagement';
import { getDayTotalNumberOfHours } from '../../Desktop/desktop-utils';
import TimesheetManagement from './TimesheetManagementDesktop';
import { Destination } from '../../../types/api/settings';
import OverlayLoader from '../../OverlayLoader';
import WeekTimesheetList from './WeekTimesheetList';
import { HomeMenuActions } from '../../../hooks/useDesktopHomeMenuActions';
import ScreenMessage from '../../ScreenMessage';
import WeekSummary from './WeekSummary';
import DesktopWeekSelector from '../../controls/Minimal/DesktopWeekSelector';
import ReloadPage from '../../ReloadPage';
import useErrorData from '../../../hooks/useErrorData';

export interface TimesheetDesktopProps {
	homeMenuActions: HomeMenuActions;
}

const TimesheetDesktop: React.FC<TimesheetDesktopProps> = ( {
	homeMenuActions,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ isManageDialogOpen, setIsManageDialogOpen ] = useState<boolean>( false );
	const [ isManagingDestination, setIsManagingDestination ] =
		useState<boolean>( false );
	const [ timesheetHoursProp, setTimesheetHoursProp ] = useState<string>( '' );
	const [ selectedDayHours, setSelectedDayHours ] = useState<number>( 0 );
	const [ timesheetId, setTimesheetId ] = useState<string>();

	const error = useErrorData();

	const [ selectedDestinationName, setSelectedDestinationName ] =
		useState<string>();

	const openManageDialog = useCallback(
		( hours: number, dayProp: string, templateName?: string, id?: string ) => {
			if ( !id ) {
				setTimesheetHoursProp( dayProp );
				setSelectedDayHours( hours );
			}
			setTimesheetId( id );
			setSelectedDestinationName( templateName );
			setIsManageDialogOpen( true );
			dispatch( setTempDestination( null ) );
		},
		[ dispatch ]
	);

	const tempDestination = useSelector(
		( state: AppState ) => state.tempDestinationState.destination
	);

	const onCloseManageDialog = useCallback( () => {
		setIsManageDialogOpen( false );
		setIsManagingDestination( false );
		setSelectedDayHours( 0 );
		setTimesheetHoursProp( '' );
		homeMenuActions.setIsAddingHours( false );
		setSelectedDestinationName( undefined );
	}, [ homeMenuActions ] );

	const {
		isLoading,
		selectedWeekRange,
		currentAccountData,
		setSelectedWeekRange,
	} = useAccountTimesheetManagement();

	const totalHoursByDay = currentAccountData
		? getDayTotalNumberOfHours( currentAccountData.timesheets )
		: undefined;

	return !error ? (
		<>
			<TimesheetManagement
				selectedDestinationName={ selectedDestinationName }
				isOpen={ isManageDialogOpen || homeMenuActions.isAddingHours }
				onClose={ onCloseManageDialog }
				timesheetId={ timesheetId }
				onSetSelectedDestination={ setSelectedDestinationName }
				setIsManagingDestination={ setIsManagingDestination }
				isManagingDestination={ isManagingDestination }
				tempDestination={ tempDestination as Destination }
				selectedDayProp={ timesheetHoursProp || 'monday' }
				selectedDayHours={ selectedDayHours || 0 }
			/>
			<OverlayLoader
				loadingCondition={
					isLoading ||
					!selectedWeekRange ||
					!totalHoursByDay ||
					!currentAccountData
				}
				message={ 'Loading timesheet data' }
			/>
			{ currentAccountData?.isInactiveForSelectedWeek && (
				<ScreenMessage
					message={ 'inactive account' }
					className={ classes.inactiveAccount }
				/>
			) }
			<Box overflow="auto">
				<Box className={ classes.weekSelector }>
					<DesktopWeekSelector
						onWeekRangeSelected={ setSelectedWeekRange }
						selectedWeekRange={ selectedWeekRange }
					/>
				</Box>
				{ currentAccountData?.isInactiveForSelectedWeek && (
					<Box className={ classes.inactiveOverlay } />
				) }
				<Box className={ classes.listContainer }>
					{ currentAccountData &&
						totalHoursByDay &&
						selectedWeekRange &&
						manageWorkingHoursProps.map( ( weekDayProp ) => {
							return (
								<WeekTimesheetList
									onOpenTimesheetManagement={ openManageDialog }
									weekDayProp={ weekDayProp }
									currentAccountWeekActivities={ currentAccountData.timesheets }
									totalHoursByDay={ totalHoursByDay }
									key={ `layout-${ weekDayProp.label }` }
								/>
							)
						} ) }
				</Box>
				{ currentAccountData?.timesheets && (
					<WeekSummary
						currentAccountWeekActivities={ currentAccountData.timesheets }
						contractHours={ currentAccountData.contractHours }
					/>
				) }
			</Box>
		</>
	) : error.type !== "USER" && error.type !== "SETTINGS" ? (
		<ReloadPage
			selectedWeekRange={ selectedWeekRange }
			error={ error }
		/>
	) : null;
};

export default TimesheetDesktop;
