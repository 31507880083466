import React, { useCallback } from 'react';

import { Box, Typography, Paper, makeStyles, Tooltip } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';

import Chip from './controls/Chip';
import clsx from 'clsx';
import useTranslation from '../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		cursor: 'pointer',
	},
	inactivePaper: {
		opacity: 0.5,
	},
	editIcon: {
		marginRight: theme.spacing( 1 ),
		color: theme.palette.primary.main,
	},
	deleteIcon: {
		color: theme.palette.error.main,
		marginRight: theme.spacing( 1 ),
	},
} ) );

export interface SelectDestinationTemplateCardProps {
	isActive: boolean;
	title: string;
	color: 'primary' | 'secondary' | 'dark';
	customChipColor?: string;
	onCardClick: ( title: string ) => void;
	onEditClick: ( title: string ) => void;
	onDeleteClick: ( title: string ) => void;
}

const SelectDestinationTemplateCard: React.FC<SelectDestinationTemplateCardProps> = ( {
	isActive,
	title,
	color,
	customChipColor,
	onEditClick,
	onCardClick,
	onDeleteClick,
} ) => {
	const classes = useStyles();

	const onCardTitleClick = useCallback( () => {
		if ( isActive === false ) {
			return;
		}
		onCardClick( title );
	}, [ onCardClick, title, isActive ] );

	const onEditChipClick = useCallback( () => {
		onEditClick( title );
	}, [ onEditClick, title ] );

	const onDeleteChipClick = useCallback( () => {
		onDeleteClick( title );
	}, [ onDeleteClick, title ] );

	let paperClassName = classes.paper;

	if ( isActive === false ) {
		paperClassName = clsx( classes.paper, classes.inactivePaper );
	}

	const t = useTranslation();

	return (
		<Box m={ 2 }>
			<Paper
				className={ paperClassName }
				title={
					isActive === false
						? 'You cannot log time to this project for the selected week.'
						: undefined
				}
			>
				<Box display="flex">
					<Box
						onClick={ onCardTitleClick }
						p={ 2 }
						flex="1"
						display="flex"
						alignItems="center"
					>
						<Chip
							color={ color }
							label=""
							style={ {
								padding: '0.75em 0.4em',
								marginRight: '1rem',
								display: 'inline-block',
								backgroundColor: customChipColor,
							} }
						/>
						<Typography variant="h4">{ title }</Typography>
					</Box>
					{ isActive !== false ? (
						<Box onClick={ onEditChipClick } alignSelf="center">
							<Tooltip title={ t( "Edit destination" ) || false } placement="top">
								<Edit className={ classes.editIcon } />
							</Tooltip>
						</Box>
					) : null }

					<Box onClick={ onDeleteChipClick } alignSelf="center">
						<Tooltip title={ t( "Delete destination" ) || false } placement="top">
							<Delete className={ classes.deleteIcon } />
						</Tooltip>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};
export default SelectDestinationTemplateCard;
