import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Box, makeStyles, Typography, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import YearSelector from './YearSelector';
import LeaveItems from './LeaveItems';
import LeaveBalance from './LeaveBalance';
import OverlayLoader from '../../OverlayLoader';
import ScreenWrapper from '../ScreenWrapper';
import { AppState } from '../../../reducers';
import useTranslation from '../../../hooks/useTranslation';
import { setSelectedLeaveRequestsYear } from '../../../actions/time-actions';
import { currentYear } from '../../controls/Minimal/helpers';
import ScreenMessage from '../../ScreenMessage';

export interface LeaveProps { }

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 3 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		padding: theme.spacing( 3, 0, 0, 4 ),
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing( 1 ),
		right: '12.5%',
		transform: 'translate( 50%, 0 )',
	},
	desktopButton: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
} ) );

const Leave: React.FC<LeaveProps> = () => {
	const classes = useStyles();
	const history = useHistory();

	const { accounts: userAccounts } = useSelector(
		( state: AppState ) => state.userState
	);

	const onAddLEaveRequest = useCallback( () => {
		history.push( '/create-leave-request', { previous: history.location.pathname } );
	}, [ history ] );

	const employeeCode = userAccounts[ 0 ]?.employeeCode;

	const dispatch = useDispatch();

	const { isLoading } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	const { leaveRequestsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	useEffect( () => {
		if ( !leaveRequestsYear ) {
			dispatch( setSelectedLeaveRequestsYear( currentYear, employeeCode ) );
		}
	}, [ employeeCode, dispatch, leaveRequestsYear ] );

	const onChangeYear = useCallback(
		( year: number ) => {
			dispatch( setSelectedLeaveRequestsYear( year, employeeCode ) );
		},
		[ employeeCode, dispatch ]
	);

	const leaveRequests = useSelector(
		( state: AppState ) => state.leaveRequestState.accountRequests?.leaveRequests
	);

	const leaveBalances = useSelector(
		( state: AppState ) => state.leaveRequestState.accountRequests?.leaveBalances
	);

	const t = useTranslation();

	return (
		<ScreenWrapper>
			<OverlayLoader
				message={ 'Loading leave requests data' }
				loadingCondition={ !leaveRequests || isLoading }
			/>
			<Box>
				<Typography className={ classes.pageTitle }>
					{ t( 'Leave Overview' ) }
				</Typography>
				<YearSelector
					selectedYear={ leaveRequestsYear || currentYear }
					onChange={ onChangeYear }
				/>
			</Box>
			{ leaveRequests?.length ? (
				<Box flex="1" overflow="auto" flexDirection="column" display="flex">
					<Box flex="1">
						{ leaveRequests && <LeaveItems leaves={ leaveRequests } /> }
					</Box>
					{ leaveBalances && <LeaveBalance balances={ leaveBalances } /> }
				</Box>
			) : (
				<ScreenMessage
					message={ 'No leave requests added for the selected year' }
				/>
			) }
			<Fab
				color="primary"
				className={ classes.fab }
				aria-controls="create-leave-request"
				onClick={ onAddLEaveRequest }
			>
				<Add />
			</Fab>
		</ScreenWrapper>
	);
};

export default Leave;
