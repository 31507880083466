import {
	GET_EXPENSE_CLAIMS_STARTED,
	GET_EXPENSE_CLAIMS_SUCCEEDED,
	GET_EXPENSE_CLAIMS_FAILED,
	RELEASE_EXPENSE_CLAIM_FAILED,
	DELETE_EXPENSE_CLAIM_FAILED,
	CREATE_EXPENSE_CLAIM_FAILED,
	CREATE_EXPENSE_CLAIM_STARTED,
	DISMISS_EXPENSE_CLAIM_ERROR,
	GET_EXPENSE_CLAIM_SUCCEEDED,
	GET_EXPENSE_CLAIM_STARTED,
	DELETE_EXPENSE_CLAIM_STARTED,
	RELEASE_EXPENSE_CLAIM_STARTED,
	DELETE_EXPENSE_CLAIM_SUCCEEDED,
} from './../actions/api-actions';
import { ExpenseClaim, ExpenseClaimsData } from '../types/api/expenseClaims';

export interface ExpenseClaimsState {
	expenseClaimsData: ExpenseClaimsData | null;
	isLoading: boolean;
	error: string;
}

export interface ExpenseClaimsAction {
	expenseClaimsData: ExpenseClaimsData;
	type: string;
}

export const expenseClaimsReducer = (
	state: ExpenseClaimsState = {
		expenseClaimsData: null,
		isLoading: false,
		error: '',
	},
	action: any
) => {
	switch ( action.type ) {
		case GET_EXPENSE_CLAIM_SUCCEEDED: {
			const expenseClaim: ExpenseClaim = action.expenseClaim;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims.map( ( element ) => {
					if ( expenseClaim.id === element.id ) {
						return { ...expenseClaim, isLoading: false };
					}

					return element;
				} ) || [];
			if ( !expenseClaims.find( ( element ) => element.id === expenseClaim.id ) ) {
				expenseClaims.unshift( expenseClaim );
			}

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
			};
		}
		case GET_EXPENSE_CLAIMS_STARTED:
		case CREATE_EXPENSE_CLAIM_STARTED: {
			return {
				...state,
				error: '',
				isLoading: true,
			};
		}
		case RELEASE_EXPENSE_CLAIM_STARTED:
		case GET_EXPENSE_CLAIM_STARTED:
		case DELETE_EXPENSE_CLAIM_STARTED: {
			const id = action.id;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims?.map( ( element ) => {
					if ( id === element.id ) {
						return { ...element, isLoading: true };
					}
					return element;
				} ) || [];

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
			};
		}
		case GET_EXPENSE_CLAIMS_SUCCEEDED: {
			const { expenseClaimsData } = action;

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
				error: '',
			};
		}
		case DELETE_EXPENSE_CLAIM_SUCCEEDED: {
			const { id } = action;
			const expenseClaims = state.expenseClaimsData?.expenseClaims.filter(
				( element ) => element.id !== id
			);

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
				error: '',
			};
		}
		case GET_EXPENSE_CLAIMS_FAILED:
		case RELEASE_EXPENSE_CLAIM_FAILED:
		case DELETE_EXPENSE_CLAIM_FAILED: {
			const id = action.id;
			const expenseClaims =
				state.expenseClaimsData?.expenseClaims?.map( ( element ) => {
					if ( id === element.id ) {
						return { ...element, isLoading: false };
					}
					return element;
				} ) || [];

			const expenseClaimsData = { ...state.expenseClaimsData, expenseClaims };

			return {
				...state,
				expenseClaimsData,
				isLoading: false,
			};

		}
		case CREATE_EXPENSE_CLAIM_FAILED: {
			const { error } = action;
			return {
				...state,
				isLoading: false,
				error,
			};
		}
		case DISMISS_EXPENSE_CLAIM_ERROR: {
			return {
				...state,
				error: '',
			};
		}
		default:
			return state;
	}
};
