import React from 'react';

import { NavLink } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';

import { ReactComponent as ExpenseClaimIcon } from './../../images/expense-icon.svg';
import OnTimeHomeIcon from './../../icons/OnTimeHomeIcon';
import LeaveRequestIcon from './../../icons/LeaveRequestIcon';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	footerNav: {
		'@media (max-height: 420px)': {
			padding: theme.spacing( 1, 2 ),
		},
	},
	link: {
		color: theme.palette.custom.gray,
		textDecoration: 'none',
		'@media (max-height: 420px)': {
			'& > div': {
				float: 'left',
				marginLeft: theme.spacing( 1 ),
				lineHeight: '1.5em',
			},
		},
	},
	leaveIcon: {
		'& .b-leave-request': {
			fill: theme.palette.custom.gray,
		},
	},
	expenseIcon: {
		'& .st0': {
			stroke: theme.palette.custom.gray,
		},
		'& .st1': {
			stroke: theme.palette.custom.gray,
		},
		'& .st2': {
			stroke: theme.palette.custom.gray,
		},
		'& .st3': {
			stroke: theme.palette.custom.gray,
		},
		'& .st4': {
			stroke: theme.palette.custom.gray,
		},
	},
	homeIcon: {
		'& .b': {
			fill: theme.palette.custom.gray,
		},
		'& .a': {
			stroke: theme.palette.custom.gray,
		},
	},
	activeLink: {
		color: theme.palette.custom.turquoise,
		'& .b': {
			fill: theme.palette.custom.turquoise,
		},
		'& .a': {
			stroke: theme.palette.custom.turquoise,
		},
		'& .b-leave-request': {
			fill: theme.palette.custom.turquoise,
		},
		'& .st0': {
			stroke: theme.palette.custom.turquoise,
		},
		'& .st1': {
			stroke: theme.palette.custom.turquoise,
		},
		'& .st2': {
			stroke: theme.palette.custom.turquoise,
		},
		'& .st3': {
			stroke: theme.palette.custom.turquoise,
		},
		'& .st4': {
			stroke: theme.palette.custom.turquoise,
		},
	},
	navLinkContainer: {
		borderBottomStyle: 'solid',
		borderLeftStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		// padding: theme.spacing( 2, 0 ),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '8rem'
	}
} ) );

const FooterMenuDesktop: React.FC = ( props: any ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<nav className={ classes.footerNav }>
			<Box display="flex">
				<Box flex="1" textAlign="center" className={ classes.navLinkContainer }>
					<NavLink
						className={ classes.link }
						to="/"
						exact={ true }
						activeClassName={ classes.activeLink }
					>
						<Box>
							<OnTimeHomeIcon
								width={ 32 }
								height={ 32 }
								className={ classes.homeIcon }
							/>
						</Box>
						<Box>
							<Typography variant="caption">{ t( 'home' ) }</Typography>
						</Box>
					</NavLink>
				</Box>
				<Box flex="1" textAlign="center" className={ classes.navLinkContainer }>
					<NavLink
						className={ classes.link }
						to="/leave"
						activeClassName={ classes.activeLink }
					>
						<Box>
							<LeaveRequestIcon
								width={ 32 }
								height={ 32 }
								className={ classes.leaveIcon }
							/>
						</Box>
						<Box>
							<Typography variant="caption">{ t( 'leave' ) }</Typography>
						</Box>
					</NavLink>
				</Box>
				<Box flex="1" textAlign="center" className={ classes.navLinkContainer }>
					<NavLink
						className={ classes.link }
						to="/expense-claims"
						activeClassName={ classes.activeLink }
					>
						<Box>
							<ExpenseClaimIcon
								width={ 32 }
								height={ 32 }
								className={ classes.expenseIcon }
							/>
						</Box>
						<Box>
							<Typography variant="caption">{ t( 'expenses' ) }</Typography>
						</Box>
					</NavLink>
				</Box>
			</Box>
		</nav>
	);
};

export default FooterMenuDesktop;
