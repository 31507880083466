import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
	Dialog,
	Slide,
	TextField,
	DialogContent,
	Box,
	DialogTitle,
	Button,
	Divider,
	Typography,
	SlideProps,
} from '@material-ui/core';
import { AppState } from '../../../reducers';
import { PrintTimesheetRequestArguments } from '../../../types/api/timesheets';
import {
	printTimesheetOptions,
	printExportTypeOptions,
} from '../../../config/constants';
import { printTimesheet } from '../../../actions/api-actions';
import Dropdown from '../../controls/Dropdown';
import useTranslation from '../../../hooks/useTranslation';

export interface PrintTimesheetFormProps {
	isOpen: boolean;
	onClose: () => void;
}

const Transition = React.forwardRef<unknown, SlideProps>(
	function Transition ( props, ref ) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	}
);

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		paperDialog: {
			width: '40rem',
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		textField: {
			margin: theme.spacing( 1, 0 ),
			maxWidth: '47.5%',
			'& .MuiOutlinedInput-input': {
				padding: theme.spacing( 1.5, 1.5 ),
				fontSize: theme.spacing( 1.75 ),
			},
		},
		submitButton: {
			textTransform: 'none',
			fontWeight: 900,
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			margin: theme.spacing( 0, 0, 2, 0 ),
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
		},
		weekSelection: {
			padding: theme.spacing( 0, 2, 2, 2 ),
			display: 'flex',
			justifyContent: 'space-between',
		},
		section: {
			fontWeight: 900,
			color: theme.palette.primary.main,
			padding: theme.spacing( 1, 0 ),
		},
		selectClass: {
			width: `calc( 100% )`,
			fontSize: theme.spacing( 2 ),
			margin: theme.spacing( 0, 0, 2, 0 ),
		},
		droptDownLabel: {
			display: 'none',
		},
	} )
);

const actualYear = new Date().getFullYear();

const FormSchema: yup.AnyObjectSchema = yup.object().shape( {
	year: yup
		.number()
		.required( 'empty year' )
		.moreThan(
			actualYear - 2,
			"year should be greater"
		)
		.lessThan(
			actualYear + 2,
			"year should be less"
		),
	week: yup
		.number()
		.moreThan( 0, 'week should be greater' )
		.lessThan( 53, 'week should be less' )
		.required( 'empty week' ),
} );

const PrintTimesheetForm: React.FC<PrintTimesheetFormProps> = ( {
	isOpen,
	onClose,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ periodLabel, setPeriodLabel ] = useState<string>(
		printTimesheetOptions[ 0 ].label
	);

	const [ detailsType, setDetailsType ] = useState<string>(
		printExportTypeOptions[ 0 ]
	);

	const { register, handleSubmit, errors } = useForm( {
		resolver: yupResolver( FormSchema ),
	} );

	const employeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const { year, week } = useSelector( ( state: AppState ) => {
		const timeSheetInfos = state.timesheetsState.timesheets.filter(
			( timesheet ) => timesheet.employeeCode === employeeCode
		)[ 0 ].timeSheetInfos[ 0 ];

		const weekRange = state.timeState.weekRange;
		if ( timeSheetInfos && weekRange ) {
			const year = timeSheetInfos.year;
			const week = timeSheetInfos.week;

			return { year, week };
		}

		return {};
	} );

	const yearFieldError =
		errors.year && !( errors.year as any ).message.includes( 'NaN' )
			? ( errors.year as any ).message
			: 'empty year';

	const weekFieldError =
		errors.week && !( errors.week as any ).message.includes( 'NaN' )
			? ( errors.week as any ).message
			: 'empty week';

	const onSubmitForm = useCallback(
		( data ) => {
			const period = printTimesheetOptions.find(
				( option ) => option.label === periodLabel
			)?.value;

			const requestData: PrintTimesheetRequestArguments = {
				...data,
				employeeCode,
				period,
				detailsType,
			};

			dispatch( printTimesheet( requestData ) );
			onClose();
		},
		[ employeeCode, onClose, periodLabel, detailsType, dispatch ]
	);

	const onChangePeriod = useCallback( ( value ) => {
		setPeriodLabel( value );
	}, [] );

	const onChangeExportOption = useCallback( ( value ) => {
		setDetailsType( value );
	}, [] );

	const t = useTranslation();

	return (
		<Dialog
			open={ isOpen }
			onClose={ onClose }
			TransitionComponent={ Transition }
			classes={ { paper: classes.paperDialog } }
		>
			<DialogTitle>{ t( 'Print timesheet options' ) }</DialogTitle>
			<DialogContent>
				<form onSubmit={ handleSubmit( onSubmitForm ) }>
					<Box className={ classes.contentContainer }>
						<Typography className={ classes.section }>
							{ t( 'Week selection' ) }
						</Typography>
						<Box display="flex" className={ classes.weekSelection }>
							<TextField
								defaultValue={ !!week && week > 0 ? week : 52 }
								className={ classes.textField }
								label={ t( 'Week' ) }
								variant="outlined"
								name="week"
								error={ !!errors.week }
								inputRef={ register }
								helperText={ !!errors.week && t( weekFieldError ) }
								type="number"
							/>
							<TextField
								defaultValue={ !!week && week > 0 ? year : Number( year ) - 1 }
								className={ classes.textField }
								label={ t( 'Year' ) }
								variant="outlined"
								inputRef={ register }
								error={ !!errors.year }
								name="year"
								helperText={ !!errors.year && t( yearFieldError, {
									firstYear: yearFieldError === "year should be greater" ? actualYear - 2 : undefined,
									lastYear: yearFieldError === "year should be less" ? actualYear + 2 : undefined,
								} ) }
								type="number"
							/>
						</Box>
						<Divider />
						<Typography className={ classes.section }>{ t( 'Period' ) }</Typography>
						<Dropdown
							label="Period"
							options={ printTimesheetOptions.map( ( option ) => option.label ) }
							selectedValue={ periodLabel }
							onValueChange={ onChangePeriod }
							autoExpand={ false }
							selectClass={ classes.selectClass }
							labelClass={ classes.droptDownLabel }
						/>
						<Divider />
						<Typography className={ classes.section }>{ t( 'Export type' ) }</Typography>
						<Dropdown
							label="Export type"
							options={ printExportTypeOptions.map( ( option ) => option ) }
							selectedValue={ detailsType }
							onValueChange={ onChangeExportOption }
							autoExpand={ false }
							selectClass={ classes.selectClass }
							labelClass={ classes.droptDownLabel }
						/>
					</Box>
					<Button className={ classes.submitButton } type="submit" fullWidth>
						{ t( 'Print' ) }
					</Button>
				</form>
			</DialogContent>
		</Dialog>
	);
};

export default PrintTimesheetForm;
