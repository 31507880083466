import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import './index.css';
import Root from './components/Root';
import { ServiceWorkerProvider } from './hooks/useServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './i18n';
import AddToHomeScreenProvider from './components/Providers/AddToHmeScreenProvider';
// import config from './config-loader';

/* 
// Sentry is Disabled by a request from Almando
Sentry.init( {
	dsn: config.sentryDsn,
	integrations: [ new Integrations.BrowserTracing() ],
	environment: config.env,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
} );
 */

ReactDOM.render(
	<ServiceWorkerProvider>
		<AddToHomeScreenProvider>
			<Root />
		</AddToHomeScreenProvider>
	</ServiceWorkerProvider>,
	document.getElementById( 'root' )
);
