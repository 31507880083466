import {
	GET_LEAVE_REQUESTS_STARTED,
	GET_LEAVE_REQUESTS_SUCCEEDED,
	GET_LEAVE_REQUESTS_FAILED,
	GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED,
	GET_LEAVE_REQUEST_ACTIVITIES_FAILED,
	GET_LEAVE_REQUEST_ACTIVITIES_STARTED,
} from './../actions/api-actions';
import { AccountRequests } from '../types/api/leaveRequests';
import { Activity } from '../types/api/timesheets';

export interface LeaverRequestState {
	accountRequests: AccountRequests | null;
	isLoading: boolean;
	isLoadingActivities: boolean;
	activities: Array<Activity>;
}

export interface LeaveRequestAction {
	accountRequests: AccountRequests;
	type: string;
	activities: Array<Activity>;
	error: any;
}

export const leaveRequestReducer = (
	state: LeaverRequestState = {
		accountRequests: null,
		isLoading: false,
		isLoadingActivities: true,
		activities: [],
	},
	action: LeaveRequestAction
) => {
	switch ( action.type ) {
		case GET_LEAVE_REQUESTS_STARTED: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_LEAVE_REQUEST_ACTIVITIES_STARTED: {
			return {
				...state,
				isLoadingActivities: true,
			};
		}
		case GET_LEAVE_REQUESTS_SUCCEEDED: {
			const { accountRequests } = action;
			return {
				...state,
				accountRequests,
				isLoading: false,
			};
		}
		case GET_LEAVE_REQUEST_ACTIVITIES_SUCCEEDED: {
			const { activities } = action;
			return {
				...state,
				activities,
				isLoadingActivities: false,
			};
		}
		case GET_LEAVE_REQUEST_ACTIVITIES_FAILED: {
			return {
				...state,
				isLoadingActivities: false,
			};
		}
		case GET_LEAVE_REQUESTS_FAILED: {
			return {
				...state,
				isLoading: false,
			};
		}
		default:
			return state;
	}
};
