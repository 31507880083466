import React from 'react';
import { Box, makeStyles, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		width: '100%',
		bottom: theme.spacing( 0 ),
		display: 'flex',
		alignItems: 'center',
		borderRadius: theme.spacing( 0.25 ),
		position: 'absolute',
		justifyContent: 'center',
		zIndex: 999,
	},
	button: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		textTransform: 'none',
		fontSize: theme.spacing( 1.8 ),
		fontWeight: 900,
		margin: theme.spacing( 0.5 ),
		padding: theme.spacing( 0.5, 1.8 ),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	text: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		padding: theme.spacing( 3.5 ),
		alignSelf: 'center',
		textAlign: 'center',
	},
} ) );

export interface UpdateAppMessageProps {
	onUpdate: () => void;
}

const UpdateAppMessage: React.FC<UpdateAppMessageProps> = ( { onUpdate } ) => {
	const classes = useStyles();

	return (
		<Box className={ classes.container } p={ 2 }>
			<Typography className={ classes.text }>
				{ 'A new version of the app is available!' }
			</Typography>
			<Button className={ classes.button } onClick={ onUpdate }>
				{ 'Update' }
			</Button>
		</Box>
	);
};

export default UpdateAppMessage;
