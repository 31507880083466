import React from 'react';
import { DateTime } from 'luxon';

import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import SelectDateIcon from '@material-ui/icons/ExpandMore';

import { WeekRangeData } from '../../../actions/time-actions';
import { getDate } from './helpers';
import useTranslation from '../../../hooks/useTranslation';
import useLanguage from '../../../hooks/useLanguage';

const useStyles = makeStyles( theme => {
	const color = theme.palette.grey[ 500 ];
	return {
		root: {
			padding: theme.spacing( 2 ),
		},
		menu: {
			'& :hover': {
				cursor: 'pointer',
			},
		},
		weekRange: {
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 2 ),
				fontWeight: 900,
			},
			color,
		},
		week: {
			color,
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 1.8 ),
			},
		},
		icon: {
			[ theme.breakpoints.down( 'xs' ) ]: {
				fontSize: theme.spacing( 2.5 ),
				fontWeight: 900,
			},
			fontSize: theme.spacing( 3.5 ),
			marginLeft: theme.spacing( 0.25 ),
			color,
			'& :hover': {
				cursor: 'pointer',
			},
		},
	};
} );

interface SelectWeekProps {
	onMenuOpen: any;
	weekRange?: WeekRangeData;
}

const SelectWeek: React.FC<SelectWeekProps> = ( { onMenuOpen, weekRange } ) => {
	const t = useTranslation();
	const localeLanguage = useLanguage();
	const classes = useStyles();
	const week = DateTime.fromJSDate( getDate( weekRange, 'from' ) ).weekNumber;
	const year = DateTime.fromJSDate( getDate( weekRange, week === 1 ? "to" : 'from' ) ).weekYear;
	const monthStart = DateTime.fromJSDate( getDate( weekRange, 'from' ) ).setLocale( localeLanguage ).monthLong;
	const monthEnd = DateTime.fromJSDate( getDate( weekRange, 'to' ) ).setLocale( localeLanguage ).monthLong;
	const startWeekDay = DateTime.fromJSDate( getDate( weekRange, 'from' ) ).day;
	const endWeekDay = DateTime.fromJSDate( getDate( weekRange, 'to' ) ).day;
	const headerString = `${ year } ${ t( 'Week' ) } ${ week }`;
	const weekRangeString = `${ startWeekDay } ${ monthStart } - ${ endWeekDay } ${ monthEnd }`;
	return (
		<Box className={ classes.root }>
			<Grid
				container
				direction="row"
				alignItems="center"
				className={ classes.menu }
				onClick={ onMenuOpen }
			>
				<Grid item>
					<Typography variant="h3" className={ classes.weekRange }>
						{ weekRangeString }
					</Typography>
				</Grid>
				<Grid item>
					<SelectDateIcon className={ classes.icon } />
				</Grid>
			</Grid>
			<Typography
				className={ classes.week }
				onClick={ onMenuOpen }
			>
				{ headerString }
			</Typography>
		</Box>
	);
};

export default SelectWeek;
