import {
	SET_WEEK_RANGE_DATA,
	WeekRangeData,
	SET_SELECTED_EXPENSE_CLAIMS_YEAR,
	SET_SELECTED_LEAVE_REQUESTS_YEAR,
	SET_CALENDAR_MONTH_DATA,
	SET_TIMESHEET_REQUEST_HOURS,
	RESET_TIMESHEET_REQUEST_HOURS,
} from '../actions/time-actions';
import { TotalHoursGroup } from '../components/Desktop/DesktopLayout';

export interface TimeState {
	weekRange?: WeekRangeData;
	expenseClaimsYear: number | null;
	leaveRequestsYear: number | null;
	calendarMonthData?: Date;
	timesheetHours: TotalHoursGroup | null;
}

export interface TimeStateAction {
	type: string;
	weekRange: WeekRangeData;
	expenseClaimsYear: number;
	leaveRequestsYear: number;
	calendarMonthData: Date
	timesheetHours: TotalHoursGroup,
}

export const timeReducer = (
	state: TimeState = {
		weekRange: undefined,
		expenseClaimsYear: null,
		leaveRequestsYear: null,
		timesheetHours: null,
	},
	action: TimeStateAction
) => {
	switch ( action.type ) {

		case SET_WEEK_RANGE_DATA: {
			const { weekRange } = action;

			return {
				...state,
				weekRange,
			};
		}
		case SET_SELECTED_EXPENSE_CLAIMS_YEAR: {
			const { expenseClaimsYear } = action;

			return {
				...state,
				expenseClaimsYear,
			};
		}
		case SET_SELECTED_LEAVE_REQUESTS_YEAR: {
			const { leaveRequestsYear } = action;

			return {
				...state,
				leaveRequestsYear,
			};
		}
		case SET_CALENDAR_MONTH_DATA: {
			const { calendarMonthData } = action;

			return {
				...state,
				calendarMonthData,
			};
		}
		case SET_TIMESHEET_REQUEST_HOURS: {
			return { ...state, timesheetHours: action.timesheetHours }
		}
		case RESET_TIMESHEET_REQUEST_HOURS: {
			return { ...state, timesheetHours: null }
		}
		default:
			return state;
	}
};
