import { SET_AUTHORIZATION_DATA } from '../actions/authorization-actions';

export interface AuthorizationState {
	rawToken: string
	expiresOn?: number
}

export const authorizationReducer = ( state: AuthorizationState = {
	rawToken: '',
	expiresOn: undefined
}, action: any ) => {
	switch ( action.type ) {
		case SET_AUTHORIZATION_DATA: {
			const { rawToken, expiresOn } = action.authorizationData
			return {
				...state,
				rawToken,
				expiresOn,
			}
		}
		default:
			return state;
	}
}
