import {
	PRINT_TIMESHEET_STARTED,
	PRINT_TIMESHEET_SUCCEEDED,
	PRINT_TIMESHEET_FAILED,
} from '../actions/api-actions';

export interface PrintTimesheetState {
	isLoading: boolean;
	message?: string;
}

export interface PrintAction {
	type: string;
	message?: string;
}

export const printTimesheetReducer = (
	state: PrintTimesheetState = {
		isLoading: false,
		message: undefined,
	},
	action: PrintAction
) => {
	switch ( action.type ) {
		case PRINT_TIMESHEET_STARTED: {
			return {
				...state,
				message: undefined,
				isLoading: true,
			};
		}
		case PRINT_TIMESHEET_SUCCEEDED: {
			const { message } = action;
			return {
				...state,
				message,
				isLoading: false,
			};
		}
		case PRINT_TIMESHEET_FAILED: {
			return {
				...state,
				message: undefined,
				isLoading: false,
			};
		}
		default:
			return state;
	}
};
