import {
	GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED,
	GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED,
	GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED,
} from '../actions/api-actions';
import { ExpenseClaimsSourceData } from '../types/api/expenseClaims';

export interface ExpenseClaimsSourcesState {
	expenseClaimsSourceData: ExpenseClaimsSourceData | null
	isLoading: boolean;
}

export interface ExpenseClaimsSourcesAction {
	expenseClaimsSourceData: ExpenseClaimsSourceData;
	type: string;
}

export const expenseClaimsSourcesReducer = (
	state: ExpenseClaimsSourcesState = {
		expenseClaimsSourceData: null,
		isLoading: false,
	},
	action: ExpenseClaimsSourcesAction
) => {
	switch ( action.type ) {
		case GET_EXPENSE_CLAIMS_SOURCES_DATA_STARTED: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_EXPENSE_CLAIMS_SOURCES_DATA_SUCCEEDED: {
			const { expenseClaimsSourceData } = action
			return {
				...state,
				expenseClaimsSourceData,
				isLoading: false,
			};
		}
		case GET_EXPENSE_CLAIMS_SOURCES_DATA_FAILED: {
			return {
				...state,
				isLoading: false,
			};
		}
		default:
			return state;
	}
};
