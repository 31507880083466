import React, { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.js`;

export interface PDFViewerProps {
	base64String: string;
}

const useStyles = makeStyles( ( theme ) => ( {
	pageNav: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	icon: {
		fontSize: theme.spacing( 2 ),
		'&:hover': {
			cursor: 'pointer',
		},
	},
	pages: {
		padding: theme.spacing( 0, 1 ),
		fontWeight: 900,
	},
} ) );

const PDFViewer: React.FC<PDFViewerProps> = ( { base64String = '' } ) => {
	const classes = useStyles();
	const [ pageNumber, setPageNumber ] = useState<number>( 1 );
	const [ numPages, setNumPages ] = useState<number>( 1 );

	const file = `data:image;base64,${ base64String }`;

	const onDocumentLoadSuccess = useCallback( ( { numberOfPages } ) => {
		setNumPages( numberOfPages );
	}, [] );

	const onNextPageClick = useCallback( () => {
		if ( pageNumber === numPages ) {
			setPageNumber( 1 );
		} else {
			setPageNumber( pageNumber + 1 );
		}
	}, [ pageNumber, numPages ] );

	const onPrevPageClick = useCallback( () => {
		if ( pageNumber === 1 ) {
			setPageNumber( numPages );
		} else {
			setPageNumber( pageNumber - 1 );
		}
	}, [ pageNumber, numPages ] );

	return (
		<Box>
			<Document file={ file } onLoadSuccess={ onDocumentLoadSuccess }>
				<Page pageNumber={ pageNumber } />
			</Document>
			<Box className={ classes.pageNav }>
				<ArrowBack className={ classes.icon } onClick={ onPrevPageClick } />
				<Typography
					className={ classes.pages }
				>{ `${ pageNumber } of ${ numPages } pages` }</Typography>
				<ArrowForward className={ classes.icon } onClick={ onNextPageClick } />
			</Box>
		</Box>
	);
};

export default PDFViewer;
