import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		color: theme.palette.text.primary,
		'&:hover': {
			cursor: 'pointer',
		},
		borderColor: theme.palette.text.disabled,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing( 1.5, 0 ),
	},
	selectedItem: {
		backgroundColor: theme.palette.custom.paper,
		borderLeftStyle: 'solid',
		borderLeftColor: theme.palette.primary.main,
	},
	container: {
		padding: theme.spacing( 1 ),
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	status: {
		backgroundColor: theme.palette.custom.statusApproved,
		padding: theme.spacing( 0.5, 1.5 ),
		color: theme.palette.primary.contrastText,
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 700,
		textAlign: 'center',
	},
	statusBox: {
		width: '20%',
	},
	notSettled: {
		backgroundColor: theme.palette.custom.statusEntered,
	},
	disabled: {
		opacity: '0.5',
	},
	action: {
		'& :hover': {
			cursor: 'pointer',
		},
	},
	dataContainer: {
		display: 'flex',
		justifyContent: 'flex-between',
		width: '80%',
	},
	data: {
		flex: 1,
	},
	flexSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	customDelete: {
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
		textTransform: 'none',
	},
	time: {
		width: '20%',
	},
} ) );

export interface LeaveRequestItemTemplateCardDesktopProps {
	onSelect: ( id: string ) => void;
	id: string;
	status: { id: string; desc: string };
	title: string;
	time: string;
	disabled?: boolean;
	isSelected?: boolean;
}

const LeaveRequestItemTemplateCardDesktop: React.FC<LeaveRequestItemTemplateCardDesktopProps> = ( {
	title,
	id,
	onSelect,
	status,
	time,
	disabled = false,
	isSelected = false,
} ) => {
	const classes = useStyles();
	const history = useHistory();

	const statusClassName =
		status?.id === 'G'
			? classes.status
			: clsx( classes.status, classes.notSettled );

	const containerClass = !disabled
		? clsx( classes.container, classes.action )
		: classes.container;

	const onClickHandler = useCallback( () => {
		if ( !disabled ) {
			history.location.pathname.includes( 'create-leave-request' ) &&
				history.push( '/leave', { previous: history.location.pathname } );
			onSelect( id );
		}
	}, [ onSelect, disabled, id, history ] );

	const disabledClass = disabled
		? clsx( classes.paper, classes.disabled )
		: classes.paper;

	const paperClass = isSelected
		? clsx( disabledClass, classes.selectedItem )
		: disabledClass;

	return (
		<Box className={ paperClass } onClick={ onClickHandler }>
			<Box className={ containerClass }>
				<Typography className={ classes.time }>{ time }</Typography>
				<Box className={ classes.dataContainer }>
					<Typography className={ classes.data }>{ title }</Typography>
					<Typography className={ statusClassName }>{ status?.desc }</Typography>
				</Box>
			</Box>
		</Box>
	);
};
export default LeaveRequestItemTemplateCardDesktop;
