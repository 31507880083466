import React, { useEffect } from 'react';
import {
	DialogTitle,
	DialogContentText,
	DialogContent,
	Dialog,
} from '@material-ui/core';
import { AppState } from '../../../reducers';
import { useSelector } from 'react-redux';
import useTranslation from '../../../hooks/useTranslation';
interface DeleteConfirmProps {
	open: boolean;
	onConfirmReleaseSuccess: () => void;
}

const ReleaseConfirmDialog: React.FC<DeleteConfirmProps> = ( {
	open,
	onConfirmReleaseSuccess,
} ) => {
	const { isLoading } = useSelector(
		( state: AppState ) => state.releasedTimesheetsState
	);

	useEffect( () => {
		if ( open && !isLoading ) {
			onConfirmReleaseSuccess();
		}
	}, [ onConfirmReleaseSuccess, isLoading, open ] );

	const t = useTranslation();

	return (
		<Dialog open={ open && isLoading }>
			<DialogTitle>{ t( 'Release timesheet' ) }</DialogTitle>
			<DialogContent>
				<DialogContentText id="release-timesheet">
					{ t( 'Loading' ) }
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default ReleaseConfirmDialog;
