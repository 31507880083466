import React, { useCallback } from 'react';

import { Box, Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface CodesCategoriesProps {
	selectedCategory?: string;
	onSelectCategory: ( category: string ) => void;
}

const categories = [ 'Allowance', 'Expense', 'Mileage', 'Other' ];

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		padding: theme.spacing( 2 ),
		display: 'flex',
	},
	button: {
		flex: 1,
		backgroundColor: theme.palette.background.default,
		color: theme.palette.primary.main,
		borderStyle: 'solid',
		borderColor: theme.palette.primary.dark,
		borderWidth: theme.spacing( 0.125 ),
		overflow: 'hidden',
		textTransform: 'none',
		fontWeight: 900,
		padding: theme.spacing( 0.5, 0 ),
		'&:hover': {
			backgroundColor: theme.palette.custom.paper,
		},
	},
	selected: {
		backgroundColor: theme.palette.custom.paper,
		borderWidth: theme.spacing( 0.25 ),
		'&:hover': {
			backgroundColor: theme.palette.custom.paper,
		},
	},
} ) );
const CodesCategories: React.FC<CodesCategoriesProps> = ( {
	selectedCategory,
	onSelectCategory,
} ) => {
	const classes = useStyles();

	const onClickCategoryHandler = useCallback( ( e ) => {
		onSelectCategory( e.target.innerHTML );
	}, [ onSelectCategory ] );

	const selectedClass = clsx( classes.button, classes.selected );
	return (
		<Box className={ classes.container }>
			{ categories.map( ( category ) => (
				<Button
					key={ category }
					onClick={ onClickCategoryHandler }
					className={
						selectedCategory !== category ? classes.button : selectedClass
					}
				>
					{ category }
				</Button>
			) ) }
		</Box>
	);
};

export default CodesCategories;
