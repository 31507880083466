import React from 'react';

import { Box, IconButton, makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';

import AccountMenu from '../Desktop/AccountMenu';
import useAccountTimesheetManagement from '../../hooks/useAccountTimesheetManagement';

const useStyles = makeStyles( theme => ( {
	iconButton: {
		margin: theme.spacing( 0, 2, 0, 0 ),
	},
	mediaHeader: {
		height: '10rem',
		maxHeight: '15vh',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		color: theme.palette.primary.contrastText,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		borderBottom: 'solid',
		borderBottomColor: theme.palette.custom.paper
	},
	settingsIcon: {
		height: theme.spacing( 5 ),
		width: theme.spacing( 5 ),
	},
	accountMenu: {
		padding: theme.spacing( 0, 0, 0, 3 ),
	}
} ) )

export interface DesktopHeaderProps {
	onLogOutClick: () => void;
	showSettings: () => void;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ( { onLogOutClick, showSettings } ) => {
	const classes = useStyles();

	const {
		currentAccountData,
		userAccounts,
		onSelectUserAccount,
	} = useAccountTimesheetManagement();

	return (
		<Box className={ classes.mediaHeader }>
			<Box className={ classes.accountMenu }>
				<AccountMenu
					userAccounts={ userAccounts }
					onSelectAccount={ onSelectUserAccount }
					selectedCompany={ currentAccountData?.companyName }
					onLogout={ onLogOutClick }
				/>
			</Box>
			<IconButton
				aria-label="settings"
				onClick={ showSettings }
				className={ classes.iconButton }
			>
				<SettingsIcon className={ classes.settingsIcon } />
			</IconButton>
		</Box>
	);
};

export default DesktopHeader;
