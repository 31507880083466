import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

import { convertDecimalToTimeString } from '../../../utils/date';

import { LeaveRequestBalance } from '../../../types/api/leaveRequests';
import useTranslation from '../../../hooks/useTranslation';

export interface LeaveBalanceProps {
	balances: Array<LeaveRequestBalance>;
}

const useStyles = makeStyles( ( theme ) => ( {
	budgetContainer: {
		'&:last-child': {
			marginBottom: theme.spacing( 0 ),
			[ theme.breakpoints.down( 'md' ) ]: {
				marginBottom: theme.spacing( 5 ),
			},
		},
	},
	budgetInfoItem: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing( 0, 4, 2, 4 ),
	},
	balanceLabel: {
		fontSize: theme.spacing( 1.8 ),
		fontWeight: 900,
	},
	balanceValue: {
		fontFamily: 'Roboto',
		fontSize: theme.spacing( 1.8 ),
		fontWeight: 900,
	},
} ) );

const LeaveBalance: React.FC<LeaveBalanceProps> = ( { balances } ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<>
			<Box className={ classes.budgetContainer }>
				<Box className={ classes.budgetInfoItem }>
					<Typography className={ classes.balanceLabel }>
						{ t( 'Total spent-Vacation' ) }
					</Typography>
					<Typography className={ classes.balanceValue }>
						{ convertDecimalToTimeString( balances[ 0 ].spent ) }
					</Typography>
				</Box>
				<Box className={ classes.budgetInfoItem }>
					<Typography className={ classes.balanceLabel }>
						{ t( 'Remaining-Vacation' ) }
					</Typography>
					<Typography className={ classes.balanceValue }>
						{ convertDecimalToTimeString( balances[ 0 ].balance ) }
					</Typography>
				</Box>
			</Box>
			{ balances.length > 1 &&
				<Box className={ classes.budgetContainer }>
					<Box className={ classes.budgetInfoItem }>
						<Typography className={ classes.balanceLabel }>
							{ t( 'Total spent-Overtime' ) }
						</Typography>
						<Typography className={ classes.balanceValue }>
							{/* TO DO: Need to know which is the attribute for overtime spent hours */ }
							{ convertDecimalToTimeString( balances[ 1 ].spent ) }
						</Typography>
					</Box>
					<Box className={ classes.budgetInfoItem }>
						<Typography className={ classes.balanceLabel }>
							{ t( 'Remaining-Overtime' ) }
						</Typography>
						<Typography className={ classes.balanceValue }>
							{ convertDecimalToTimeString( balances[ 1 ].balance ) }
						</Typography>
					</Box>
				</Box> }
		</>
	);
};

export default LeaveBalance;
