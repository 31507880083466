import React from 'react';

import { Box, Typography, makeStyles, Divider } from '@material-ui/core';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	messageBox: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		padding: theme.spacing( 0, 5, 0, 5 ),
	},
	message: {
		fontSize: theme.spacing( 1.7 ),
		marginBottom: theme.spacing( 0.5 ),
	},
	messageLink: {
		fontSize: theme.spacing( 1.7 ),
		textDecoration: 'none',
		color: theme.palette.primary.main,
	},
	divider: {
		height: theme.spacing( 0.15 ),
		margin: theme.spacing( 1, 0, 2 ),
	},
} ) );

interface ContactLineProps {
	contactText?: string;
}

const ContactLine: React.FC<ContactLineProps> = ( { contactText } ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<Box className={ classes.messageBox }>
			<Typography className={ classes.message }>{ contactText }</Typography>
			<Typography>
				<a className={ classes.messageLink } href="mailto:helpdesk@ict.nl">
					{ t( 'Contact the helpdesk.' ) }
				</a>
			</Typography>
			<Divider className={ classes.divider } />
		</Box>
	);
};

export default ContactLine;
