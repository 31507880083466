import React from 'react';

import {
	Box,
	Typography,
	Paper,
	makeStyles,
	Tooltip,
	SvgIconProps,
} from '@material-ui/core';
import clsx from 'clsx';
import useTranslation from '../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		opacity: '1.25',
		padding: theme.spacing( 1.5 ),
		cursor: 'pointer',
	},
	icon: {
		color: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.dark,
		},
	},
	disabledCard: {
		opacity: '0.6',
		cursor: 'default',
	},
	disabled: {
		'&$disabled': {
			color: theme.palette.primary.contrastText,
		},
		opacity: '0.6',
	},
} ) );

export interface SettingsTemplateCardProps {
	title?: string;
	action?: () => void;
	className?: string;
	actionTitle?: string;
	disabled?: boolean;
	isCardClickable?: boolean;
	Icon?: ( props: SvgIconProps ) => JSX.Element;
}

const SettingsTemplateCard: React.FC<SettingsTemplateCardProps> = ( {
	title,
	action,
	disabled = false,
	actionTitle,
	className,
	children,
	isCardClickable = false,
	Icon,
} ) => {
	const classes = useStyles();

	const paperClassName = className
		? clsx( classes.paper, className )
		: classes.paper;

	const paper = disabled
		? clsx( paperClassName, classes.disabledCard )
		: paperClassName;

	const t = useTranslation();

	return (
		<Box m={ 2 }>
			<Paper
				className={ paper }
				onClick={ isCardClickable && !disabled ? action : undefined }
			>
				<Box display="flex">
					<Box flex="1" display="flex" alignItems="center" paddingRight={ 2 }>
						<Typography>{ title ? t( title ) : undefined }</Typography>
					</Box>
					<Box paddingRight={ 1 }>
						{ children
							? children
							: Icon && (
								<Tooltip title={ t( actionTitle || '' ) || false } placement={ "top" } disableHoverListener={ disabled }>
									<Icon
										className={ classes.icon }
										onClick={ !disabled ? action : undefined }
									/>
								</Tooltip>
							) }
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};
export default SettingsTemplateCard;
