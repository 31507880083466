import { SET_TEMP_DESTINATION } from '../actions/ui-actions';

export interface TempDestinationState {
	destination: any
}

export const tempDestinationReducer = ( state: TempDestinationState = {
	destination: null
}, action: any ) => {
	switch ( action.type ) {
		case SET_TEMP_DESTINATION: {
			return {
				...state,
				destination: action.destination
			}
		}
		default:
			return state;
	}
}
