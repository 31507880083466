import React, { useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';

export interface BackButtonProps {
	className?: string;
}

const BackButton: React.FC<BackButtonProps> = ( { className } ) => {
	const history = useHistory();

	const onClickBackHandler = useCallback( () => {
		const previousPath = ( history.location.state as any )?.previous;
		if ( previousPath && previousPath !== '/' ) {
			history.goBack();
		} else {
			history.push( '/' );
		}
	}, [ history ] );

	return (
		<IconButton aria-label="Back" onClick={ onClickBackHandler }>
			<ArrowBack className={ className } />
		</IconButton>
	);
};

export default BackButton;
