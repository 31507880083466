import React from 'react';
import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( theme => ( {
	errorMessage: {
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
	}
} ) );

export interface ReleaseTimesheetErrorMessageProps {
	error: any;
	onDismissError: () => void;
}

const ReleaseTimesheetErrorMessage: React.FC<ReleaseTimesheetErrorMessageProps> = ( { error, onDismissError } ) => {
	const classes = useStyles();

	let errorMessage: string = error?.message?.description;
	let errorCode: string = error?.message?.code;

	const t = useTranslation();

	if ( !errorMessage ) {
		errorMessage = t( 'release error' );
	}
	if ( !errorCode ) {
		errorCode = "000";
	}

	return (
		<Box className={ classes.errorMessage } p={ 2 }>
			<Typography>{ `Oh-oh.. ${ t( "release timesheet error label" ) }` }</Typography>
			<Box padding="1em 0">
				<Typography>
					{ errorCode }: { errorMessage }
				</Typography>
			</Box>
			<Button variant="contained" color="secondary" onClick={ onDismissError } >{ "Okay..." }</Button>
		</Box>
	)
}

export default ReleaseTimesheetErrorMessage;
