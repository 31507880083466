import React from 'react';

import { Box } from '@material-ui/core';

import WeekTimesheets from './WeekTimesheets';
import CalendarToolbar from './CalendarToolbar';
import Weekday from './WeekDayElement';

import CalendarControl from '../../controls/Calendar/Calendar';
import useWeekTimesheets from '../../../hooks/useWeekTimesheets';
import NavigationFab from '../../controls/NavigationFab';
import useWeekRange from '../../../hooks/useWeekRange';
import usePublicHolidays from '../../../hooks/usePublicHolidays';
import useDestinationNavSettings from '../../../hooks/useDestinationNavSettings';
import WeekHolidays from './WeekHolidays';
import OverlayLoader from '../../OverlayLoader';
import useErrorData from '../../../hooks/useErrorData';
import ReloadPage from '../../ReloadPage';

const CalendarScreen: React.FC = () => {
	const { selectedWeekRange, setSelectedWeekRange } = useWeekRange();

	const { weekActivities, isLoading } = useWeekTimesheets();

	const navigationSettings = useDestinationNavSettings();

	const error = useErrorData();

	const { publicHolidays, currentWeekHolidays } = usePublicHolidays();

	return (
		<Box
			display="flex"
			flexDirection="column"
			height="calc( 100vh - 10rem )"
			flex="1"
			overflow="auto"
		>
			{!error && (
				<OverlayLoader
					loadingCondition={ !selectedWeekRange || !publicHolidays.length }
					message="Loading calendar data"
				/>
			) }
			{error?.type !== 'USER' && selectedWeekRange && publicHolidays.length && (
				<>
					<Box>
						<CalendarControl
							selectedWeekRange={ selectedWeekRange }
							onWeekRangeSelected={ setSelectedWeekRange }
							Toolbar={ CalendarToolbar }
							WeekDayElement={ Weekday }
							publicHolidays={ publicHolidays }
						/>
					</Box>
					{!error && (
						<Box minHeight="5em" flex="1" overflow="auto" position="relative">
							<OverlayLoader
								loadingCondition={ isLoading }
								message="Loading activities"
							/>
							{!isLoading && (
								<>
									<WeekHolidays weekHolidays={ currentWeekHolidays } />
									<WeekTimesheets weekActivities={ weekActivities } />
								</>
							) }
						</Box>
					) }
				</>
			) }
			{!error ? (
				<NavigationFab { ...navigationSettings } />
			) : (
				<ReloadPage error={ error } />
			) }
		</Box>
	);
};

export default CalendarScreen;
