import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, makeStyles } from '@material-ui/core';

import ExpenseClaimsListDesktop from './ExpenseClaimsListDesktop';
import ExpenseClaimOptionsDesktop from '../ExpenseClaimsOptions/ExpenseClaimOptionsDesktop';
import ManageExpenseClaim from '../ManageExpenseClaims/ManageExpenseClaimDesktop';
import { AppState } from '../../../reducers';
import {
	dismissExpenseClaimError,
	getExpenseClaimDetails,
} from '../../../actions/api-actions';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import ScreenMessage from '../../ScreenMessage';

export interface ExpenseClaimsDesktopProps { }

const useStyles = makeStyles( ( theme ) => ( {
	errorMessage: {
		position: 'absolute',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		top: '4rem',
		height: '4rem',
		display: 'flex',
		width: '55%',
		justifyContent: 'center',
		alignItems: 'center',
	},
} ) );

const ExpenseClaimsDesktop: React.FC<ExpenseClaimsDesktopProps> = () => {
	const classes = useStyles();

	const [ id, setId ] = useState<string>();

	const [ isManageActive, setIsManageActive ] = useState<boolean>( false );

	const dispatch = useDispatch();

	const employeeCode = useSelector(
		( state: AppState ) => state.userState.selectedUser?.employeeCode
	);

	const { expenseClaims, selectedExpenseClaim } = useExpenseClaimsData( id );

	const errorMessage = useSelector(
		( state: AppState ) => state.expenseClaimsState.error
	);

	useEffect( () => {
		dispatch( dismissExpenseClaimError() );
	}, [ dispatch ] );

	const defaultId =
		expenseClaims && expenseClaims.length ? expenseClaims[ 0 ].id : null;

	const expenseClaimsLength = expenseClaims?.length || 0;

	useEffect( () => {
		if ( defaultId && employeeCode && expenseClaimsLength ) {
			setId( defaultId );
			dispatch(
				getExpenseClaimDetails( {
					employeeCode,
					id: defaultId,
				} )
			);
		}
	}, [ dispatch, employeeCode, setId, defaultId, expenseClaimsLength ] );

	return (
		<>
			{ isManageActive ? (
				<ManageExpenseClaim
					action={ setIsManageActive }
					expenseClaimToEdit={ selectedExpenseClaim }
				/>
			) : (
				<>
					<Box display="flex" justifyContent="center">
						<Box width="45%">
							<ExpenseClaimsListDesktop
								setExpenseClaimId={ setId }
								selectedId={ selectedExpenseClaim?.id }
							/>
						</Box>
						<Box width="55%">
							{ !!errorMessage ? (
								<ScreenMessage
									message={
										'Oops...something went wrong, refresh the page and try again.'
									}
									className={ classes.errorMessage }
								/>
							) : (
								<ExpenseClaimOptionsDesktop
									expenseClaim={ selectedExpenseClaim }
									onManage={ setIsManageActive }
								/>
							) }
						</Box>
					</Box>
				</>
			) }
		</>
	);
};

export default ExpenseClaimsDesktop;
