import React, { useCallback, useState, useEffect } from 'react';
import { Box, makeStyles, Input, Typography } from '@material-ui/core';
import {
	ExpandLess as IncreaseHoursIcon,
	ExpandMore as DecreaseHoursIcon,
} from '@material-ui/icons';
import { HoursOutput } from './TimeSpentSelector';
import useTimeField from '../../../hooks/useTimeField';
import MaskedAddHoursInput from './MaskedAddHoursInput';
import useTranslation from '../../../hooks/useTranslation';

export interface DigitalClockPickerProps {
	setTime: ( time: HoursOutput ) => void;
	time: HoursOutput;
}

const useStyles = makeStyles( ( theme ) => ( {
	numberContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignContent: 'center',
		margin: theme.spacing( 1, 1.5 ),
		cursor: 'pointer',
	},
	time: {
		width: '50px',
		height: '50px',
		'& .MuiInputBase-input': {
			fontSize: theme.spacing( 4 ),
			fontWeight: 900,
			color: theme.palette.text.disabled,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			fontFamily: 'Monospace, sans-serif',
		},
	},
	splitter: {
		fontSize: theme.spacing( 4 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
	},
	timeContainer: {
		borderStyle: 'solid',
		borderRadius: theme.spacing( 1 ),
		padding: theme.spacing( 2 ),
		borderColor: theme.palette.text.disabled,
	},
	icon: {
		fontSize: theme.spacing( 3 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	timeLabel: {
		color: theme.palette.text.disabled,
		fontWeight: 900,
		fontSize: theme.spacing( 1.5 ),
		paddingTop: theme.spacing( 0.5 ),
	},
} ) );

const DigitalClockPicker: React.FC<DigitalClockPickerProps> = ( {
	setTime,
	time,
} ) => {
	const classes = useStyles();
	const [ timePickerMinutes, setTimePickerMinutes ] = useState<number>(
		time.minutes
	);
	const [ timePickerHours, setTimePickerHours ] = useState<number>(
		Math.abs( time.hours )
	);

	const {
		onKeyBoardEventHandler,
		onFocusField,
		onSwitchHoursMinutesByKey,
		fieldMinutesTime,
		fieldHoursTime,
		setFieldHoursTime,
		setFieldMinutesTime,
		inputForMinutesRef,
		inputForHoursRef,
	} = useTimeField();

	useEffect( () => {
		const hours = Number( fieldHoursTime );
		if ( fieldMinutesTime ) {
			setTimePickerMinutes( Number( fieldMinutesTime ) );
		}
		setTimePickerHours( hours );
	}, [ fieldMinutesTime, fieldHoursTime, timePickerMinutes ] );
	useEffect( () => {
		if ( Number( fieldHoursTime ) === 16 ) {
			setTimePickerMinutes( 0 );
			setFieldMinutesTime( '00' );
		}
	}, [ fieldHoursTime, timePickerMinutes, setFieldMinutesTime ] );

	const onIncreaseHours = useCallback( () => {
		const hours = timePickerHours === 16 ? 0 : timePickerHours + 1;
		setFieldHoursTime( hours < 10 ? `0${ hours }` : `${ hours }` );
		setTime( { hours, minutes: hours === 16 ? 0 : time.minutes } );
	}, [ setTime, timePickerHours, time, setFieldHoursTime ] );

	const onDecreaseHours = useCallback( () => {
		const hours = timePickerHours === 0 ? 16 : timePickerHours - 1;
		setFieldHoursTime( hours < 10 ? `0${ hours }` : `${ hours }` );
		setTime( { ...time, hours } );
	}, [ setTime, time, timePickerHours, setFieldHoursTime ] );

	const onIncreaseMinutes = useCallback( () => {
		if ( time.hours === 16 ) {
			return;
		}
		const minutes = timePickerMinutes === 59 ? 0 : timePickerMinutes + 1;
		setFieldMinutesTime( minutes < 10 ? `0${ minutes }` : `${ minutes }` );
		setTime( { ...time, minutes } );
	}, [ setTime, time, timePickerMinutes, setFieldMinutesTime ] );

	const onDecreaseMinutes = useCallback( () => {
		if ( time.hours === 16 ) {
			return;
		}
		const minutes = timePickerMinutes === 0 ? 59 : timePickerMinutes - 1;
		setFieldMinutesTime( minutes < 10 ? `0${ minutes }` : `${ minutes }` );
		setTime( { ...time, minutes } );
	}, [ setTime, time, timePickerMinutes, setFieldMinutesTime ] );

	const onBlurEvent = useCallback( () => {
		setTime( { hours: timePickerHours, minutes: timePickerMinutes } );
	}, [ setTime, timePickerMinutes, timePickerHours ] );

	const t = useTranslation();

	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<Box className={ classes.numberContainer }>
				<IncreaseHoursIcon onClick={ onIncreaseHours } className={ classes.icon } />
				<Box className={ classes.timeContainer }>
					<Input
						className={ classes.time }
						value={
							fieldHoursTime
								? fieldHoursTime
								: timePickerHours < 10
									? `0${ timePickerHours }`
									: timePickerHours
						}
						onFocus={ onFocusField }
						inputProps={ {
							timeType: 'hours',
							inputRef: inputForHoursRef,
						} }
						inputComponent={ MaskedAddHoursInput as any }
						name="hours"
						onKeyDownCapture={ onSwitchHoursMinutesByKey }
						onKeyUp={ onKeyBoardEventHandler }
						onBlur={ onBlurEvent }
						inputMode="numeric"
					/>
				</Box>
				<Typography className={ classes.timeLabel }>
					{ t( 'Hours' ).toLowerCase() }
				</Typography>
				<DecreaseHoursIcon onClick={ onDecreaseHours } className={ classes.icon } />
			</Box>
			<Box className={ classes.splitter }>{ ':' }</Box>
			<Box className={ classes.numberContainer }>
				<IncreaseHoursIcon
					onClick={ onIncreaseMinutes }
					className={ classes.icon }
				/>
				<Box className={ classes.timeContainer }>
					<Input
						className={ classes.time }
						value={
							timePickerHours === 16
								? '00'
								: fieldMinutesTime
									? fieldMinutesTime
									: timePickerMinutes < 10
										? `0${ timePickerMinutes }`
										: timePickerMinutes
						}
						onFocus={ onFocusField }
						inputProps={ {
							timeType: 'minutes',
							inputRef: inputForMinutesRef,
						} }
						name="minutes"
						disabled={ timePickerHours === 16 }
						inputComponent={ MaskedAddHoursInput as any }
						onKeyDownCapture={ onSwitchHoursMinutesByKey }
						onKeyUp={ onKeyBoardEventHandler }
						onBlur={ onBlurEvent }
						inputMode="numeric"
					/>
				</Box>
				<Typography className={ classes.timeLabel }>
					{ t( 'Minutes' ).toLowerCase() }
				</Typography>
				<DecreaseHoursIcon
					onClick={ onDecreaseMinutes }
					className={ classes.icon }
				/>
			</Box>
		</Box>
	);
};

export default DigitalClockPicker;
