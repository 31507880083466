import React, { FunctionComponent, SVGProps } from 'react';
import { Box, Paper, makeStyles, Typography, Tooltip } from '@material-ui/core';
import useTranslation from '../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	quickActionContainer: {
		margin: theme.spacing( 2 ),
	},
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.background.paper,
		opacity: '1.25',
		padding: theme.spacing( 1.5 ),
		cursor: 'pointer',
	},
	disabled: {
		opacity: 0.7,
		cursor: 'default',
	},
	icon: {
		width: 24,
		height: 24,
		'& .b-leave-request': {
			fill: theme.palette.primary.main,
		},
		'& .st0': {
			stroke: theme.palette.primary.main,
		},
		'& .st1': {
			stroke: theme.palette.primary.main,
		},
		'& .st2': {
			stroke: theme.palette.primary.main,
		},
		'& .st3': {
			stroke: theme.palette.primary.main,
		},
		'& .st4': {
			stroke: theme.palette.primary.main,
		},
	},
} ) );

export interface QuickActionProps {
	onClick: () => void;
	tooltipTitle: string;
	title: string;
	Icon: FunctionComponent<SVGProps<SVGSVGElement>> | React.FC<any>;
	className?: string;
}

const QuickAction: React.FC<QuickActionProps> = ( {
	onClick,
	tooltipTitle,
	title,
	Icon,
	className,
} ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<Box className={ className || classes.quickActionContainer }>
			<Paper className={ classes.paper } onClick={ onClick }>
				<Box display="flex" alignItems="center">
					<Box flex="1" display="flex" alignItems="center">
						<Typography>{ t( title ) }</Typography>
					</Box>
					<Tooltip title={ tooltipTitle } placement="top">
						<Box paddingRight={ 1 }>
							<Icon className={ classes.icon } />
						</Box>
					</Tooltip>
				</Box>
			</Paper>
		</Box>
	);
};

export default QuickAction;
