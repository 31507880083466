import React, { useCallback, useEffect } from 'react';

// Routing
import AppRouter from '../AppRouter';

// Styling
import { Box, makeStyles } from '@material-ui/core';
import './App.css';

import LogIn from '../screens/LogIn';
import Loading from '../Loading';
import ExpiredTokenMessage from './ExpiredTokenMessage';
import useApiAuth from '../../hooks/useApiAuth';
import useAppStateController from '../../hooks/useAppStateController';
// import { autoLoginTime } from '../../config/constants';
import UpdateAppMessage from '../controls/UpdateAppMessage';
import { useServiceWorker } from '../../hooks/useServiceWorker';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import useTranslation from '../../hooks/useTranslation';
import { autoLoginTime } from '../../config/constants';

const useStyles = makeStyles( ( theme ) => ( {
	root: {
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
		boxShadow: `0 0 3px 0 ${ theme.palette.text.primary }`,
		position: 'relative',
		margin: '0 auto',
		maxWidth: '150rem',
		overflowX: 'hidden',
		[ theme.breakpoints.down( 'md' ) ]: {
			maxWidth: '60rem',
		},
	},
	outerRoot: {
		background: theme.palette.background.default,
	},
} ) );

const App: React.FC = () => {
	const {
		isLoading,
		rawToken,
		hasTokenExpired,
		account,
		onSignIn,
		onSignOut,
	} = useApiAuth();

	useAppStateController();

	const { isUpdateAvailable, updateAssets } = useServiceWorker();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const message = useSelector(
		( state: AppState ) => state.printTimesheetState.message
	);

	useEffect( () => {
		if ( message ) {
			enqueueSnackbar( message, {
				onClick: () => closeSnackbar( message ),
				key: message,
			} );
		}
	}, [ message, enqueueSnackbar, closeSnackbar ] );

	const tokenErrorMessageClickHandler = useCallback( () => {
		onSignIn();
	}, [ onSignIn ] );

	const classes = useStyles();

	const t = useTranslation();

	useEffect( () => {
		if ( isUpdateAvailable || account ) {
			return
		}

		let t = setTimeout( () => { onSignIn() }, autoLoginTime );
		return () => clearTimeout( t )
	}, [ account, onSignIn, isUpdateAvailable ] )

	return (
		<Box className={ classes.outerRoot }>
			<Box margin="0 auto" className={ classes.root }>
				<div className="аpp">
					{ account ? (
						rawToken ? (
							<>
								{ hasTokenExpired ? (
									/*
														  When the token expires we overlay the message over the current screen.
														  That's why we always render the router.
													   */
									<ExpiredTokenMessage
										message={ t( 'Expired session' ) }
										buttonText={ t( 'Login' ) }
										onClick={ tokenErrorMessageClickHandler }
									/>
								) : null }
								<AppRouter onSignOut={ onSignOut } />
							</>
						) : (
							<Loading />
						)
					) : (
						<LogIn onLogInClick={ onSignIn } isLoading={ isLoading } />
					) }
				</div>
				{ isUpdateAvailable && <UpdateAppMessage onUpdate={ updateAssets } /> }
			</Box>
		</Box>
	);
};

export default App;
