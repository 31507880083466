import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
	getCurrentTimesheet,
	getSettings,
	getUserInfo,
} from '../actions/api-actions';
import { setWeekRangeData, WeekRangeData } from '../actions/time-actions';
import useTranslation from '../hooks/useTranslation';

interface ReloadPageProps {
	selectedWeekRange?: WeekRangeData;
	error: { type: 'SETTINGS' | 'USER' | 'TIMESHEET'; message: string };
}

const useStyles = makeStyles( ( theme ) => ( {
	error: {
		display: 'flex',
		flex: 1,
		color: theme.palette.primary.light,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	reloadButton: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		textTransform: 'none',
		fontSize: theme.spacing( 1.8 ),
		fontWeight: 900,
		margin: theme.spacing( 0.5 ),
		padding: theme.spacing( 0.5, 1.8 ),
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
} ) );

const ReloadPage: React.FC<ReloadPageProps> = ( {
	selectedWeekRange,
	error,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const t = useTranslation();

	const onReloadPage = useCallback( () => {
		switch ( error.type ) {
			case 'USER': {
				dispatch( getUserInfo() );
				break;
			}
			case 'SETTINGS':
				dispatch( getSettings() );
				break;
			default: {
				if ( selectedWeekRange ) {
					dispatch( setWeekRangeData( selectedWeekRange ) );
				} else {
					dispatch( getCurrentTimesheet() );
				}
				break;
			}
		}
	}, [ dispatch, selectedWeekRange, error ] );

	return (
		<Box className={ classes.error }>
			<Typography>{ t( error.message ) }</Typography>
			<Button className={ classes.reloadButton } onClick={ onReloadPage }>
				{ t( `Reload ${ error.type.toLowerCase() } data` ) }
			</Button>
		</Box>
	);
};

export default ReloadPage;
