import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
	Box,
	Typography,
	makeStyles,
	Button,
	CircularProgress,
} from '@material-ui/core';
import { Save, Delete } from '@material-ui/icons';

import { DateTime } from 'luxon';

import ManageLeaveRequestForm from './ManageLeaveRequestForm';
import { defaultStartDate } from '../Leave/leave-utils';
import ScreenWrapper from '../ScreenWrapper';
import Dropdown from '../../controls/Dropdown';
import useTranslation from '../../../hooks/useTranslation';
import { AppState } from '../../../reducers';
import { LeaveRequest } from '../../../types/api/leaveRequests';
import {
	getLeaveRequestActivities,
	deleteLeaveRequest,
	getLeaveRequest,
	createLeaveRequest,
} from '../../../actions/api-actions';
import {
	days,
	getDecimalTimeString,
	getWeekdayDate,
} from '../../../utils/date';
import { currentYear } from '../../controls/Minimal/helpers';
import ScreenMessage from '../../ScreenMessage';

export interface ManageLeaveDesktopProps {
	leaveRequestToEdit?: LeaveRequest;
	editMode: boolean;
}

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 2 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		textAlign: 'center',
		padding: theme.spacing( 2 ),
	},
	button: {
		padding: theme.spacing( 1 ),
		cursor: 'pointer',
		fontWeight: 700,
		backgroundColor: theme.palette.background.default,
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.11 ),
		fontSize: theme.spacing( 1.5 ),
		margin: theme.spacing( 0.5, 1, 0.5, 0 ),
		borderRadius: theme.spacing( 1 ),
		'&:hover': {
			color: theme.palette.primary.main,
		},
		textTransform: 'none',
		'&:first-child': {
			margin: theme.spacing( 0.5, 1 ),
		},
	},
	icon: {
		fontSize: theme.spacing( 2.5 ),
		margin: theme.spacing( 0, 0.25 ),
		cursor: 'pointer',
	},
	actionContainer: {
		borderBottomStyle: 'solid',
		borderBottomColor: theme.palette.text.disabled,
		display: 'flex',
		position: 'relative',
		height: '4rem',
	},
	titleContainer: {
		display: 'flex',
		alignSelf: 'center',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flex: '1',
	},
	loader: {
		margin: theme.spacing( 0, 0.5 ),
	},
	selectClass: {
		flex: 1,
		margin: theme.spacing( 2, 1, 0 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 1 ),
		color: theme.palette.text.primary,
	},
	missingData: {
		display: 'flex',
		justifyContent: 'center',
		padding: theme.spacing( 3, 0 ),
	},
	disabled: {
		position: 'absolute',
		zIndex: 1,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		padding: theme.spacing( 2 ),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.default,
		opacity: 0.5,
	},
	editModeDisabledFields: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: theme.spacing( 2, 3, 2, 1 ),
		maxWidth: `calc( 100% )`,
		paddingLeft: theme.spacing( 1 ),
		color: theme.palette.text.primary,
	},
	errorMessage: {
		color: theme.palette.error.dark,
		padding: theme.spacing( 0, 0, 0, 2 ),
		fontSize: theme.spacing( 1.5 ),
	},
} ) );

const ManageLeaveDesktop: React.FC<ManageLeaveDesktopProps> = ( {
	leaveRequestToEdit,
	editMode,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const [ isActionStarted, setIsActionStarted ] = useState<boolean>( false );

	const userAccounts = useSelector(
		( state: AppState ) => state.userState.accounts
	);

	const leaveRequests = useSelector(
		( state: AppState ) => state.leaveRequestState.accountRequests?.leaveRequests
	);

	const [ selectedActivity, setSelectedActivity ] = useState<string>( '' );

	const [ selectedPeriod, setSelectedPeriod ] = useState<
		'(part of day)' | 'Week' | 'Period'
	>( '(part of day)' );

	const id = leaveRequestToEdit?.id;

	const { isLoading } = useSelector( ( state: AppState ) => {
		return {
			isLoading:
				state.leaveRequestState.isLoading ||
				state.leaveRequestState.isLoadingActivities,
		};
	} );

	useEffect( () => {
		if ( isActionStarted && isLoading ) {
			setIsActionStarted( false );
			history.push( '/leave', { previous: history.location.pathname } );
		}
	}, [ isActionStarted, isLoading, history ] );

	const leaveRequestDaysWithHours =
		leaveRequestToEdit &&
		days.filter( ( day ) => Number( leaveRequestToEdit[ day ] ) > 0 ).length;

	const leaveRequestEditPeriod =
		leaveRequestDaysWithHours !== undefined
			? leaveRequestDaysWithHours === 0
				? 'Period'
				: leaveRequestDaysWithHours > 1
					? 'Week'
					: '(part of day)'
			: undefined;

	const employeeCode = userAccounts[ 0 ]?.employeeCode;
	const companyCode = userAccounts[ 0 ]?.company;
	const { activities } = useSelector(
		( state: AppState ) => state.leaveRequestState
	);

	useEffect( () => {
		if ( employeeCode && !activities.length ) {
			dispatch( getLeaveRequestActivities() );
		}
	}, [ employeeCode, dispatch, activities.length ] );

	useEffect( () => {
		if ( id && employeeCode && !leaveRequestToEdit ) {
			dispatch( getLeaveRequest( employeeCode, id ) );
		}
	}, [ employeeCode, dispatch, leaveRequestToEdit, id ] );


	const { leaveRequestsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	const activitiesOptions = activities.reduce( ( options, activity ) => {
		return {
			...options,
			[ activity.activityDescription ]: activity.activityCode,
		};
	}, {} );

	const defaultSelectedActivity = leaveRequestToEdit
		? Object.entries( activitiesOptions ).find(
			( option ) => option[ 1 ] === leaveRequestToEdit.activityCode
		)
		: undefined;

	useEffect( () => {
		if ( editMode ) {
			setSelectedActivity(
				( defaultSelectedActivity && defaultSelectedActivity[ 0 ] ) || ''
			);
		} else {
			setSelectedActivity( '' );
		}
	}, [ editMode, defaultSelectedActivity ] );

	const onChangeActivity = useCallback(
		( activity ) => {
			setSelectedActivity( activity );
			setSelectedPeriod( selectedPeriod || '(part of day)' );
		},
		[ selectedPeriod ]
	);

	const onDeleteLeaveRequest = useCallback( () => {
		if ( leaveRequestsYear && leaveRequestToEdit ) {
			dispatch(
				deleteLeaveRequest(
					employeeCode,
					leaveRequestToEdit?.id,
					leaveRequestsYear
				)
			);
			setIsActionStarted( true );
		}
	}, [ dispatch, employeeCode, leaveRequestToEdit, leaveRequestsYear ] );

	const onChangePeriod = useCallback( ( period ) => {
		setSelectedPeriod( period );
	}, [] );

	const onSubmit = useCallback(
		( data ) => {
			const numberOfHours =
				data.hours !== undefined
					? Number( getDecimalTimeString( data.hours, ':' ) )
					: undefined;

			const weekDaysHours = days
				.filter( ( day ) => ![ 'saturday', 'sunday' ].includes( day ) )
				.reduce( ( hoursProps, day ) => {
					const currentDayValue =
						data[ day ] !== undefined
							? Number( getDecimalTimeString( data[ day ], ':' ) )
							: undefined;
					return { ...hoursProps, [ day ]: currentDayValue };
				}, {} );

			const requestData = {
				...data,
				...weekDaysHours,
				numberOfHours,
				employeeCode,
				companyCode,
				activity:
					( activitiesOptions as any )[ selectedActivity ] ||
					leaveRequestToEdit?.activityCode,
				hours: undefined,
			};

			dispatch(
				createLeaveRequest( requestData, leaveRequestsYear || currentYear )
			);
			setIsActionStarted( true );
		},
		[
			employeeCode,
			companyCode,
			dispatch,
			selectedActivity,
			activitiesOptions,
			leaveRequestToEdit,
			leaveRequestsYear,
		]
	);

	const t = useTranslation();

	const startWeekDay =
		leaveRequestToEdit && days.find( ( day ) => leaveRequestToEdit[ day ] );

	const dateTimeStartDate =
		startWeekDay &&
		leaveRequestToEdit &&
		getWeekdayDate(
			DateTime.fromJSDate( new Date( leaveRequestToEdit.startDateOfWeek ) ),
			startWeekDay
		);

	const disabledStartDate =
		dateTimeStartDate &&
		dateTimeStartDate.toMillis() < defaultStartDate.toMillis();

	const disabled =
		leaveRequestToEdit &&
		( leaveRequestToEdit?.status?.id !== 'N' || disabledStartDate );

	return (
		<>
			<Box className={ classes.actionContainer }>
				<Box className={ classes.titleContainer }>
					<Typography className={ classes.pageTitle }>
						{ t( 'Leave request' ) }
					</Typography>
					{ isActionStarted && (
						<CircularProgress size={ 20 } className={ classes.loader } />
					) }
				</Box>
				{ ( !disabled || !editMode ) && !( editMode && !leaveRequests?.length ) && selectedActivity && (
					<Button
						className={ classes.button }
						form="manage-leave-request"
						type="submit"
					>
						<Save className={ classes.icon } />
						{ t( 'Save' ) }
					</Button>
				) }
				{ !!leaveRequests?.length &&
					id &&
					leaveRequestToEdit?.status?.id === 'N' && (
						<Button className={ classes.button } onClick={ onDeleteLeaveRequest }>
							<Delete className={ classes.icon } />
							{ t( 'Delete' ) }
						</Button>
					) }
			</Box>
			<ScreenWrapper>
				{ !editMode || leaveRequests?.length ? (
					<>
						{ disabled && <Box className={ classes.disabled } /> }
						{ !editMode ? (
							<>
								<Dropdown
									label="Activity"
									options={ Object.keys( activitiesOptions ) }
									autoExpand={ false }
									selectedValue={ selectedActivity }
									onValueChange={ onChangeActivity }
									selectClass={ classes.selectClass }
								/>
								{ !selectedActivity && (
									<Box className={ classes.errorMessage } paddingLeft={ 2 }>
										{ t( 'Activity name is required' ) }
									</Box>
								) }
								<Dropdown
									label="Period"
									options={
										leaveRequestEditPeriod
											? [ leaveRequestEditPeriod ]
											: [ '(part of day)', 'Week', 'Period' ]
									}
									autoExpand={ false }
									selectedValue={
										leaveRequestEditPeriod
											? leaveRequestEditPeriod
											: selectedPeriod || ''
									}
									onValueChange={ onChangePeriod }
									selectClass={ classes.selectClass }
								/>
							</>
						) : (
							<>
								<Box className={ classes.editModeDisabledFields }>
									<Typography>{ t( 'Activity' ) }</Typography>
									<Typography>{ selectedActivity }</Typography>
								</Box>
								<Box className={ classes.editModeDisabledFields }>
									<Typography>{ t( 'Period' ) }</Typography>
									<Typography>{ leaveRequestEditPeriod }</Typography>
								</Box>
							</>
						) }
						{ ( selectedActivity || defaultSelectedActivity ) && (
							<ManageLeaveRequestForm
								onSubmit={ onSubmit }
								period={
									leaveRequestEditPeriod
										? leaveRequestEditPeriod
										: selectedPeriod || '(part of day)'
								}
								leaveParams={ editMode ? leaveRequestToEdit : undefined }
							/>
						) }
					</>
				) : (
					<ScreenMessage
						message={ 'No leave requests added for the selected year' }
					/>
				) }
			</ScreenWrapper>
		</>
	);
};

export default ManageLeaveDesktop;
