import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Box, makeStyles, Typography, Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { DateTime } from 'luxon';

import ExpenseClaimTemplateCard from './ExpenseClaimTemplateCard';
import YearSelector from '../Leave/YearSelector';
import OverlayLoader from '../../OverlayLoader';
import { AppState } from '../../../reducers';
import useTranslation from '../../../hooks/useTranslation';
import ScreenWrapper from '../ScreenWrapper';
import useExpenseClaimsData from '../../../hooks/useExpenseClaimsData';
import useExpenseClaimActions from '../../../hooks/useExpenseClaimActions';
import { setSelectedExpenseClaimsYear } from '../../../actions/time-actions';
import ScreenMessage from '../../ScreenMessage';
import { currentYear } from '../../controls/Minimal/helpers';

export interface ExpenseClaimsProps { }

const setChipLabel = ( date: Date ) =>
	DateTime.fromJSDate( new Date( date ) ).toISODate();

const useStyles = makeStyles( ( theme ) => ( {
	pageTitle: {
		fontSize: theme.spacing( 3 ),
		fontWeight: 900,
		color: theme.palette.text.disabled,
		padding: theme.spacing( 3, 0, 0, 4 ),
	},
	fab: {
		position: 'absolute',
		bottom: theme.spacing( 1 ),
		right: '12.5%',
		transform: 'translate( 50%, 0 )',
	},
	desktopButton: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
	},
	card: {
		'&:last-child': {
			marginBottom: theme.spacing( 5 ),
		},
	},
} ) );

const ExpenseClaims: React.FC<ExpenseClaimsProps> = () => {
	const classes = useStyles();
	const history = useHistory();

	const { accounts: userAccounts } = useSelector(
		( state: AppState ) => state.userState
	);

	const { expenseClaimsYear } = useSelector(
		( state: AppState ) => state.timeState
	);

	const onAddExpenseClaim = useCallback( () => {
		history.push( '/create-expense-claim', { previous: history.location.pathname } );
	}, [ history ] );

	const employeeCode = userAccounts[ 0 ]?.employeeCode;

	const dispatch = useDispatch();

	useEffect( () => {
		if ( !expenseClaimsYear && employeeCode ) {
			dispatch( setSelectedExpenseClaimsYear( currentYear, employeeCode ) );
		}
	}, [ employeeCode, dispatch, expenseClaimsYear ] );

	const onChangeYear = useCallback(
		( year: number ) => {
			dispatch( setSelectedExpenseClaimsYear( year, employeeCode ) );
		},
		[ employeeCode, dispatch ]
	);

	const onSelectExpenseClaim = useCallback(
		( id: string ) => {
			history.push( `/expense-claim/${ id }`, { previous: history.location.pathname } );
		},
		[ history ]
	);

	const {
		onDeleteExpenseClaim,
		onReleaseExpenseClaim,
	} = useExpenseClaimActions();

	const {
		expenseClaims,
		isLoadingExpenseClaims,
	} = useExpenseClaimsData();

	const t = useTranslation();

	return (
		<ScreenWrapper>
			<OverlayLoader
				message={ t( 'Loading expense claims data' ) }
				loadingCondition={ isLoadingExpenseClaims || !expenseClaims }
			/>
			<Box>
				<Typography className={ classes.pageTitle }>
					{ t( 'Expense Claims' ) }
				</Typography>
				<YearSelector
					selectedYear={ expenseClaimsYear || currentYear }
					onChange={ onChangeYear }
				/>
			</Box>
			{ expenseClaims?.length ? (
				<Box flex="1" overflow="auto" flexDirection="column" display="flex">
					<Box flex="1">
						{ expenseClaims &&
							expenseClaims.map( ( expenseClaim ) => (
								<ExpenseClaimTemplateCard
									key={ expenseClaim.id }
									amount={ expenseClaim.salesAmount.toFixed( 2 ) }
									onSelect={ onSelectExpenseClaim }
									onDelete={ onDeleteExpenseClaim }
									onRelease={ onReleaseExpenseClaim }
									date={ setChipLabel( expenseClaim.date ) }
									statusId={ expenseClaim.status?.id }
									status={ expenseClaim.status?.desc }
									description={ expenseClaim.description }
									className={ classes.card }
									codeDescription={ expenseClaim.descriptionExpenseClaimCode }
									id={ expenseClaim.id }
								/>
							) ) }
					</Box>
				</Box>
			) : (
				<ScreenMessage
					message={ t( 'No expense claims added for the selected year' ) }
				/>
			) }
			<Fab
				color="primary"
				className={ classes.fab }
				aria-controls="create-expense-claim"
				onClick={ onAddExpenseClaim }
			>
				<Add />
			</Fab>
		</ScreenWrapper>
	);
};

export default ExpenseClaims;
