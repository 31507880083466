import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles( ( theme ) => ( {
	paper: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		cursor: 'pointer',
		padding: theme.spacing( 1, 2 ),
		borderLeftColor: theme.palette.secondary.main,
		backgroundColor: theme.palette.background.paper,
		flexGrow: 1,
	},
	day: {
		boxShadow: `0px 1px 16px ${ theme.palette.custom.boxShadow }`,
		cursor: 'pointer',
		padding: theme.spacing( 1.3 ),
		borderRadius: theme.spacing( 0.5 ),
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.primary.contrastText,
		width: '40px',
		height: '40px',
		maxWidth: '5.5vh',
		maxHeight: '5.5vh',
		display: 'flex',
		justifyContent: 'center',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing( 1, 3, 0, 3 ),
		opacity: '0.9',

	},
} ) );

export interface PublicHolidaysTemplateCardProps {
	title: string;
	day: number;
}

const PublicHolidaysTemplateCard: React.FC<PublicHolidaysTemplateCardProps> = ( {
	title,
	day,
} ) => {
	const classes = useStyles();

	return (
		<Box m={ 2 } className={ classes.container }>
			<Typography className={ classes.day }>{ day }</Typography>
			<Typography className={ classes.paper }>{ title }</Typography>
		</Box>
	);
};
export default PublicHolidaysTemplateCard;
