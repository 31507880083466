import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import logo from './../../images/ontime-logo.svg';

const useStyles = makeStyles( ( theme ) => ( {
	appHeader: {
		padding: theme.spacing( 0.5, 0, 0 ),
		height: '10rem',
		maxHeight: '15vh',
		borderBottom: 'solid',
		borderBottomColor: theme.palette.custom.paper,
	},
	logoContainer: {
		height: '8rem',
		maxHeight: '11vh',
		'& img': {
			height: '80%'
		},
	},
} ) );

const HomeLogoHeader: React.FC = () => {
	const classes = useStyles();

	return (
		<header className={ classes.appHeader }>
			<Box textAlign="center" className={ classes.logoContainer }>
				<img src={ logo } alt="OnTime Logo" />
				<Typography variant="h2" >{ 'onTime' }</Typography>
			</Box>
		</header>
	);
};

export default HomeLogoHeader;
