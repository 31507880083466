import React, { useEffect, useCallback } from 'react';

import { NavbarElementProps } from 'react-day-picker';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';

import { Box, makeStyles, Typography } from '@material-ui/core';

import monthImage from './../../../images/june.png';
import overlay from './../../../images/overlay.png';

import {
	getNextMonthStartDate,
	getPreviousMonthStartDate,
} from '../../../utils/date';
import { ArrowRight, ArrowLeft } from '@material-ui/icons';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '9rem',
		maxHeight: '10vh',
		backgroundImage: `linear-gradient(${ theme.palette.custom.picture },
                       ${ theme.palette.custom.picture }),url( ${ overlay } ), url( ${ monthImage } )`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		color: theme.palette.primary.contrastText,
		textShadow: '0px 3px 6px #000000CC',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'relative'
	},
	navRight: {
		cursor: 'pointer',
		position: 'absolute',
		right: theme.spacing( 2 ),
		fontSize: theme.spacing( 6 ),
	},
	navLeft: {
		cursor: 'pointer',
		position: 'absolute',
		left: theme.spacing( 2 ),
		fontSize: theme.spacing( 6 ),
	},
} ) );

const monthSlideDuration = 300;

export interface MonthClasses {
	slideMonthLeft: string;
	moveMonthRight: string;
	slideMonth: string;
	moveMonthLeft: string;
	slideMonthRight: string;
}

export interface CalendarToolbarCustomProps {
	monthClasses: MonthClasses;
	setHandlers: ( handlers: SwipeableHandlers ) => void;
	setMonthClass: ( montClass: string ) => void;
	onChange: ( date: Date ) => void;
}

const CalendarToolbar: React.FC<
	Partial<NavbarElementProps> & CalendarToolbarCustomProps
> = ( props ) => {
	const {
		localeUtils,
		onPreviousClick,
		month,
		onNextClick,
		setHandlers,
		monthClasses,
		setMonthClass,
		onChange,
	} = props;

	const classes = useStyles();

	const onNextMonthClick = useCallback( () => {
		month && onChange( getNextMonthStartDate( month ) );

		setMonthClass( monthClasses.slideMonthLeft );
		setTimeout( () => {
			onNextClick && onNextClick();
			setMonthClass( monthClasses.moveMonthRight );
		}, monthSlideDuration );
		setTimeout( () => {
			setMonthClass( monthClasses.slideMonth );
		}, monthSlideDuration + 50 );
	}, [ onNextClick, setMonthClass, monthClasses, month, onChange ] );

	const onPreviousMonthClick = useCallback( () => {
		month && onChange( getPreviousMonthStartDate( month ) );

		setMonthClass( monthClasses.slideMonthRight );
		setTimeout( () => {
			onPreviousClick && onPreviousClick();
			setMonthClass( monthClasses.moveMonthLeft );
		}, monthSlideDuration );
		setTimeout( () => {
			setMonthClass( monthClasses.slideMonth );
		}, monthSlideDuration + 50 );
	}, [ onPreviousClick, setMonthClass, monthClasses, onChange, month ] );

	const handlers: SwipeableHandlers = useSwipeable( {
		onSwipedLeft: onNextMonthClick,
		onSwipedRight: onPreviousMonthClick,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	} );

	useEffect( () => {
		setHandlers( handlers );
	}, [ setHandlers, handlers ] );
	const months = localeUtils?.getMonths();
	const currentMonth = ( month && months ) && months[ month.getMonth() ];
	const displayYear: number =
		month && month.getFullYear() !== new Date().getFullYear()
			? month.getFullYear()
			: 0;

	const t = useTranslation()

	return (
		<Box className={ classes.mediaHeader }>
			<ArrowLeft
				onClick={ onPreviousMonthClick }
				className={ classes.navLeft }
			/>
			<Typography variant="h2">
				{ displayYear ? `${ t( currentMonth || '' ) } ${ displayYear }` : `${ t( currentMonth || '' ) }` }
			</Typography>
			<ArrowRight
				onClick={ onNextMonthClick }
				className={ classes.navRight }
			/>
		</Box>
	);
};

export default CalendarToolbar;
