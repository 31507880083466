import React from 'react';

import { Box } from '@material-ui/core';

import MinimalMenu from '../../controls/Minimal/MinimalMenu';

import { DateTime } from 'luxon';

import useDestinationNavSettings from '../../../hooks/useDestinationNavSettings';
import Dropdown from '../../controls/Dropdown';
import MinimalTimesheets from './MinimalTimesheets';
import ManageDraftsMenu from './ManageDraftsMenu';
import useAccountTimesheetManagement from '../../../hooks/useAccountTimesheetManagement';
import OverlayLoader from '../../OverlayLoader';
import ScreenWrapper from '../ScreenWrapper';
import ReloadPage from '../../ReloadPage';
import useErrorData from '../../../hooks/useErrorData';

export interface OvertimeOptions {
	hasMonFriOvertime: boolean;
	hasSaturdayOvertime: boolean;
	hasSunPubHolOvertime: boolean;
	employeeCode: string;
}

const Minimal = () => {
	const destinationNavigationSettings = useDestinationNavSettings();

	const {
		isLoading,
		selectedWeekRange,
		setSelectedWeekRange,
		currentAccountData,
		userAccounts,
		onSelectUserAccount,
		overtimeOptions,
		disableUnRelease,
		hasEnoughHours,
		onRefreshAccountTimesheetData,
	} = useAccountTimesheetManagement();

	const error = useErrorData();

	return (
		<ScreenWrapper>

			<>
				<OverlayLoader
					loadingCondition={ isLoading || !selectedWeekRange }
					message={ 'Loading timesheet data' }
				/>
				<Box>

					<MinimalMenu
						selectedWeekRange={ selectedWeekRange }
						onWeekRangeSelected={ setSelectedWeekRange }
					/>
				</Box>
				{ !error && selectedWeekRange && currentAccountData ? (
					<Box flex={ 1 } overflow="auto">
						{ userAccounts.length > 1 && (
							<Box>
								<Dropdown
									label="Your Account"
									options={ userAccounts.map( ( account ) => account.companyName ) }
									selectedValue={ currentAccountData.companyName }
									autoExpand={ false }
									onValueChange={ onSelectUserAccount }
									isHeader={ true }
								/>
							</Box>
						) }
						<MinimalTimesheets accountHourData={ currentAccountData } />
					</Box>
				) : null }
			</>
			{ error && <ReloadPage error={ error } selectedWeekRange={ selectedWeekRange } /> }
			{ !error &&
				selectedWeekRange &&
				currentAccountData &&
				!currentAccountData.isInactiveForSelectedWeek && (
					<ManageDraftsMenu
						releaseTimesheetUIProps={ {
							overtimeOptions: [ overtimeOptions ],
							hasEnoughHours,
							fromDateTime: DateTime.fromJSDate( selectedWeekRange.from ),
							onRefreshTimesheetData: onRefreshAccountTimesheetData,
							disableUnRelease,
						} }
						addHoursProps={ destinationNavigationSettings }
					/>
				) }
		</ScreenWrapper>
	);
};

export default Minimal;
