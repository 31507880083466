import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { TextsmsOutlined as CommentIcon } from '@material-ui/icons';
import TemplateCard from '../../TemplateCard';
import { convertDecimalToTimeString, days } from '../../../utils/date';
import { WeekActivity } from '../../../types/api/timesheets';
import { useHistory } from 'react-router-dom';
import MinimalWeekDays from './MinimalWeekDays';
import useTranslation from '../../../hooks/useTranslation';
import ScreenMessage from '../../ScreenMessage';
import useAccountTimesheetManagement from '../../../hooks/useAccountTimesheetManagement';

export interface AccountTimesheetHoursData {
	index: number;
	companyKey: string;
	companyName: string;
	employeeId: string;
	contractHours: number;
	totalHoursThisWeek: number;
	timeSpentWeekDays: string;
	startOfEmployment: string;
	endOfEmployment: string;
	timesheets: WeekActivity[];
	isInactiveForSelectedWeek: boolean;
}

export interface MinimalTimesheetsProps {
	accountHourData: AccountTimesheetHoursData;
}

const useStyles = makeStyles( ( theme ) => ( {
	okayText: {
		color: theme.palette.custom.turquoise,
	},
	notOkayText: {
		color: theme.palette.custom.orange,
	},
	errorMessage: {
		backgroundColor: theme.palette.custom.orange,
		color: 'white',
	},
	cardWrapper: {
		padding: 0,
		margin: 0,
	},
	inactiveAccount: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
	},
	weekDayContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing( 1, 2 ),
		opacity: '0.8',
	},
	weekDay: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
	},
	totalHours: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		color: theme.palette.primary.main,
	},
	missingHours: {
		fontWeight: 900,
		fontSize: theme.spacing( 1.8 ),
		color: theme.palette.secondary.main,
	},
} ) );

interface DayKeysTimesheetsGroupingItem {
	destination: string;
	id: string;
	hours: number;
	status: string;
	isTemplate: boolean;
	templateColor?: string;
	name?: string;
	comment?: string;
}
interface DayKeysTimesheetsGrouping {
	monday: Array<DayKeysTimesheetsGroupingItem>;
	tuesday: Array<DayKeysTimesheetsGroupingItem>;
	wednesday: Array<DayKeysTimesheetsGroupingItem>;
	thursday: Array<DayKeysTimesheetsGroupingItem>;
	friday: Array<DayKeysTimesheetsGroupingItem>;
	saturday: Array<DayKeysTimesheetsGroupingItem>;
	sunday: Array<DayKeysTimesheetsGroupingItem>;
}

interface DayHoursGroup {
	monday: number;
	tuesday: number;
	wednesday: number;
	thursday: number;
	friday: number;
	saturday: number;
	sunday: number;
}

const MinimalTimesheets: React.FC<MinimalTimesheetsProps> = ( {
	accountHourData,
} ) => {
	const {
		totalHoursThisWeek,
		contractHours,
		timeSpentWeekDays,
		companyName,
		timesheets,
	} = accountHourData;

	const dayKeys: DayKeysTimesheetsGrouping = {
		monday: [],
		tuesday: [],
		wednesday: [],
		thursday: [],
		friday: [],
		saturday: [],
		sunday: [],
	};

	const dayHours: DayHoursGroup = {
		monday: 0,
		tuesday: 0,
		wednesday: 0,
		thursday: 0,
		friday: 0,
		saturday: 0,
		sunday: 0,
	};

	const classes = useStyles();

	const history = useHistory();

	// Group the timesheet hours by day of the week
	timesheets.forEach( ( timesheet ) => {
		days.forEach( ( key ) => {
			const { name, destination, details, isTemplate, templateColor } =
				timesheet;
			dayKeys[ key ].push( {
				hours: details[ key ],
				name,
				destination,
				isTemplate,
				status: timesheet.details.status.desc,
				id: details.id,
				templateColor,
				comment: timesheet.details.comment
			} );
			const time = convertDecimalToTimeString( details[ key ] ).split( ':' );
			dayHours[ key ] += Number( time[ 0 ] ) * 60 + Number( time[ 1 ] );
		} );
	} );

	const t = useTranslation();
	const { lowestStatusTimesheet } = useAccountTimesheetManagement();
	const loggedHoursMessage =
		lowestStatusTimesheet === 'C' || lowestStatusTimesheet === ''
			? 'Not released logged hours'
			: 'Logged hours';

	return (
		<Box marginBottom={ 8 }>
			{ !accountHourData.isInactiveForSelectedWeek && (
				<Box display="flex" p={ 2 } alignItems="center">
					<Box flex="1">
						<Typography>{ t( loggedHoursMessage ) }</Typography>
					</Box>
					<Box
						className={
							totalHoursThisWeek < contractHours
								? classes.notOkayText
								: classes.okayText
						}
					>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography>
								{ `${ timeSpentWeekDays }/${ convertDecimalToTimeString(
									contractHours
								) }` }
							</Typography>
						</Box>
					</Box>
				</Box>
			) }
			{ !!timesheets.length ? (
				days.map( ( day, i ) => (
					<React.Fragment key={ `${ day }${ i }` }>
						<MinimalWeekDays
							weekDay={ day }
							isHidden={
								[ 'sunday', 'saturday' ].includes( day ) && dayHours[ day ] === 0
							}
							holiday={ day }
							totalHours={ dayHours[ day ] }
						/>
						{ dayKeys[ day ].map( ( timesheet: any, index: any ) => {
							if ( timesheet.hours !== 0 ) {
								return (
									<TemplateCard
										key={ `${ index }${ timesheet.name }${ timesheet.destination }${ day }}` }
										color={
											timesheet.destination === 'Project'
												? 'primary'
												: 'secondary'
										}
										title={ timesheet.name }
										customChipColor={ timesheet.templateColor }
										chipLabel={ convertDecimalToTimeString( timesheet.hours ) }
										action={ () =>
											history.push(
												timesheet.isTemplate
													? `/edit-timesheet/${ timesheet.name }/${ timesheet.id }`
													: `/edit-timesheet/${ timesheet.id }`,
												{ previous: history.location.pathname }
											)
										}
										disabled={ timesheet.status.toLowerCase() !== 'draft' }
										tooltip={ timesheet.comment }
										Icon={ CommentIcon }
									/>
								);
							}
							return null;
						} ) }
					</React.Fragment>
				) )
			) : (
				<ScreenMessage
					message={
						accountHourData.isInactiveForSelectedWeek
							? 'inactive account'
							: 'no time logged'
					}
					messageProps={ !accountHourData.isInactiveForSelectedWeek ? { companyName } : undefined }
					className={
						accountHourData.isInactiveForSelectedWeek
							? classes.inactiveAccount
							: classes.errorMessage
					}
				/>
			) }
		</Box>
	);
};

export default MinimalTimesheets;
