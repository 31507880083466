import {
	Box,
	// Divider, 
	makeStyles,
	Typography
} from '@material-ui/core';
import React, {
	// useCallback,
	//  useState 
} from 'react';
import useTranslation from '../../../hooks/useTranslation';
import { WeekActivity } from '../../../types/api/timesheets';
import { convertDecimalToTimeString } from '../../../utils/date';
// import DividerToggle from '../../DividerToggle';

export interface WeekSummaryProps {
	currentAccountWeekActivities: Array<WeekActivity>;
	contractHours: number;
}

// const summaryData = [
// 	'destination',
// 	'totalHours',
// 	'activityName',
// 	'subtask',
// 	'comment',
// ];

const useStyles = makeStyles( ( theme ) => ( {
	summaryContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing( 0, 1, 1 ),
		height: '4rem'
	},
	summaryTitle: {
		fontSize: theme.spacing( 2.5 ),
		fontWeight: 900,
		margin: theme.spacing( 1, 0, 0, 0 ),
	},
	summaryValue: {
		padding: theme.spacing( 0.5, 2 ),
		backgroundColor: theme.palette.text.disabled,
		color: theme.palette.primary.contrastText,
		borderRadius: theme.spacing( 1 ),
		fontSize: theme.spacing( 2.5 ),
		fontWeight: 900,
		margin: theme.spacing( 1, 0, 0 ),
	},
	summaryItemsContainer: {
		display: 'flex',
		padding: theme.spacing( 1, 4 ),
		justifyContent: 'center',
		alignItems: 'center',
	},
	summaryItem: {
		flex: 1,
		maxWidth: '20%',
	},
} ) );

const WeekSummary: React.FC<WeekSummaryProps> = ( {
	currentAccountWeekActivities,
	contractHours
} ) => {
	const classes = useStyles();
	// const [ isWeekSummaryShown, setIsWeekSummaryShown ] = useState<boolean>( true );
	// const onToggleWeekSummary = useCallback( () => {
	// 	setIsWeekSummaryShown( !isWeekSummaryShown );
	// }, [ isWeekSummaryShown ] );

	const totalWeekHours = currentAccountWeekActivities.reduce(
		( sum: number, element: WeekActivity ) => {
			return sum + element.hours;
		},
		0
	);

	// const weekSummaryActivities = currentAccountWeekActivities.map( ( activity ) => {
	// 	const destination = activity.destination;
	// 	const activityName =
	// 		activity.details.projectDescription ||
	// 		activity.details.activityDescription;
	// 	const totalHours = convertDecimalToTimeString( activity.hours );
	// 	const subtask = activity.details.subTaskNumber || '-';
	// 	const comment = activity.details.comment || '-';
	// 	return {
	// 		destination,
	// 		totalHours,
	// 		activityName,
	// 		subtask,
	// 		comment,
	// 	};
	// } );
	const t = useTranslation();

	return (
		<>
			{/* <DividerToggle
				onToggle={ onToggleWeekSummary }
				expandMore={ !isWeekSummaryShown }
			/> */}
			{/* {isWeekSummaryShown && ( */ }
			<>
				<Box className={ classes.summaryContainer }>
					<Typography className={ classes.summaryTitle }>
						{ t( 'Total hours' ) }
					</Typography>
					<Typography className={ classes.summaryValue }>
						{ convertDecimalToTimeString( totalWeekHours ) }
						{ ' / ' + convertDecimalToTimeString( contractHours ) }
					</Typography>
				</Box>
				{/* 
					To use after  the decision about merging the time for the different timesheet items in the backend.

					<Divider />
					<Box className={ classes.summaryItemsContainer }>
						{ [
							'Destination',
							'Total Hours',
							'Activity',
							'Subtask',
							'Comment',
						].map( ( e ) => (
							<Typography className={ classes.summaryItem } key={ e }>
								{e }
							</Typography>
						) ) }
					</Box>
					<Divider />
					{weekSummaryActivities.map( ( summaryActivity, containerIndex ) => (
						<Box
							className={ classes.summaryItemsContainer }
							key={ `summaryActivity-${ containerIndex }` }
						>
							{summaryData.map( ( key, itemIndex ) => (
								<Typography
									className={ classes.summaryItem }
									key={ `summaryData-${ containerIndex }-${ itemIndex }` }
								>
									{( summaryActivity as any )[ key ] }
								</Typography>
							) ) }
						</Box>
					) ) } */}
			</>
			{/* ) } */ }
		</>
	);
};

export default WeekSummary;
