import {
	GET_PUBLIC_HOLIDAYS_STARTED,
	GET_PUBLIC_HOLIDAYS_FAILED,
	GET_PUBLIC_HOLIDAYS_SUCCEEDED,
} from './../actions/api-actions';
import { PublicHolidaysData } from '../types/api/timesheets';

export interface PublicHolidaysState {
	publicHolidays: Array<PublicHolidaysData>;
	selectedWeekHolidays: Array<PublicHolidaysData>
	isLoading: boolean;
}

interface ActionPublicHolidays {
	type: string,
	error?: any;
	publicHolidays: Array<PublicHolidaysData>
	selectedWeekHolidays: Array<PublicHolidaysData>
}

export const publicHolidaysReducer = (
	state: PublicHolidaysState = {
		publicHolidays: [],
		selectedWeekHolidays: [],
		isLoading: false,
	},
	action: ActionPublicHolidays
) => {
	switch ( action.type ) {
		case GET_PUBLIC_HOLIDAYS_STARTED: {
			return {
				...state,
				isLoading: true,
			};
		}
		case GET_PUBLIC_HOLIDAYS_SUCCEEDED: {
			const { publicHolidays, selectedWeekHolidays } = action;
			return {
				...state,
				publicHolidays,
				selectedWeekHolidays,
				isLoading: false,
			};
		}
		case GET_PUBLIC_HOLIDAYS_FAILED: {
			return {
				...state,
				isLoading: false,
			};
		}
		default:
			return state;
	}
};
