import React from 'react';
import { Typography } from '@material-ui/core';
import { WeekdayElementProps } from 'react-day-picker';
import useTranslation from '../../../hooks/useTranslation';

const Weekday: React.FunctionComponent<WeekdayElementProps> = ( props ) => {
	const { weekday, locale, localeUtils, className } = props;
	const t = useTranslation();
	const weekdayName = t( localeUtils.formatWeekdayLong( weekday, locale ).toLowerCase() );

	return (
		<Typography className={ className }>{ weekdayName.slice( 0, 2 ) }</Typography>
	);
};

export default Weekday;
