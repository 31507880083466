import { TimesheetSourceData } from '../types/api/timesheets';

import {
	GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED,
	GET_TIME_SHEET_SOURCE_DATA_FAILED,
	GET_TIME_SHEET_SOURCE_DATA_STARTED,
} from '../actions/api-actions';

export interface TimesheetSourcesState {
	data: TimesheetSourceData | null;
	error: any;
	isLoading: boolean;
}

export function timesheetSourcesReducer (
	state: TimesheetSourcesState = {
		data: null,
		error: null,
		isLoading: false,
	},
	action: any
) {
	switch ( action.type ) {
		case GET_TIME_SHEET_SOURCE_DATA_SUCCEEDED:
			return {
				...state,
				data: action.timesheetSourceData,
				isLoading: false,
				error: null,
			};
		case GET_TIME_SHEET_SOURCE_DATA_STARTED:
			return {
				...state,
				data: null,
				isLoading: true,
				error: null,
			};
		case GET_TIME_SHEET_SOURCE_DATA_FAILED:
			return {
				...state,
				data: null,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
}
