import React from 'react';
import { Box } from '@material-ui/core';
import DesktopPopUpHeader from '../Headers/DesktopPopUpHeader';
import AddTimesheetHeader from '../Headers/AddTimesheetHeader';
import ScreenHeader from '../Headers/ScreenHeader';
import useTranslation from '../../hooks/useTranslation';

interface WrapperProps {
	desktopAction?: () => void;
	customTitle?: string;
	withHeader?: boolean;
}

const ScreenWrapper: React.FC<WrapperProps> = ( {
	children,
	desktopAction,
	customTitle,
	withHeader,
} ) => {
	const t = useTranslation();

	return (
		<>
			{ desktopAction
				? withHeader && <DesktopPopUpHeader onClosePopUp={ desktopAction } />
				: customTitle
					? withHeader && <ScreenHeader title={ t( customTitle ) } />
					: withHeader && <AddTimesheetHeader /> }
			<Box
				display="flex"
				flexDirection="column"
				height="calc( 100vh - 18rem)"
				flex="1"
				overflow="auto"
				position="relative"
			>
				{ children }
			</Box>
		</>
	);
};

export default ScreenWrapper;
