import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';

const LoginCallback: React.FC = () => {
	const history = useHistory();

	useEffect( () => {
		history.replace( "/" );
	} )

	return (
		<Box padding={ 2 }>
			<Typography>We're processing your login information...</Typography>
		</Box>
	);
};

export default LoginCallback;