import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
	Box,
	Typography,
	TextField,
	makeStyles,
	Checkbox,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
} from '@material-ui/core';
import { AttachFile, Close } from '@material-ui/icons';

import FileView from '../../FileView';
import DividerToggle from '../../DividerToggle';
import { fileToBase64String } from '../../../utils/files';
import useTranslation from '../../../hooks/useTranslation';
import * as promises from '../../../utils/promises';
import { ExpenseClaim } from '../../../types/api/expenseClaims';

const useStyles = makeStyles( ( theme ) => ( {
	formRow: {
		display: 'flex',
		padding: theme.spacing( 1, 2 ),
		justifyContent: 'space-between',
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing( 0, 3, 0, 0 ),
		minWidth: theme.spacing( 14 ),
		maxWidth: theme.spacing( 17.5 ),
	},
	value: {
		flex: 1,
		overflow: 'hidden',
		textAlign: 'right',
		maxWidth: `calc( 100%-10em )`,
	},
	pickerContainer: {
		overflow: 'hidden',
	},
	comment: {
		padding: theme.spacing( 0, 1 ),
		borderRadius: theme.spacing( 0.5 ),
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.25 ),
	},
	uploadBox: {
		padding: theme.spacing( 3, 1 ),
		backgroundColor: theme.palette.custom.paper,
		borderRadius: theme.spacing( 0.5 ),
		cursor: 'pointer',
	},
	description: {
		padding: theme.spacing( 0, 1, 4 ),
		margin: theme.spacing( 1, 0 ),
		borderRadius: theme.spacing( 0.5 ),
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.25 ),
	},
	picker: {
		padding: theme.spacing( 0 ),
		cursor: 'pointer',
		'& .MuiInputBase-input ': {
			textAlign: 'left',
		},
	},
	attachBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	attachFile: {
		fontSize: theme.spacing( 3 ),
		marginBottom: theme.spacing( 1 ),
	},
	attachText: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
	},
	timeLabel: {
		textTransform: 'capitalize',
	},
	checkBox: {
		padding: theme.spacing( 0.5 ),
	},
	deleteFile: {
		cursor: 'pointer',
	},
} ) );

export interface ManageExpenseClaimFormProps {
	date: MaterialUiPickersDate;
	setDate: ( date: MaterialUiPickersDate ) => void;
	onSubmit: ( data: any ) => void;
	setError: ( error?: string ) => void;
	error?: string;
	expenseClaimCode?: string;
	projectNumber?: string;
	subProjectNumber?: string;
	projectPhaseNumber?: string;
	expenseClaimToEdit?: ExpenseClaim;
	expenseClaimCodeType?: Array<'project' | 'currency' | 'mileage'>;
}

const ManageExpenseClaimForm: React.FC<ManageExpenseClaimFormProps> = ( {
	date,
	setDate,
	onSubmit,
	expenseClaimCode,
	projectNumber,
	projectPhaseNumber,
	subProjectNumber,
	expenseClaimToEdit,
	expenseClaimCodeType,
	error,
	setError,
} ) => {
	const classes = useStyles();

	const [ files, setFiles ] = useState<
		Array<{ fileName: string; fileData: string }>
	>( [] );

	const [ isFileContainerHidden, setIsFilesContainerHidden ] =
		useState<boolean>( true );

	const onFilesContainerToggle = useCallback( () => {
		setIsFilesContainerHidden( !isFileContainerHidden );
	}, [ isFileContainerHidden ] );

	const getFileData = useCallback( async ( file ) => {
		const fileData = await fileToBase64String( file );
		const fileName = file.name;
		return { fileName, fileData };
	}, [] );

	const onDrop = useCallback(
		async ( acceptedFiles ) => {
			try {
				const filesData = await promises.map( acceptedFiles, getFileData );
				setFiles( [ ...files, ...filesData ] );
			} catch ( e ) { }
		},
		[ files, getFileData ]
	);

	const onChange = useCallback(
		( e ) => {
			if ( !Number( e.target.value ) && e.target.value !== '' ) {
				setError( 'Enter a valid value' );
			} else {
				setError( undefined );
			}
		},
		[ setError ]
	);

	const onRemoveSelectedFile = ( fileName: string ) => {
		const newFiles = files.filter( ( file ) => file.fileName !== fileName );
		setFiles( newFiles );
	};

	const { getRootProps, getInputProps } = useDropzone( { onDrop } );

	const { register, handleSubmit } = useForm();

	const onFormSubmit = handleSubmit( ( data: any ) => {
		onSubmit( {
			...data,
			amount: Number( data.amount ),
			KM: Number( data.KM ),
			files,
			date: date?.toISODate(),
			projectNumber: projectNumber?.toString() || '',
			projectPhaseNumber: projectPhaseNumber?.toString() || '',
			subProjectNumber: subProjectNumber?.toString() || '',
			expenseClaimCode,
		} );
	} );

	const t = useTranslation();

	return (
		<form onSubmit={ onFormSubmit } id="manage-expense-claim">
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Box className={ classes.pickerContainer }>
						<Typography>{ t( 'Date' ) }</Typography>
						<DatePicker
							autoOk
							disableToolbar
							variant="inline"
							value={ expenseClaimToEdit?.date || date }
							onChange={ setDate }
							format={ 'dd-MMM yyyy' }
							className={ classes.picker }
						/>
					</Box>
				</Box>
				<Box { ...getRootProps() } className={ classes.uploadBox }>
					<input
						{ ...getInputProps() }
						name="files"
						accept="image/*,.pdf"
						type="file"
					/>
					<Box className={ classes.attachBox }>
						<AttachFile className={ classes.attachFile } />
						<Typography className={ classes.attachText }>
							{ files.length > 0
								? `${ files.length } ${ t( 'attached file(s)' ) }`
								: t( 'attach file(s)' ) }
						</Typography>
					</Box>
				</Box>
			</Box>
			{ files.length > 0 && (
				<>
					<DividerToggle
						onToggle={ onFilesContainerToggle }
						expandMore={ isFileContainerHidden }
					/>
					{ !isFileContainerHidden && (
						<>
							<List>
								{ files.map( ( file ) => (
									<ListItem key={ file.fileName }>
										<ListItemIcon>
											<FileView
												base64String={ file.fileData }
												fileName={ file.fileName.length > 20
													? `${ file.fileName.slice( 0, 21 ) }...`
													: file.fileName }
											/>
										</ListItemIcon>
										<ListItemText>
											{ file.fileName.length > 10
												? `${ file.fileName.slice( 0, 15 ) }...`
												: file.fileName }
										</ListItemText>
										<ListItemIcon>
											<Close
												className={ classes.deleteFile }
												onClick={ () => onRemoveSelectedFile( file.fileName ) }
											/>
										</ListItemIcon>
									</ListItem>
								) ) }
							</List>
							<Divider />
						</>
					) }
				</>
			) }
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Comment' ) }</Typography>
				</Box>
				<Box className={ classes.value } flex="1">
					<TextField
						placeholder={ t( 'comment here' ) }
						name="description"
						inputRef={ register }
						className={ classes.comment }
						defaultValue={ expenseClaimToEdit?.description }
						fullWidth
						inputProps={ { maxLength: '60' } }
					/>
				</Box>
			</Box>
			{ expenseClaimCodeType?.includes( 'currency' ) && (
				<Box className={ classes.formRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Amount' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<TextField
							name="amount"
							inputRef={ register }
							className={ classes.comment }
							defaultValue={ expenseClaimToEdit?.salesAmount }
							fullWidth
							error={ !!error }
							onChange={ onChange }
							helperText={ error }
							required
						/>
					</Box>
				</Box>
			) }
			{ expenseClaimCodeType?.includes( 'mileage' ) && (
				<Box className={ classes.formRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Kilometers' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<TextField
							name="KM"
							inputRef={ register }
							className={ classes.comment }
							defaultValue={ expenseClaimToEdit?.km }
							fullWidth
							error={ !!error }
							onChange={ onChange }
							helperText={ error }
							required
						/>
					</Box>
				</Box>
			) }
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Release on save' ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<Checkbox
						className={ classes.checkBox }
						inputRef={ register }
						color="primary"
						name="releaseExpenseClaim"
						inputProps={ { 'aria-label': 'Release expense claim' } }
						defaultChecked={ expenseClaimToEdit?.status.id === '1' }
					/>
				</Box>
			</Box>
			<Box className={ classes.formRow }>
				<Box className={ classes.value }>
					<TextField
						placeholder={ t( 'Details' ) }
						name="detailDescription"
						inputRef={ register }
						className={ classes.description }
						multiline
						defaultValue={ expenseClaimToEdit?.detailedDescription }
						fullWidth
					/>
				</Box>
			</Box>
		</form>
	);
};

export default ManageExpenseClaimForm;
