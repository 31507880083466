import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import monthImage from './../../images/june.png';
import overlay from './../../images/overlay.png';

import BackButton from '../controls/BackButton';

import useTranslation from '../../hooks/useTranslation';

interface ScreenHeaderProps {
	title: string;
	CustomButton?: JSX.Element;
}

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '8rem',
		backgroundImage: `linear-gradient(${ theme.palette.custom.picture },
                       ${ theme.palette.custom.picture }), url( ${ overlay } ), url( ${ monthImage } )`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		filter: 'grayscale(10%) brightness(1)',
	},
	headerTitle: {
		color: theme.palette.custom.white,
		textShadow: '0px 3px 6px #000000CC, 0px 3px 6px #000000CC',
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		textAlign: 'center',
		right: 0,
		left: 0,
	},
	backButton: {
		color: theme.palette.custom.white,
	},
	biggerText: {
		fontSize: theme.spacing( 3 ),
		textTransform: 'capitalize',
	},
} ) );

const ScreenHeader: React.FC<ScreenHeaderProps> = ( {
	title,
	CustomButton,
} ) => {
	const classes = useStyles();

	const t = useTranslation();

	return (
		<header>
			<Box className={ classes.mediaHeader } p={ 2 }>
				<Box
					display="flex"
					flexDirection="row-reverse"
					width="100%"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box className={ classes.headerTitle }>
						<Box>
							<Typography className={ classes.biggerText }>{ t( title ) }</Typography>
						</Box>
					</Box>
					{
						<Box flex="1">
							{ CustomButton || <BackButton className={ classes.backButton } /> }
						</Box>
					}
				</Box>
			</Box>
		</header>
	);
};

export default ScreenHeader;
