import React from 'react';
import { IBeforeInstallPromptEvent, useAddToHomeScreenPrompt } from '../../hooks/useAddToHomeScreenPrompt';


interface AddToHomeScreenContextProps {
	prompt: IBeforeInstallPromptEvent | null,
	promptToInstall: () => void;
}

export const AddToHomeScreenContext = React.createContext<AddToHomeScreenContextProps>( {
	prompt: null,
	promptToInstall: () => { }
} );

const AddToHomeScreenProvider: React.FC = ( { children } ) => {
	const [ prompt, promptToInstall ] = useAddToHomeScreenPrompt();

	return (
		<AddToHomeScreenContext.Provider value={ { prompt, promptToInstall } }>
			{ children }
		</AddToHomeScreenContext.Provider>
	);
}

export default AddToHomeScreenProvider