import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Typography, Box } from '@material-ui/core';

import { AppState } from './../../../reducers/index';

import {
	DestinationFormProps,
	GroupedProjectActivities,
	ProjectActivityGroupDefinition,
} from './DestinationFormTypes';

import Dropdown from './../../controls/Dropdown';
import TextBox from './../../controls/TextBox';

import {
	groupProjectActivities,
	getDestinationTextFieldCustomPlaceHolder,
} from './destination-utils';
import FooterHint from '../../controls/FooterHint';
import CreateDestinationButton from './CreateDestinationButton';
import useTranslation from '../../../hooks/useTranslation';

export interface DropdownField {
	type: 'dropdown';
	label: string;
	getOptions: () => Array<string>;
}

export interface ITextField {
	type: 'text';
	label: string;
}

const groupingKeys: Array<ProjectActivityGroupDefinition> = [
	{
		label: 'Project',
		number: 'projectNumber',
		description: 'projectDescription',
	},
	{
		label: 'Sub Project',
		number: 'SubprojectNumber',
		description: 'subProjectDescription',
	},
	{
		label: 'Project Phase',
		number: 'projectPhaseNumber',
		description: 'projectPhaseDescription',
	},
	{
		label: 'Activity',
		number: 'componentCode',
		description: 'componentSearchKey',
	},
	{
		label: 'Sub Task',
		number: 'subTaskNumber',
		description: 'subtask',
	},
];

const ProjectForm: React.FC<DestinationFormProps> = ( {
	values,
	onValuesChange,
	onSubmit,
	autoExpand,
	isLoadingData,
	employeeCode,
	ColorPicker,
} ) => {
	let hint = '';

	const groupedProjectActivities = useSelector<
		AppState,
		GroupedProjectActivities | null
	>( ( state ) => {
		if ( state.timesheetSourcesState.data && state.timeState.weekRange ) {
			const projectActivitiesData = state.timesheetSourcesState.data.projectActivities.find(
				( projectActivity ) => projectActivity.employeeCode === employeeCode
			);

			const weekRange = state.timeState.weekRange;

			if ( projectActivitiesData ) {
				const projectActivities = projectActivitiesData.projectActivities.filter(
					( projectActivity ) => {
						const isInactive =
							Date.parse( projectActivity.startDateProjectTeam ) >
							weekRange.to.getTime() ||
							Date.parse( projectActivity.endDateProjectTeam ) <
							weekRange.from.getTime();

						return !isInactive;
					}
				);
				return groupProjectActivities( groupingKeys, projectActivities );
			}
		}

		return null;
	} );

	const onFormSubmit = useCallback( () => {
		if ( groupedProjectActivities ) {
			let selectedActivity =
				groupedProjectActivities[ values[ 'Project' ] ].children[
					values[ 'Sub Project' ]
				].children[ values[ 'Project Phase' ] ].children[ values[ 'Activity' ] ]
					.children[ values[ 'Sub Task' ] ].children;

			onSubmit( selectedActivity );
		}
	}, [ groupedProjectActivities, onSubmit, values ] );

	const alternativeActionHandler = useCallback( () => {
		if ( groupedProjectActivities ) {
			let selectedActivity =
				groupedProjectActivities[ values[ 'Project' ] ].children[
					values[ 'Sub Project' ]
				].children[ values[ 'Project Phase' ] ].children[ values[ 'Activity' ] ]
					.children[ values[ 'Sub Task' ] ].children;

			onSubmit( selectedActivity, true );
		}
	}, [ groupedProjectActivities, onSubmit, values ] );

	const onDropdownChange = useCallback(
		( value: string, label: string ) => {
			let newValues = {
				...values,
				[ label ]: value,
			};

			// When changing the value, we have to reset all the dependent fields (after it)
			let dropdownIndex = groupingKeys.findIndex( ( groupingKey ) => {
				if ( groupingKey.label === label ) {
					return true;
				}
				return false;
			} );

			if ( dropdownIndex === -1 ) {
				console.error( {
					message: 'Label not found in grouping keys',
					label,
					groupingKeys,
				} );
				return;
			}

			for (
				let nextFieldIndex = dropdownIndex + 1;
				nextFieldIndex < groupingKeys.length;
				nextFieldIndex++
			) {
				newValues = {
					...newValues,
					[ groupingKeys[ nextFieldIndex ].label ]: '',
				};
			}
			onValuesChange( newValues );
		},
		[ onValuesChange, values ]
	);

	const fields: Array<
		DropdownField | ITextField
	> | null = groupedProjectActivities
			? [
				{
					label: 'Project',
					getOptions: () => Object.keys( groupedProjectActivities ),
					type: 'dropdown',
				},
				{
					label: 'Sub Project',
					getOptions: () => {
						if ( values[ 'Project' ] ) {
							return Object.keys(
								groupedProjectActivities[ values[ 'Project' ] ].children
							);
						}

						return [];
					},
					type: 'dropdown',
				},
				{
					label: 'Project Phase',
					getOptions: () => {
						if ( values[ 'Sub Project' ] ) {
							return Object.keys(
								groupedProjectActivities[ values[ 'Project' ] ].children[
									values[ 'Sub Project' ]
								].children
							);
						}

						return [];
					},
					type: 'dropdown',
				},
				{
					label: 'Activity',
					getOptions: () => {
						if ( values[ 'Project Phase' ] ) {
							return Object.keys(
								groupedProjectActivities[ values[ 'Project' ] ].children[
									values[ 'Sub Project' ]
								].children[ values[ 'Project Phase' ] ].children
							);
						}

						return [];
					},
					type: 'dropdown',
				},
				{
					label: 'Sub Task',
					getOptions: () => {
						if ( values[ 'Activity' ] ) {
							return Object.keys(
								groupedProjectActivities[ values[ 'Project' ] ].children[
									values[ 'Sub Project' ]
								].children[ values[ 'Project Phase' ] ].children[ values[ 'Activity' ] ]
									.children
							);
						}

						return [];
					},
					type: 'dropdown',
				},
				{
					label: 'Name',
					type: 'text',
				},
			]
			: null;

	const form = [];

	const t = useTranslation();
	if ( groupedProjectActivities && fields && Object.keys( groupedProjectActivities ).length > 0 ) {
		let hasFieldsWithoutValue = false;

		for ( let fieldIndex = 0; fieldIndex < fields.length; fieldIndex++ ) {
			const field: DropdownField | ITextField = fields[ fieldIndex ];
			const value = values[ field.label ] ? values[ field.label ] : '';

			switch ( field.type ) {
				case 'dropdown':
					let options: Array<string> = field.getOptions();

					if ( options && options.length ) {
						form.push(
							<Dropdown
								key={ field.label }
								autoExpand={ autoExpand }
								label={ field.label }
								options={ options }
								selectedValue={ value }
								onValueChange={ onDropdownChange }
							/>
						);
					}
					break;
				case 'text':
					form.push(
						<TextBox
							key={ field.label }
							label={ field.label }
							customText={ t( getDestinationTextFieldCustomPlaceHolder( field.label ) || '' ) }
							value={ value }
							onChange={ ( val ) => {
								onValuesChange( {
									...values,
									[ field.label ]: val,
								} );
							} }
						/>
					);
					break;
			}

			if ( !value ) {
				hasFieldsWithoutValue = true;
				hint = `Select ${ field.label.toLowerCase() }`;
				break;
			}
		}
		if ( !hasFieldsWithoutValue ) {
			form.push( ColorPicker );
		}
	} else {
		form.push(
			<Box p={ 2 }>
				<Typography>{ t( 'no project activities' ) }</Typography>
			</Box>
		);
	}

	let footerHint = null;


	if ( hint ) {
		if ( hint === 'Select name' ) {
			footerHint = (
				<FooterHint
					hint="Enter name to save quick action"
					alternativeActionText="Continue"
					onAlternativeActionClick={ alternativeActionHandler }
				/>
			);
		} else {
			footerHint = <FooterHint hint={ hint } />;
		}
	}

	return (
		<>
			<Box
				marginLeft={ 2 }
				marginRight={ 2 }
				flex="1"
				overflow="auto"
			>
				{ !isLoadingData ? (
					form
				) : (
					<Box p={ 2 }>
						<Typography>Loading needed data...</Typography>
					</Box>
				) }
			</Box>
			{ footerHint }
			{ values.Name && (
				<CreateDestinationButton
					onClick={ onFormSubmit }
					actionText="Save changes"
				/>
			) }
		</>
	);
};

export default ProjectForm;
