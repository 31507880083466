import React from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import useTranslation from '../../hooks/useTranslation';

export interface ExpiredTokenMessageProps {
	onClick: () => void
	message: string
	buttonText: string
}

const ExpiredTokenMessage: React.FC<ExpiredTokenMessageProps> = ( { onClick, message, buttonText } ) => {
	const t = useTranslation();

	return (
		<Dialog open={ true }>
			<DialogTitle>{ t( "Oops.." ) }</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{ t( message ) }
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="primary" onClick={ onClick }>{ t( buttonText ) }</Button>
			</DialogActions>
		</Dialog>
	)
};

export default ExpiredTokenMessage;
