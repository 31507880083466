import {
	RELEASE_TIMESHEET_STARTED,
	RELEASE_TIMESHEET_SUCCEEDED,
	RELEASE_TIMESHEET_FAILED,
	UNDO_RELEASE_TIMESHEET_FAILED,
	UNDO_RELEASE_TIMESHEET_STARTED,
	UNDO_RELEASE_TIMESHEET_SUCCEEDED,
} from '../actions/api-actions';

import { CLEAR_RELEASE_TIMESHEET_ERROR } from '../actions/ui-actions';

export interface ReleasedTimesheetsState {
	isLoading: boolean;
	isLoadingUndo: boolean;
	error: any;
}

export function releasedTimesheetsReducer (
	state: ReleasedTimesheetsState = {
		isLoading: false,
		isLoadingUndo: false,
		error: null,
	},
	action: any
) {
	switch ( action.type ) {
		case RELEASE_TIMESHEET_STARTED: {
			return {
				...state,
				isLoading: true,
				error: null,
			};
		}
		case UNDO_RELEASE_TIMESHEET_STARTED: {
			return {
				...state,
				isLoadingUndo: true,
				error: null,
			};
		}
		case UNDO_RELEASE_TIMESHEET_FAILED:
		case RELEASE_TIMESHEET_FAILED: {
			return {
				...state,
				isLoading: false,
				isLoadingUndo: false,
				error: action.error,
			};
		}
		case RELEASE_TIMESHEET_SUCCEEDED: {
			return {
				...state,
				isLoading: false,
			};
		}
		case UNDO_RELEASE_TIMESHEET_SUCCEEDED: {
			return {
				...state,
				isLoadingUndo: false,
			};
		}
		case CLEAR_RELEASE_TIMESHEET_ERROR: {
			return {
				...state,
				error: null,
			};
		}
		default:
			return state;
	}
}
