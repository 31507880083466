import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Fab, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


export interface NavigationFabProps {
	path: string;
	ariaLabel: string;
	title: string
}

const useStyles = makeStyles( theme => ( {
	fab: {
		position: 'absolute',
		bottom: theme.spacing( 11 ),
		right: '12.5%',
		transform: 'translate( 50%, 0 )',

		'@media (max-height: 420px)': {
			bottom: theme.spacing( 7.5 ),
		}
	},
} ) );

const NavigationFab: React.FC<NavigationFabProps> = ( { path, ariaLabel, title } ) => {
	const classes = useStyles();
	const history = useHistory();
	const onClick = useCallback( () => {
		history.push( path, { previous: history.location.pathname } );
	}, [ history, path ] );

	return (
		<Box textAlign="right">
			<Tooltip title={ title } placement="top">
				<Fab
					color="primary"
					aria-label={ ariaLabel }
					className={ classes.fab }
					onClick={ onClick }
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</Box>
	);
};

export default NavigationFab