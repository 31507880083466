import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TemplateCard from '../../TemplateCard';
import { WeekActivity } from '../../../types/api/timesheets';
import { useHistory } from 'react-router-dom';

interface WeekTimesheetsProps {
	action?: () => void;
	weekActivities: WeekActivity[];
}

const useStyles = makeStyles( ( theme ) => ( {
	timesheetCard: {
		'&:last-child': {
			marginBottom: theme.spacing( 10 ),
		},
	},
} ) );

const WeekTimesheets: React.FC<WeekTimesheetsProps> = ( {
	action,
	weekActivities,
} ) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Box>
			{ weekActivities.map( ( weekActivity, index ) => (
				<TemplateCard
					className={ classes.timesheetCard }
					disabled={ !( weekActivity.details.status.desc === 'Draft' ) }
					key={ index }
					color={
						weekActivity.destination === 'Project' ? 'primary' : 'secondary'
					}
					title={ weekActivity.name }
					chipLabel={ `${ weekActivity.hours } Hours` }
					customChipColor={ weekActivity.templateColor }
					action={ () => {
						history.push(
							weekActivity.isTemplate
								? `/edit-timesheet/${ weekActivity.name }/${ weekActivity.details.id }`
								: `/edit-timesheet/${ weekActivity.details.id }`, { previous: history.location.pathname }
						);
					} }
				/>
			) ) }
		</Box>
	);
};

export default WeekTimesheets;
