import React, { useCallback } from 'react';
import {
	makeStyles,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Dialog,
	Button,
} from '@material-ui/core';

import { Delete } from '@material-ui/icons';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	delete: {
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.background.paper,
		},
		borderLeftWidth: theme.spacing( 0.25 ),
		borderTopWidth: theme.spacing( 0.25 ),
		borderTopColor: theme.palette.custom.lightGray,
		borderLeftColor: theme.palette.custom.lightGray,
		borderRadius: 0,
		borderTopStyle: 'solid',
		borderLeftStyle: 'solid',
		boxSizing: 'border-box',
		padding: theme.spacing( 1.5 ),
		fontWeight: 900,
		width: '50%',
		height: '100%',
	},
	confirm: {
		textTransform: 'none',
		backgroundColor: theme.palette.error.dark,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.error.main,
		},
	},
	close: {
		textTransform: 'none',
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
} ) );

interface DeleteConfirmProps {
	action?: () => void;
	customButtonClass?: string;
	customFontClass?: string;
}

const DeleteConfirm: React.FC<DeleteConfirmProps> = ( {
	action,
	customButtonClass,
	customFontClass,
} ) => {
	const [ open, setOpen ] = React.useState( false );

	const classes = useStyles();

	const handleClickOpen = useCallback( () => {
		if ( action ) {
			setOpen( true );
		}
	}, [ action ] );

	const handleClose = useCallback( () => {
		setOpen( false );
	}, [] );

	const onDelete = useCallback( () => {
		if ( action ) {
			action();
			handleClose();
		}
	}, [ handleClose, action ] );

	const t = useTranslation();

	return (
		<>
			<Button
				className={ customButtonClass || classes.delete }
				onClick={ handleClickOpen }
			>
				<Delete className={ customFontClass } />
				{ t( 'Delete' ) }
			</Button>
			<Dialog open={ open } onClose={ handleClose }>
				<DialogTitle>{ t( 'Delete item!' ) }</DialogTitle>
				<DialogContent>
					<DialogContentText id="delete-alert">
						{ t( 'Delete alert' ) }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={ onDelete } className={ classes.confirm }>
						{ t( 'Delete' ) }
					</Button>
					<Button onClick={ handleClose } className={ classes.close }>
						{ t( 'Close' ) }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DeleteConfirm;
