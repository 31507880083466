import { useSelector } from 'react-redux';
import { AppState } from '../reducers';
import { PublicHolidaysData, PublicHoliday } from '../types/api/timesheets';

const usePublicHolidays = () => {
	const usersHolidays = useSelector<AppState, Array<PublicHolidaysData>>(
		( state ) => {
			if ( state.publicHolidaysState.publicHolidays ) {
				return state.publicHolidaysState.publicHolidays;
			}
			return [];
		}
	);

	const currentWeekUserHolidays = useSelector<
		AppState,
		Array<PublicHolidaysData>
	>( ( state ) => {
		if ( state.publicHolidaysState.selectedWeekHolidays ) {
			return state.publicHolidaysState.selectedWeekHolidays;
		}
		return [];
	} );

	const publicHolidays: PublicHoliday[] = usersHolidays.reduce(
		( a: PublicHoliday[], b: PublicHolidaysData ) => {
			const arrayToConcat: PublicHoliday[] = b.publicHolidays.filter(
				( holiday ) =>
					!a
						.map( ( x ) => new Date( x.date ).getTime() )
						.includes( new Date( holiday.date ).getTime() )
			);
			return [ ...a, ...arrayToConcat ];
		},
		[]
	);

	const currentWeekHolidays: PublicHoliday[] = currentWeekUserHolidays.reduce(
		( a: PublicHoliday[], b: PublicHolidaysData ) => {
			const arrayToConcat: PublicHoliday[] = b.publicHolidays.filter(
				( holiday ) =>
					!a
						.map( ( x ) => new Date( x.date ).getTime() )
						.includes( new Date( holiday.date ).getTime() )
			);
			return [ ...a, ...arrayToConcat ];
		},
		[]
	);

	return { currentWeekHolidays, publicHolidays, usersHolidays };
};

export default usePublicHolidays;
