import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import LeaveRequestQuickAction from '../../controls/QuickAction';
import DesktopTemplateCard from '../../DesktopTemplateCard';
// import { ReactComponent as LeaveRequestIcon } from './../../../images/leave-request-icon.svg';
import LeaveRequestIcon from './../../../icons/LeaveRequestIcon';

import { makeStyles } from '@material-ui/core';

export interface LeavesMenuDesktopProps { }

const useStyles = makeStyles( theme => ( {
	leaveRequestQuickAction: {
		margin: theme.spacing( 1.5, 8 )
		// color: theme.palette.primary.main,
	},
	icon: {
		color: theme.palette.primary.main,
	}
} ) )

const LeavesMenuDesktop: React.FC<LeavesMenuDesktopProps> = () => {
	const history = useHistory();
	const classes = useStyles();

	const onClickOverviewHandler = useCallback( () => {
		history.push( '/leave', { previous: history.location.pathname } );
	}, [ history ] );

	const onClickAddHandler = useCallback( () => {
		history.push( '/create-leave-request', { previous: history.location.pathname } );
	}, [ history ] );

	return (
		<>
			<LeaveRequestQuickAction
				onClick={ onClickOverviewHandler }
				tooltipTitle="Alt+L"
				title={ 'Leave overview / request' }
				Icon={ () => <LeaveRequestIcon width={ 24 } height={ 24 } className={ classes.icon } /> }
				className={ classes.leaveRequestQuickAction }
			/>
			<DesktopTemplateCard
				title={ 'Add leave request' }
				action={ onClickAddHandler }
				Icon={ Add }
			/>
		</>
	);
};

export default LeavesMenuDesktop;
