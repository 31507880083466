import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { DatePicker } from '@material-ui/pickers';
import { Box, Typography, TextField, makeStyles } from '@material-ui/core';

import useTranslation from '../../../hooks/useTranslation';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ExpenseClaim } from '../../../types/api/expenseClaims';

const useStyles = makeStyles( ( theme ) => ( {
	formRow: {
		display: 'flex',
		padding: theme.spacing( 0.25, 2 ),
		justifyContent: 'space-between',
	},
	label: {
		display: 'flex',
		alignItems: 'center',
		margin: theme.spacing( 0, 3, 0, 0 ),
		minWidth: theme.spacing( 14 ),
		maxWidth: theme.spacing( 17.5 ),
	},
	value: {
		flex: 1,
		overflow: 'hidden',
		textAlign: 'left',
	},
	comment: {
		padding: theme.spacing( 0, 1 ),
		borderRadius: theme.spacing( 0.5 ),
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.25 ),
	},
	uploadBox: {
		padding: theme.spacing( 3, 1 ),
		backgroundColor: theme.palette.custom.paper,
		borderRadius: theme.spacing( 0.5 ),
		cursor: 'pointer',
	},
	description: {
		padding: theme.spacing( 0, 1 ),
		margin: theme.spacing( 1, 0 ),
		borderRadius: theme.spacing( 0.5 ),
		borderColor: theme.palette.custom.paper,
		borderStyle: 'solid',
		borderWidth: theme.spacing( 0.25 ),
		position: 'absolute',
		top: theme.spacing( 0.75 ),
		right: theme.spacing( 0.25 ),
		width: '50%',
	},
	picker: {
		padding: theme.spacing( 0 ),
		cursor: 'pointer',
		'& .MuiInputBase-input ': {
			textAlign: 'left',
		},
	},
	attachBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	attachFile: {
		fontSize: theme.spacing( 3 ),
		marginBottom: theme.spacing( 1 ),
	},
	attachText: {
		fontSize: theme.spacing( 1.5 ),
		fontWeight: 900,
	},
	timeLabel: {
		textTransform: 'capitalize',
	},
	checkBox: {
		padding: theme.spacing( 0.5 ),
	},
	formContainer: {
		display: 'flex',
	},
} ) );

export interface ManageExpenseClaimFormProps {
	date: MaterialUiPickersDate;
	setDate: ( date: MaterialUiPickersDate ) => void;
	onSubmit: ( data: any ) => void;
	expenseClaimCode?: string;
	projectNumber?: string;
	subProjectNumber?: string;
	projectPhaseNumber?: string;
	expenseClaimToEdit?: ExpenseClaim;
	expenseClaimCodeType?: Array<'project' | 'currency' | 'mileage'>;
	files: Array<{ fileName: string; fileData: string }>;
	error?: string;
	setError: ( error?: string ) => void;
}

const ManageExpenseClaimForm: React.FC<ManageExpenseClaimFormProps> = ( {
	date,
	setDate,
	onSubmit,
	expenseClaimCode,
	projectNumber,
	projectPhaseNumber,
	subProjectNumber,
	expenseClaimToEdit,
	expenseClaimCodeType,
	files,
	error,
	setError,
} ) => {
	const classes = useStyles();

	const onChange = useCallback(
		( e ) => {
			if ( !Number( e.target.value ) && e.target.value !== '' ) {
				setError( 'Enter a valid value' );
			} else {
				setError( undefined );
			}
		},
		[ setError ]
	);

	const { register, handleSubmit } = useForm();

	const onFormSubmit = handleSubmit( ( data: any ) => {
		onSubmit( {
			...data,
			amount: Number( data.amount ),
			KM: Number( data.KM ),
			files,
			date: date?.toISODate(),
			projectNumber: projectNumber?.toString() || '',
			projectPhaseNumber: projectPhaseNumber?.toString() || '',
			subProjectNumber: subProjectNumber?.toString() || '',
			expenseClaimCode,
		} );
	} );

	const t = useTranslation();

	return (
		<form
			onSubmit={ onFormSubmit }
			id="manage-expense-claim"
		>
			<Box className={ classes.formRow } style={ { justifyContent: 'flex-start' } }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Date' ) }</Typography>
				</Box>
				<Box className={ classes.value }>
					<DatePicker
						autoOk
						disableToolbar
						variant="inline"
						value={ expenseClaimToEdit?.date || date }
						onChange={ setDate }
						format={ 'dd-MMM yyyy' }
						className={ classes.picker }
					/>
				</Box>
			</Box>
			<Box className={ classes.formRow }>
				<Box className={ classes.label }>
					<Typography>{ t( 'Comment' ) }</Typography>
				</Box>
				<Box className={ classes.value } flex="1">
					<TextField
						placeholder={ t( 'comment here' ) }
						name="description"
						inputRef={ register }
						className={ classes.comment }
						defaultValue={ expenseClaimToEdit?.description }
						fullWidth
						inputProps={ { maxLength: '60' } }
					/>
				</Box>
			</Box>
			{ expenseClaimCodeType?.includes( 'currency' ) && (
				<Box className={ classes.formRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Amount' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<TextField
							name="amount"
							inputRef={ register }
							className={ classes.comment }
							defaultValue={ expenseClaimToEdit?.salesAmount }
							fullWidth
							error={ !!error }
							onChange={ onChange }
							helperText={ error }
							required
						/>
					</Box>
				</Box>
			) }
			{ expenseClaimCodeType?.includes( 'mileage' ) && (
				<Box className={ classes.formRow }>
					<Box className={ classes.label }>
						<Typography>{ t( 'Kilometers' ) }</Typography>
					</Box>
					<Box className={ classes.value }>
						<TextField
							name="KM"
							inputRef={ register }
							className={ classes.comment }
							defaultValue={ expenseClaimToEdit?.km }
							fullWidth
							required
							error={ !!error }
							onChange={ onChange }
							helperText={ error }
						/>
					</Box>
				</Box>
			) }
			<Box className={ classes.formRow }>
				<Box className={ classes.value }>
					<TextField
						placeholder={ t( 'Details' ) }
						name="detailDescription"
						inputRef={ register }
						className={ classes.description }
						rows={ 9 }
						multiline
						defaultValue={ expenseClaimToEdit?.detailedDescription }
						fullWidth
					/>
				</Box>
			</Box>
		</form>
	);
};

export default ManageExpenseClaimForm;
