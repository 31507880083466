import React from 'react';

import { IconButton, Box, Typography, makeStyles } from '@material-ui/core';

import monthImage from './../../images/june.png';
import { ReactComponent as LogOutIcon } from './../../images/_ionicons_svg_md-log-out.svg';
import overlay from './../../images/overlay.png';

import BackButton from '../controls/BackButton';

import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import useLanguage from '../../hooks/useLanguage';
import useTranslation from '../../hooks/useTranslation';

interface AddTimesheetHeaderProps {
	onLogOutClick?: () => void;
	CustomButton?: any;
}

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '11rem',
		backgroundImage: `linear-gradient(${ theme.palette.custom.picture },
                       ${theme.palette.custom.picture }), url( ${ overlay } ), url( ${ monthImage } )`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		filter: 'grayscale(10%) brightness(1)',
	},
	headerTitle: {
		color: theme.palette.custom.white,
		textShadow: '0px 3px 6px #000000CC, 0px 3px 6px #000000CC',
		display: 'flex',
		flexDirection: 'column',
		position: 'fixed',
		textAlign: 'center',
		right: 0,
		left: 0,
	},
	backButton: {
		color: theme.palette.custom.white,
	},
	biggerText: {
		fontSize: theme.spacing( 3 ),
		textTransform: 'capitalize',
	},
} ) );

const AddTimesheetHeader: React.FC<AddTimesheetHeaderProps> = ( {
	onLogOutClick,
	CustomButton,
} ) => {
	const weekRange = useSelector( ( state: AppState ) => state.timeState.weekRange );
	const classes = useStyles();

	const fromDateTime = weekRange?.from
		? DateTime.fromJSDate( weekRange.from )
		: null;
	const toDateTime = weekRange?.to ? DateTime.fromJSDate( weekRange.to ) : null;

	const language = useLanguage();
	const t = useTranslation();

	return (
		<header>
			<Box className={ classes.mediaHeader } p={ 2 }>
				<Box
					display="flex"
					flexDirection="row-reverse"
					width="100%"
					justifyContent="space-between"
				>
					{ onLogOutClick ? (
						<IconButton aria-label="Log Out" onClick={ onLogOutClick }>
							<LogOutIcon fill={ '#fff' } />
						</IconButton>
					) : null }
					<Box className={ classes.headerTitle }>
						<Box>
							<Typography className={ classes.biggerText }>
								{ t( 'Timesheet' ) }
							</Typography>
						</Box>
						<Box m={ 1 } textAlign="center">
							{ fromDateTime && toDateTime ? (
								<>
									<Typography className={ classes.biggerText }>
										{ fromDateTime.setLocale( language ).toFormat( 'LLL d - ' ) }
										{ fromDateTime.get( 'month' ) !== toDateTime.get( 'month' )
											? toDateTime.toFormat( 'LLL d' )
											: toDateTime.toFormat( 'd' ) }
									</Typography>
									<Typography>
										{ fromDateTime.setLocale( language ).toFormat( `yyyy - '${ t( 'Week' ) }' W` ) }
									</Typography>
								</>
							) : null }
						</Box>
					</Box>
					{
						<Box flex="1">
							{ CustomButton || <BackButton className={ classes.backButton } /> }
						</Box>
					}
				</Box>
			</Box>
		</header>
	);
};

export default AddTimesheetHeader;
