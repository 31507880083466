import React, { useEffect, useCallback } from 'react';

import { NavbarElementProps } from 'react-day-picker';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';

import { Box, makeStyles, Typography } from '@material-ui/core';

import {
	getNextMonthStartDate,
	getPreviousMonthStartDate,
} from '../../../utils/date';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import useTranslation from '../../../hooks/useTranslation';

const useStyles = makeStyles( ( theme ) => ( {
	container: {
		color: theme.palette.text.primary,
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navRight: {
		cursor: 'pointer',
		position: 'absolute',
		right: theme.spacing( 7 ),
		fontSize: theme.spacing( 6 ),
	},
	navLeft: {
		cursor: 'pointer',
		position: 'absolute',
		left: theme.spacing( 7 ),
		fontSize: theme.spacing( 6 ),
	},
	monthText: {
		fontSize: theme.spacing( 2.5 ),
		fontWeight: 700,
	}
} ) );

const monthSlideDuration = 300;

export interface MonthClasses {
	slideMonthLeft: string;
	moveMonthRight: string;
	slideMonth: string;
	moveMonthLeft: string;
	slideMonthRight: string;
}

export interface CalendarToolbarCustomProps {
	monthClasses: MonthClasses;
	setHandlers: ( handlers: SwipeableHandlers ) => void;
	setMonthClass: ( montClass: string ) => void;
	onChange: ( date: Date ) => void;
}

const CalendarDesktopToolbar: React.FC<
	Partial<NavbarElementProps> & CalendarToolbarCustomProps
> = ( props ) => {
	const {
		localeUtils,
		onPreviousClick,
		month,
		onNextClick,
		setHandlers,
		monthClasses,
		setMonthClass,
		onChange,
	} = props;

	const classes = useStyles();

	const onNextMonthClick = useCallback( () => {
		month && onChange( getNextMonthStartDate( month ) );

		setMonthClass( monthClasses.slideMonthLeft );
		setTimeout( () => {
			onNextClick && onNextClick();
			setMonthClass( monthClasses.moveMonthRight );
		}, monthSlideDuration );
		setTimeout( () => {
			setMonthClass( monthClasses.slideMonth );
		}, monthSlideDuration + 50 );
	}, [ onNextClick, setMonthClass, monthClasses, month, onChange ] );

	const onPreviousMonthClick = useCallback( () => {
		month && onChange( getPreviousMonthStartDate( month ) );

		setMonthClass( monthClasses.slideMonthRight );
		setTimeout( () => {
			onPreviousClick && onPreviousClick();
			setMonthClass( monthClasses.moveMonthLeft );
		}, monthSlideDuration );
		setTimeout( () => {
			setMonthClass( monthClasses.slideMonth );
		}, monthSlideDuration + 50 );
	}, [ onPreviousClick, setMonthClass, monthClasses, onChange, month ] );

	const handlers: SwipeableHandlers = useSwipeable( {
		onSwipedLeft: onNextMonthClick,
		onSwipedRight: onPreviousMonthClick,
		preventDefaultTouchmoveEvent: true,
		trackMouse: true,
	} );

	useEffect( () => {
		setHandlers( handlers );
	}, [ setHandlers, handlers ] );
	const months = localeUtils?.getMonths();
	const currentMonth = ( months && month ) && months[ month.getMonth() ];
	const displayYear: number =
		month && month.getFullYear() !== new Date().getFullYear()
			? month.getFullYear()
			: 0;

	const t = useTranslation();

	return (
		<Box className={ classes.container }>
			<ArrowLeft onClick={ onPreviousMonthClick } className={ classes.navLeft } />
			<Typography className={ classes.monthText }>
				{ displayYear ? `${ t( currentMonth || '' ) } ${ displayYear }` : `${ t( currentMonth || '' ) }` }
			</Typography>
			<ArrowRight onClick={ onNextMonthClick } className={ classes.navRight } />
		</Box>
	);
};

export default CalendarDesktopToolbar;
