import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
	Dialog,
	Slide,
	DialogContent,
	Box,
	DialogTitle,
	Button,
	Typography,
	Divider,
	SlideProps,
} from '@material-ui/core';

import { AppState } from '../../../reducers';

import {
	printTimesheetOptions,
	printExportTypeOptions,
} from '../../../config/constants';
import Dropdown from '../../controls/Dropdown';
import { saveSettings } from '../../../actions/api-actions';
import useTranslation from '../../../hooks/useTranslation';

export interface PrintTimesheetSettingsFormProps {
	isOpen: boolean;
	onClose: () => void;
	enabled: boolean;
	setIsAutoPrintEnabled: ( value: boolean ) => void;
}

const Transition = React.forwardRef<unknown, SlideProps>(
	function Transition ( props, ref ) {
		return <Slide direction="up" ref={ ref } { ...props } />;
	}
);

const useStyles = makeStyles( ( theme: Theme ) =>
	createStyles( {
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
		},
		paperDialog: {
			width: '40rem',
		},
		submitButton: {
			textTransform: 'none',
			fontWeight: 900,
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.contrastText,
			margin: theme.spacing( 0, 0, 2, 0 ),
			'&:hover': {
				backgroundColor: theme.palette.primary.main,
			},
		},
		radioGroup: {
			flexDirection: 'row',
			padding: theme.spacing( 0, 2, 2, 2 ),
			justifyContent: 'space-between',
		},
		section: {
			fontWeight: 900,
			color: theme.palette.primary.main,
			padding: theme.spacing( 1, 0 ),
		},
		selectClass: {
			width: `calc( 100% )`,
			fontSize: theme.spacing( 2 ),
			margin: theme.spacing( 0, 0, 2, 0 ),
		},
		droptDownLabel: {
			display: 'none',
		},
	} )
);

const PrintTimesheetSettingsForm: React.FC<PrintTimesheetSettingsFormProps> = ( {
	isOpen,
	onClose,
	enabled,
	setIsAutoPrintEnabled,
} ) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [ periodLabel, setPeriodLabel ] = useState<string>(
		printTimesheetOptions[ 0 ].label
	);

	const [ detailsType, setDetailsType ] = useState<string>(
		printExportTypeOptions[ 0 ]
	);

	const settings = useSelector(
		( state: AppState ) => state.settingsState.settings
	);

	const { printOptions } = settings;

	const onSubmit = useCallback( () => {
		const period = printTimesheetOptions.filter(
			( option ) => option.label === periodLabel
		)[ 0 ].value;

		const printOptions = {
			enabled,
			period,
			detailsType,
		};

		dispatch( saveSettings( { ...settings, printOptions } ) );

		setIsAutoPrintEnabled( true );
		onClose();
	}, [
		onClose,
		periodLabel,
		detailsType,
		dispatch,
		settings,
		enabled,
		setIsAutoPrintEnabled,
	] );

	const onChangePeriod = useCallback( ( value ) => {
		setPeriodLabel( value );
	}, [] );

	const onChangeExportOption = useCallback( ( value ) => {
		setDetailsType( value );
	}, [] );

	const onCloseWithoutSave = useCallback( () => {
		dispatch(
			saveSettings( {
				...settings,
				printOptions: {
					...printOptions,
					enabled: printOptions?.enabled && enabled,
				},
			} )
		);

		setIsAutoPrintEnabled( printOptions && printOptions.enabled );
		onClose();
	}, [
		settings,
		dispatch,
		onClose,
		setIsAutoPrintEnabled,
		printOptions,
		enabled,
	] );

	const savedPeriod =
		printOptions?.period &&
		printTimesheetOptions.find( ( option ) => option.value === printOptions.period )
			?.label;

	const savedExportType = printOptions?.detailsType;

	const t = useTranslation();

	return (
		<Dialog
			open={ isOpen }
			onClose={ onCloseWithoutSave }
			TransitionComponent={ Transition }
			classes={ { paper: classes.paperDialog } }
		>
			<DialogTitle>
				{ t( 'default print options' ) }
			</DialogTitle>
			<DialogContent>
				<Box className={ classes.contentContainer }>
					<Divider />
					<Typography className={ classes.section }>{ t( 'Period' ) }</Typography>
					<Dropdown
						label="Period"
						options={ printTimesheetOptions.map( ( option ) => option.label ) }
						selectedValue={ savedPeriod || periodLabel }
						onValueChange={ onChangePeriod }
						autoExpand={ false }
						selectClass={ classes.selectClass }
						labelClass={ classes.droptDownLabel }
					/>
					<Divider />
					<Typography className={ classes.section }>{ t( 'Export type' ) }</Typography>
					<Dropdown
						label="Export type"
						options={ printExportTypeOptions.map( ( option ) => option ) }
						selectedValue={ savedExportType || detailsType }
						onValueChange={ onChangeExportOption }
						autoExpand={ false }
						selectClass={ classes.selectClass }
						labelClass={ classes.droptDownLabel }
					/>
				</Box>
				<Button className={ classes.submitButton } onClick={ onSubmit } fullWidth>
					{ t( 'Save print options' ) }
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default PrintTimesheetSettingsForm;
