import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles( ( theme ) => ( {
	layoutContainer: {
		display: 'flex',
	},
	weekSelector: {
		borderBottomStyle: 'solid',
		borderBottomWidth: theme.spacing( 0.25 ),
		borderBottomColor: theme.palette.custom.paper,
	},
	inactiveAccount: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText,
		width: '100%',
		height: '5.15rem',
		padding: theme.spacing( 0, 0, 0, 2 ),
		display: 'flex',
		alignItems: 'center',
	},
	dataContainer: {
		display: 'flex',
		flexGrow: 2,
		height: 'calc( 100vh )',
		overflow: 'hidden',
		flexDirection: 'column',
		position: 'relative',
	},
	inactiveContainer: {
		display: 'flex',
		flexGrow: 2,
		position: 'relative',
	},
	listContainer: {
		display: 'flex',
		flex: '1',
	},
	homeContainer: {
		flexGrow: 1,
		maxWidth: '40rem',
	},
	settings: {
		position: 'absolute',
		borderStyle: 'solid',
		borderColor: theme.palette.custom.paper,
		width: '40rem',
		height: '100%',
		backgroundColor: theme.palette.background.default,
		boxShadow: 'none',
		zIndex: 3,
		top: 0,
		right: 0,
	},
	closeSettings: {
		display: 'flex',
		justifyContent: 'flex-end',
		margin: theme.spacing( 2, 2, 0, 0 ),
	},
	settingsOverLay: {
		position: 'fixed',
		width: '100%',
		height: '100%',
		top: 0,
		zIndex: 2,
	},
	inactiveOverlay: {
		position: 'absolute',
		zIndex: 1,
		top: '8rem',
		left: 0,
		width: '100%',
		height: '100%',
		flexGrow: 2,
	},
} ) );
