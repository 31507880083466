import React from 'react';

import { IconButton, Box, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';

interface DesktopPopUpHeaderProps {
	onClosePopUp: () => void;
}

const useStyles = makeStyles( ( theme ) => ( {
	mediaHeader: {
		height: '5rem',
	},

} ) );

const DesktopPopUpHeader: React.FC<DesktopPopUpHeaderProps> = ( {
	onClosePopUp,
} ) => {
	const classes = useStyles();

	return (
		<header>
			<Box className={ classes.mediaHeader } p={ 1 }>
				<Box display="flex" justifyContent="flex-end">
					<IconButton onClick={ onClosePopUp }>
						<Close />
					</IconButton>
				</Box>
			</Box>
		</header>
	);
};

export default DesktopPopUpHeader;
