import React, { useState, useCallback, useEffect } from 'react';
import { Box, makeStyles, Switch } from '@material-ui/core';
import PrintSettingsIcon from '@material-ui/icons/SettingsApplicationsOutlined';

import SettingsTemplateCard from '../../SettingsTemplateCard';

import PrintTimesheetSettingsForm from '../PrintTimesheet/PrintTimesheetSettingsForm';
import { useDispatch, useSelector } from 'react-redux';
import { saveSettings } from '../../../actions/api-actions';
import { AppState } from '../../../reducers';

interface PrintTimesheetSettingsProps { }

const useStyles = makeStyles( ( theme ) => ( {
	printIcon: {
		color: theme.palette.text.disabled,
		cursor: 'pointer',
		fontSize: theme.spacing( 3 ),
	},
	disableOptions: {
		display: 'none',
	},
	releaseTemplateCard: {
		boxShadow: 'none',
		cursor: 'default',
		backgroundColor: theme.palette.background.default,
	},
} ) );

const SwitchComponent = React.forwardRef(
	(
		{
			checked,
			onChange,
		}: { checked: boolean; onChange: ( e: any, checked: boolean ) => void },
		ref: any
	) => (
			<Switch color="primary" checked={ checked } onChange={ onChange } ref={ ref } />
		)
);

const PrintTimesheetSettings: React.FC<PrintTimesheetSettingsProps> = () => {
	const classes = useStyles();
	const [ isAutoPrintEnabled, setIsAutoPrintEnabled ] = useState<boolean>( false );

	const dispatch = useDispatch();

	const [
		isManagePrintTimesheetFormOpen,
		setIsManagePrintTimesheetFormOpen,
	] = useState<boolean>( false );

	const { settings } = useSelector( ( state: AppState ) => state.settingsState );

	const onOpenPrintTimesheetSettings = useCallback( () => {
		setIsManagePrintTimesheetFormOpen( true );
	}, [] );

	const onClosePrintTimesheetSettings = useCallback( () => {
		setIsManagePrintTimesheetFormOpen( false );
	}, [] );

	useEffect( () => {
		if ( !!!settings.printOptions ) {
			setIsAutoPrintEnabled( false );
		}
	}, [ settings.printOptions ] );

	const onSwitchPrintOption = useCallback(
		( e, value ) => {
			if ( value ) {
				onOpenPrintTimesheetSettings();
			} else {
				dispatch(
					saveSettings( {
						...settings,
						printOptions: { ...settings.printOptions, enabled: value },
					} )
				);
			}
			setIsAutoPrintEnabled( value );
		},
		[ onOpenPrintTimesheetSettings, dispatch, settings ]
	);

	return (
		<>
			<SettingsTemplateCard
				title={ 'Enable print timesheet on release' }
				className={ classes.releaseTemplateCard }
			>
				<Box display="flex" alignItems="center">
					<SwitchComponent
						checked={
							isAutoPrintEnabled ||
							( !!settings.printOptions && !!settings.printOptions.enabled )
						}
						onChange={ onSwitchPrintOption }
					/>
					<PrintSettingsIcon
						className={
							settings.printOptions && settings.printOptions.period
								? classes.printIcon
								: classes.disableOptions
						}
						onClick={ onOpenPrintTimesheetSettings }
					/>
				</Box>
			</SettingsTemplateCard>
			{ isManagePrintTimesheetFormOpen && (
				<PrintTimesheetSettingsForm
					isOpen={ isManagePrintTimesheetFormOpen }
					onClose={ onClosePrintTimesheetSettings }
					enabled={ isAutoPrintEnabled }
					setIsAutoPrintEnabled={ setIsAutoPrintEnabled }
				/>
			) }
		</>
	);
};

export default PrintTimesheetSettings;
